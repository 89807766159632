import React from 'react'

const HandWithCard = ({ style = {} }: { style?: React.CSSProperties }) => {
  return (
    <svg
      width='400'
      height='328'
      viewBox='0 0 415 328'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
    >
      <path
        opacity='0.16'
        d='M307.868 0.0115201C274.131 0.591772 248.105 28.3149 219.218 45.8515C175.6 72.3404 119.577 77.7193 82.7427 113.105C57.5139 137.338 45.0461 173.857 48.5939 208.737C52.1417 243.616 71.1825 276.313 98.7582 297.801C116.341 311.506 136.977 320.744 158.035 327.965C179.377 335.287 201.599 340.703 224.141 340.988C255.393 341.384 286.095 331.888 314.367 318.496C334.032 309.185 352.981 297.801 368.446 282.447C383.059 267.941 394.262 250.137 401.935 230.998C420.902 183.684 417.886 129.472 401.44 81.2192C387.844 41.3015 354.594 -0.798991 307.868 0.0115201Z'
        fill='#949191'
      />
      <g clipPath='url(#clip0_61_5455)'>
        <path
          d='M134.575 73.7754C134.158 72.947 127.803 62.1414 113.829 74.801C99.8552 87.4606 78.5299 110.469 78.5299 110.469L88.4692 119.413C88.4692 119.413 102.23 120.087 119.91 111.741C119.91 111.741 135.053 116.173 145.595 111.499C155.173 107.249 155.345 70.7279 155.345 70.7279L134.575 73.7754Z'
          fill='white'
        />
        <path
          d='M127.12 95.4692C121.825 97.9939 111.272 103.654 102.484 102.168'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.316 104.695C130.686 104.431 126.341 104.354 124.807 103.534C124.807 103.534 110.427 112.649 97.026 110.391'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.481 113.11C129.416 114.078 123.231 113.515 119.903 111.737C119.903 111.737 105.524 120.852 92.1228 118.594'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M122.027 80.7639L141.492 129.286C143.18 133.5 147.967 135.536 152.18 133.84L240.879 98.0758C245.092 96.3794 247.134 91.5851 245.445 87.371L225.98 38.8485C224.292 34.6345 219.505 32.5986 215.292 34.295L126.594 70.0591C122.381 71.7555 120.339 76.5499 122.027 80.7639Z'
          fill='#414042'
        />
        <path
          d='M123.903 79.9712L141.762 124.495C143.903 129.814 149.928 132.388 155.245 130.237L239.95 96.0808C241.36 95.5102 242.59 94.6557 243.584 93.6081C246.312 90.7603 247.272 86.4699 245.705 82.5825L240.68 70.0785L227.846 38.0584C225.712 32.7435 219.676 30.1719 214.363 32.3168L180.378 46.0195L155.112 56.2059L129.658 66.4728C124.344 68.6177 121.765 74.6627 123.903 79.9712Z'
          fill='#EBD0D0'
        />
        <path
          opacity='0.1'
          d='M180.374 46.0254L240.683 70.0882L227.849 38.0681C225.714 32.7531 219.679 30.1816 214.365 32.3265L180.374 46.0254Z'
          fill='#414042'
        />
        <path
          opacity='0.1'
          d='M123.903 79.971L141.762 124.495C143.903 129.814 149.928 132.388 155.245 130.237L239.95 96.0807C241.36 95.51 242.59 94.6556 243.584 93.6079L153.127 57.0039L129.662 66.4662C124.344 68.6176 121.765 74.6625 123.903 79.971Z'
          fill='#414042'
        />
        <path
          d='M201.15 48.9519L204.302 56.8159C204.932 58.3867 206.721 59.1484 208.291 58.5153L220.823 53.4585C222.394 52.8255 223.158 51.0342 222.528 49.4635L219.375 41.5994C218.745 40.0287 216.957 39.267 215.386 39.9001L202.854 44.9568C201.284 45.5899 200.52 47.3812 201.15 48.9519Z'
          fill='white'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M220.885 45.3647L202.777 52.9895'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M214.528 56L209.076 42.4507'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M148.301 122.88L149.249 125.15L186.444 110.153L185.525 107.865L148.301 122.88Z' fill='#414042' />
        <path d='M146.69 119.021L147.641 121.301L184.879 106.277L183.966 103.992L146.69 119.021Z' fill='#414042' />
        <path d='M145.08 115.16L146.031 117.44L183.331 102.4L182.411 100.112L145.08 115.16Z' fill='#414042' />
        <path
          d='M143.476 111.304L144.431 113.578L181.23 98.7297L180.317 96.4452L143.476 111.304Z'
          fill='#414042'
        />
        <path d='M141.74 107.14L142.695 109.414L179.55 94.5466L178.63 92.2582L141.74 107.14Z' fill='#414042' />
        <path
          d='M160.862 75.1492L161.813 77.4297L198.671 62.5558L197.752 60.2675L160.862 75.1492Z'
          fill='#414042'
        />
        <path d='M234.27 54.2766L235.708 57.8608L131.836 99.7454L130.398 96.1612L234.27 54.2766Z' fill='#414042' />
        <path
          d='M123.903 79.9712L141.762 124.495C143.903 129.814 149.928 132.388 155.245 130.237L239.95 96.0808C241.36 95.5102 242.59 94.6557 243.584 93.6081C246.312 90.7603 247.272 86.4699 245.705 82.5825L240.68 70.0785L227.846 38.0584C225.712 32.7435 219.676 30.1719 214.363 32.3168L180.378 46.0195L155.112 56.2059L129.658 66.4728C124.344 68.6177 121.765 74.6627 123.903 79.9712Z'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M29.6623 282.71C29.6623 282.71 -40.1398 272.345 -33.9843 235.843C-33.9843 235.843 51.095 199.198 45.7263 176.816C46.2943 161.789 44.0876 126.55 44.0876 126.55C44.0876 126.55 40.9982 115.457 42.8615 107.281C42.8615 107.281 48.6437 100.393 57.1318 98.4513C57.1318 98.4513 59.5178 94.5915 63.8611 94.2022C68.2044 93.8128 76.3177 92.8496 76.3177 92.8496C76.3177 92.8496 98.5652 76.3634 102.31 73.1927C106.054 70.0221 113.24 64.1582 119.555 56.7022C125.867 49.2359 132.455 41.0738 135.333 42.8289C135.333 42.8289 139.1 43.3156 135.921 48.6334C135.921 48.6334 140.249 55.6018 135.237 61.9047C130.225 68.2077 127.259 71.2201 125.199 75.2225C123.14 79.2249 113.921 91.8468 108.329 94.194C108.329 94.194 96.0817 112.337 91.6149 116.306C91.4808 116.428 91.3479 116.533 91.2292 116.628C87.1413 119.787 93.8615 145.466 100.111 154.997C100.111 154.997 99.9954 157.272 103.7 158.846C107.398 160.415 113.309 163.65 115.826 161.712C118.336 159.77 136.787 143.903 136.787 143.903C136.787 143.903 157.333 121.685 168.027 128.077C168.027 128.077 171.978 125.162 174.89 129.791C177.806 134.414 168.154 144.345 162.519 149.564C156.884 154.784 142.248 168.61 136.911 175.808C131.574 183.006 126.74 200.918 121.473 205.961C116.209 210.999 112.008 214.011 100.905 220.782C89.7925 227.539 36.6366 275.192 29.6623 282.71Z'
          fill='white'
        />
        <path
          d='M28.6588 282.846C28.6588 282.846 80.3136 231.681 104.213 218.957C128.105 206.229 125.288 194.974 135.199 178.393C145.11 161.812 175.432 141.671 175.523 133.278C175.604 124.888 167.664 126.894 162.129 131.462C156.594 136.03 157.503 141.078 163.048 144.954'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M168.007 128.066C168.007 128.066 161.297 123.484 150.339 132.081C139.388 140.681 115.596 164.911 103.323 169.227'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M115.209 162.143C115.117 162.21 101.861 161.82 89.3482 147.738'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M147.112 160.032C146.729 157.213 144.724 154.671 142.071 153.656'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M143.682 162.75C143.344 160.353 141.5 158.232 139.171 157.569'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.915 48.6289C144.031 58.0723 127.32 68.9028 125.193 75.218C123.066 81.5331 113.497 90.3718 107.603 94.7338C103.535 101.54 91.2228 116.624 91.2228 116.624C89.581 122.772 92.8137 145.752 102.06 157.864'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.859 51.907C123.859 51.907 131.626 40.6129 135.326 42.8246C139.026 45.0362 134.071 52.8132 129.021 57.7583C123.966 62.71 118.521 58.2702 123.859 51.907Z'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.859 51.9072C123.859 51.9072 109.675 68.4806 97.4052 76.8816'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M65.2743 116.34C65.1932 110.611 66.4245 105.955 68.485 100.338C70.6213 97.3212 80.413 89.1512 90.6273 81.6816'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M75.4083 93.623C69.3304 94.275 60.5898 92.3433 58.1798 98.2961C55.9192 103.882 54.6779 110.445 54.5636 117.103'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M-30.8451 234.779C-30.8451 234.779 38.6451 201.111 44.1034 185.337C48.1208 173.733 45.0698 147.429 44.094 126.553C41.6782 118.19 41.3287 112.572 42.8679 107.284C49.7867 99.6976 54.1258 98.8755 58.1862 98.2996'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M75.1552 98.0204C78.9537 99.0265 82.276 101.68 84.0939 105.163'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M73.8821 101.983C76.0372 102.85 77.8767 104.45 79.048 106.449'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  )
}

export default HandWithCard
