import { useState } from 'react'

const Tool = ({ selected }: { selected: boolean }) => {
  const [hover, setHover] = useState(selected)

  return (
    <svg
      width='103'
      height='102'
      viewBox='0 0 103 102'
      fill='none'
      onMouseEnter={() => {
        if (!selected) {
          setHover(true)
        }
      }}
      onMouseLeave={() => {
        if (!selected) {
          setHover(false)
        }
      }}
    >
      <circle cx='51.5' cy='51' r='51' fill={hover ? 'var(--ampa-red)' : 'var(--lighter-grey)'} />
      <path
        d='M65.28 76c-.67 0-1.3-.26-1.77-.73L51.17 62.93a.617.617 0 00-.42-.18.56.56 0 00-.42.18L37.25 76H27V65.75l13.07-13.07a.61.61 0 000-.85L27.73 39.5c-.47-.48-.73-1.1-.73-1.77 0-.67.26-1.3.73-1.77l9.23-9.23c.47-.47 1.1-.73 1.77-.73.67 0 1.3.26 1.77.73l12.34 12.34c.12.12.27.18.42.18.15 0 .31-.06.42-.18l12.34-12.34c.23-.23.51-.42.81-.54.31-.13.63-.19.96-.19.33 0 .65.06.96.19.3.13.58.31.81.54l6.72 6.72c.23.23.41.51.54.81.12.31.18.63.18.96 0 .33-.06.65-.19.96-.13.3-.31.58-.54.81L63.93 49.32a.61.61 0 000 .85l12.34 12.34c.47.47.73 1.1.73 1.77 0 .67-.26 1.3-.73 1.77l-9.22 9.23c-.48.46-1.11.72-1.77.72zM38.73 26.98c-.15 0-.31.06-.42.18L28.16 37.3a.61.61 0 000 .85l36.69 36.7c.11.11.27.18.42.18.15 0 .31-.06.42-.18L75.85 64.7a.61.61 0 000-.85L69.7 57.7a.607.607 0 00-.84 0l-3.8 3.8-.88-.88 3.8-3.8a.61.61 0 000-.85L59.3 47.3a.617.617 0 00-.42-.18c-.15 0-.31.06-.42.18l-3.8 3.8-.89-.88 3.8-3.79c.11-.11.18-.27.18-.42 0-.15-.06-.31-.18-.42l-8.66-8.66a.599.599 0 00-.42-.18.56.56 0 00-.42.18l-3.8 3.8-.88-.88 3.8-3.8a.61.61 0 000-.85l-8.02-8.02a.597.597 0 00-.44-.2zm2.37 26.4a.56.56 0 00-.42.18L28.16 66.08c-.11.11-.18.26-.18.42v7.65c0 .33.27.6.6.6h8.43c.16 0 .32-.07.43-.18l11.9-12.36c.23-.24.22-.61-.01-.84l-7.8-7.81a.598.598 0 00-.43-.18zm26.68-26.41c-.15 0-.31.06-.42.18l-12.79 12.8a.61.61 0 000 .85l7.65 7.65c.11.11.27.18.42.18.15 0 .31-.06.42-.18l12.79-12.8a.61.61 0 000-.85l-7.65-7.65a.592.592 0 00-.42-.18z'
        fill={hover ? 'var(--lighter-grey)' : 'var(--ampa-red)'}
        stroke={hover ? 'var(--ampa-red)' : 'var(--lighter-grey)'}
        strokeWidth='0.2'
      />
    </svg>
  )
}

export default Tool
