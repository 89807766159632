import { CircularProgress } from '@mui/material'

const Loader = (): JSX.Element => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <CircularProgress variant='indeterminate' value={0} size={42} sx={{ color: 'black' }} />
    </div>
  )
}

export default Loader
