import { Fragment, useContext, useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { useRtl } from '../../contexts/RtlContext'
import { useForm } from '../../contexts/FormContext'
import { StepContext } from '../../components/DialogWrapper'
import RedButton from '../../components/buttons/RedButton'
import GreyButton from '../../components/buttons/GreyButton'
import Disclaimer from '../../components/Disclaimer'
import Loader from '../../components/Loader'
import WomanWithCoin from '../../vectors/WomanWithCoin'
import { DigitalRequest } from '../../@types'
import { formatBigNumberFromCommas, formatBigNumberToCommas } from '../../functions/format-big-number'

const FinancialInformation = ({
  nextStep,
  updateDigitalRequest,
  digitalRequestId,
}: {
  nextStep: () => void
  updateDigitalRequest: (
    payload: Partial<DigitalRequest>,
    token: string,
    digitalRequestId: string
  ) => Promise<AxiosResponse<any, any>>
  digitalRequestId: string
}): JSX.Element => {
  const { t } = useTranslation()
  const { isRtl } = useRtl()
  const { token, formData, onChangeFormData, loading, setLoading } = useForm()
  const {
    setPreventBack,
    setHeaderActive,
    setDarkHeading,
    setStepTitle,
    setStepperActive,
    setStepperStep,
    setImageRenderer,
  } = useContext(StepContext)

  const [missingInfo, setMissingInfo] = useState(false)

  useEffect(() => {
    setPreventBack(true)
    setHeaderActive(true)
    setDarkHeading(true)
    setStepTitle(t('Financial Information'))
    setStepperActive(true)
    setStepperStep(formData['selectedProduct'] === 'Car' ? 4 : 3)
    setImageRenderer(<WomanWithCoin />)
  }, [isRtl])

  const isBusinessPerson =
    formData['employmentStatus'] === 'Company Owner' || formData['employmentStatus'] === 'Independent Business'

  const onClickContinue = async (): Promise<void> => {
    setMissingInfo(false)

    if (
      !formData['employmentStatus'] ||
      !formData['isHomeOwner'] ||
      !formData['isAdditionalIncome'] ||
      (formData['isAdditionalIncome'] === 'YES' &&
        !formData['additionalIncomeAmount'] &&
        formData['additionalIncomeAmount'] !== 0) ||
      !formData['isPortfolioOwner'] ||
      (formData['isPortfolioOwner'] === 'YES' &&
        !formData['portfolioAmount'] &&
        formData['portfolioAmount'] !== 0) ||
      (isBusinessPerson &&
        (!formData['fieldOfWork'] ||
          !formData['businessEstablishmentYear'] ||
          (!formData['businessMonthlyIncome'] && formData['businessMonthlyIncome'] !== 0) ||
          !formData['businessCustomerCount'] ||
          !formData['isFinancialService'])) ||
      (!isBusinessPerson &&
        (!formData['occupation'] ||
          (!formData['yearsEmployedCurrent'] && formData['yearsEmployedCurrent'] !== 0) ||
          (formData['yearsEmployedCurrent'] < 3 &&
            !formData['yearsEmployedCurrent'] &&
            formData['yearsEmployedPrevious'] !== 0) ||
          (!formData['monthlyIncome'] && formData['monthlyIncome'] !== 0)))
    ) {
      setMissingInfo(true)
      toast.error(t('Required information is missing'))
      return
    }

    if (
      isBusinessPerson
        ? formData['businessMonthlyIncome'] < 0 && formData['businessMonthlyIncome'] > 1000000
        : (formData['monthlyIncome'] < 0 && formData['monthlyIncome'] > 1000000) ||
          (formData['yearsEmployedCurrent'] < 0 && formData['yearsEmployedCurrent'] > 99) ||
          (formData['yearsEmployedPrevious'] < 0 && formData['yearsEmployedPrevious'] > 99) ||
          (formData['isAdditionalIncome'] === 'YES' &&
            formData['additionalIncomeAmount'] < 0 &&
            formData['additionalIncomeAmount'] > 1000000)
    ) {
      toast.error(t('Invalid values'))
      return
    }

    if (
      (isBusinessPerson && Number(formData['businessEstablishmentYear']) < 1950) ||
      Number(formData['businessEstablishmentYear']) > new Date().getFullYear()
    ) {
      toast.error(t('Business establishment year must be between 1950 - today'))
      return
    }

    setLoading(true)

    const payload: Record<string, any> = {
      Employment_Status__c: formData['employmentStatus'],
      Activity_Area__c: formData['fieldOfWork'],
      Apartment_Status__c: formData['isHomeOwner'],
      Additional_Income__c: formData['isAdditionalIncome'],
      Amount_Additional_Income__c: formData['additionalIncomeAmount'] || 0,
      Deposit_Savings_Securities_Portfo__c: formData['isPortfolioOwner'],
      What_Is_The_Deposit_Amount__c: formData['portfolioAmount'] || 0,
    }

    if (isBusinessPerson) {
      payload.Year_Business_Was_Established__c = formData['businessEstablishmentYear']
      payload.Monthly_Income__c = formData['businessMonthlyIncome'] || 0
      payload.Number_Customers__c = formData['businessCustomerCount'] || 0
      payload.Credit_or_Financial_Service__c = formData['isFinancialService']
    } else {
      payload.Current_Workplace_Seniority__c = formData['yearsEmployedCurrent'] || 0
      payload.Seniority_Work_First__c = formData['yearsEmployedPrevious'] || 0
      payload.Occupation__c = formData['occupation']
      payload.Net_Per_Month__c = formData['monthlyIncome'] || 0
    }

    await updateDigitalRequest(payload, token, digitalRequestId)

    setLoading(false)
    nextStep()
  }

  return (
    <Fragment>
      <main>
        <div>
          <div style={{ width: '95%', margin: '0.5rem auto' }}>
            <FormControl variant='standard' fullWidth>
              <InputLabel>{t('Employment Status')}</InputLabel>
              <Select
                value={formData['employmentStatus']}
                onChange={(e) => onChangeFormData('employmentStatus', e.target.value)}
              >
                {[
                  { label: t('Employed'), value: 'Employee' },
                  { label: t('Independant'), value: 'Independent Business' },
                  { label: t('Business Owner'), value: 'Company Owner' },
                  { label: t('Retired'), value: 'Pensioner' },
                  { label: t('Unemployed'), value: 'Unemployed' },
                ].map(({ label, value }) => (
                  <MenuItem key={`Employment_Status__c-${value}`} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <p className='err-txt'>
              {missingInfo && !formData['employmentStatus'] ? t('Required information is missing') : null}
            </p>
          </div>

          {isBusinessPerson ? (
            <div style={{ display: 'flex' }}>
              <div style={{ width: '45%', margin: '0.5rem auto' }}>
                <TextField
                  variant='standard'
                  label={t('Business Establishment Year')}
                  placeholder={new Date().getFullYear().toString()}
                  value={formData['businessEstablishmentYear']}
                  onChange={(e) => {
                    const v = Number(e.target.value)

                    if (!isNaN(v) && v >= 0) {
                      onChangeFormData('businessEstablishmentYear', String(v))
                    }
                  }}
                  fullWidth
                />
                <p className='err-txt'>
                  {missingInfo && !formData['businessEstablishmentYear']
                    ? t('Required information is missing')
                    : null}
                </p>
              </div>
              <div style={{ width: '45%', margin: '0.5rem auto' }}>
                <TextField
                  variant='standard'
                  label={t('Number of Business Clients')}
                  placeholder='0'
                  value={formatBigNumberToCommas(formData['businessCustomerCount'])}
                  onChange={(e) => {
                    const v = Number(formatBigNumberFromCommas(e.target.value))

                    if (!isNaN(v) && v >= 0 && String(v).length <= 5) {
                      onChangeFormData('businessCustomerCount', v)
                    }
                  }}
                  fullWidth
                />
                <p className='err-txt'>
                  {missingInfo && !formData['businessCustomerCount'] ? t('Required information is missing') : null}
                </p>
              </div>
            </div>
          ) : (
            <Fragment>
              <div style={{ width: '95%', margin: '0.5rem auto' }}>
                <TextField
                  variant='standard'
                  label={t('Years Employed at Current Occupation')}
                  placeholder='0'
                  value={formData['yearsEmployedCurrent']}
                  onChange={(e) => {
                    const v = Number(e.target.value)

                    if (!isNaN(v)) {
                      onChangeFormData('yearsEmployedCurrent', v)
                    }
                  }}
                  fullWidth
                />
                <p className='err-txt'>
                  {missingInfo && !formData['yearsEmployedCurrent']
                    ? t('Required information is missing')
                    : formData['yearsEmployedCurrent'] < 0 && formData['yearsEmployedCurrent'] > 99
                    ? t('Years employed is invalid')
                    : null}
                </p>
              </div>
              {formData['yearsEmployedCurrent'] !== '' && formData['yearsEmployedCurrent'] < 3 ? (
                <div style={{ width: '95%', margin: '0.5rem auto' }}>
                  <TextField
                    variant='standard'
                    label={t('Years Employed at Previous Occupation')}
                    placeholder='0'
                    value={formData['yearsEmployedPrevious']}
                    onChange={(e) => {
                      const v = Number(e.target.value)

                      if (!isNaN(v)) {
                        onChangeFormData('yearsEmployedPrevious', v)
                      }
                    }}
                    fullWidth
                  />
                  <p className='err-txt'>
                    {missingInfo && !formData['yearsEmployedPrevious']
                      ? t('Required information is missing')
                      : formData['yearsEmployedPrevious'] < 0 && formData['yearsEmployedPrevious'] > 99
                      ? t('Years employed is invalid')
                      : null}
                  </p>
                </div>
              ) : null}
            </Fragment>
          )}

          {isBusinessPerson ? (
            <div style={{ width: '95%', margin: '0.5rem auto' }}>
              <FormControl variant='standard' fullWidth>
                <InputLabel>{t('Field of Work')}</InputLabel>
                <Select
                  value={formData['fieldOfWork']}
                  onChange={(e) => onChangeFormData('fieldOfWork', e.target.value)}
                >
                  {[
                    { label: t('Pharma'), value: 'Pharma' },
                    { label: t('Health services'), value: 'Health services' },
                    { label: t('Vehicles'), value: 'Vehicles' },
                    { label: t('Agriculture'), value: 'Agriculture' },
                    { label: t('Medical equipment'), value: 'Medical equipment' },
                    {
                      label: t('Banks Insurance and financial institutions'),
                      value: 'Banks Insurance and financial institutions',
                    },
                    { label: t('Start-up and R&D'), value: 'Start-up and R&D' },
                    { label: t('Gold, metal and jewelry'), value: 'Gold, metal and jewelry' },
                    { label: t('Cosmetics'), value: 'Cosmetics' },
                    { label: t('High tech'), value: 'High tech' },
                    { label: t('Optics'), value: 'Optics' },
                    { label: t('Food and detergents'), value: 'Food and detergents' },
                    { label: t('Chemicals'), value: 'Chemicals' },
                    { label: t('Advertising'), value: 'Advertising' },
                    { label: t('Communication'), value: 'Communication' },
                    { label: t('Electronics'), value: 'Electronics' },
                    { label: t('Computers'), value: 'Computers' },
                    { label: t('Packaging'), value: 'Packaging' },
                    { label: t('Electricity'), value: 'Electricity' },
                    { label: t('Textile'), value: 'Textile' },
                    {
                      label: t('Communications – equipment & infostructure'),
                      value: 'Communications – equipment & infostructure',
                    },
                    { label: t('Office supplies'), value: 'Office supplies' },
                    { label: t('Services'), value: 'Services' },
                    { label: t('Vehicles – leasing & rental'), value: 'Vehicles – leasing & rental' },
                    { label: t('Toiletries'), value: 'Toiletries' },
                    { label: t('Electrical products'), value: 'Electrical products' },
                    { label: t('Retail chains'), value: 'Retail chains' },
                    { label: t('Construction - Infrastructure'), value: 'Construction - Infrastructure' },
                    { label: t('Toys & Gifts'), value: 'Toys & Gifts' },
                    { label: t('Tourism & aviation'), value: 'Tourism & aviation' },
                    { label: t('Printing & publishing'), value: 'Printing & publishing' },
                    { label: t('Glass & its products'), value: 'Glass & its products' },
                    { label: t('Plastics & nylon Products'), value: 'Plastics & nylon Products' },
                    { label: t('Housewares'), value: 'Housewares' },
                    { label: t('Transportation Services'), value: 'Transportation Services' },
                    { label: t('Wood and its products'), value: 'Wood and its products' },
                    { label: t('Fashion'), value: 'Fashion' },
                    {
                      label: t('Construction - Trading houses and wholesalers'),
                      value: 'Construction - Trading houses and wholesalers',
                    },
                    { label: t('Fuels'), value: 'Fuels' },
                    { label: t('Construction - Contracting'), value: 'Construction - Contracting' },
                    {
                      label: t('Construction - inputs and raw materials'),
                      value: 'Construction - inputs and raw materials',
                    },
                    { label: t('Food – bars, cafes, restaurants'), value: 'Food – bars, cafes, restaurants' },
                    { label: t('Government & authorities'), value: 'Government & authorities' },
                  ]
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map(({ label, value }) => (
                      <MenuItem key={`fieldOfWork-${value}`} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <p className='err-txt'>
                {missingInfo && !formData['fieldOfWork'] ? t('Required information is missing') : null}
              </p>
            </div>
          ) : (
            <div style={{ width: '95%', margin: '0.5rem auto' }}>
              <FormControl variant='standard' fullWidth>
                <InputLabel>{t('Occupation')}</InputLabel>
                <Select
                  value={formData['occupation']}
                  onChange={(e) => onChangeFormData('occupation', e.target.value)}
                >
                  {[
                    { label: t('Quality Assurance'), value: 'Quality Assurance' },
                    { label: t('Information Security'), value: 'Information Security' },
                    { label: t('Personal Security'), value: 'Personal Security' },
                    { label: t('Avrech - married Yeshiva student'), value: 'Avrech - married Yeshiva student' },
                    { label: t('Administrator'), value: 'Administrator' },
                    { label: t('Architecture – Architect'), value: 'Architecture – Architect' },
                    { label: t('Artist'), value: 'Artist' },
                    { label: t('Art critic'), value: 'Art critic' },
                    { label: t('Optimetrist'), value: 'Optimetrist' },
                    { label: t('Curator (art)'), value: 'Curator (art)' },
                    { label: t('E-commerce manager'), value: 'E-commerce manager' },
                    { label: t('Operations and Projects manager'), value: 'Operations and Projects manager' },
                    { label: t('Dog training'), value: 'Dog training' },
                    { label: t('plumber'), value: 'plumber' },
                    { label: t('maintenance, cleaning'), value: 'maintenance, cleaning' },
                    { label: t('Advertising Relations manager'), value: 'Advertising Relations manager' },
                    { label: t('Logistics and operations'), value: 'Logistics and operations' },
                    { label: t('Sales/Marketing'), value: 'Sales/Marketing' },
                    { label: t('System'), value: 'System' },
                    { label: t('Advertising'), value: 'Advertising' },
                    { label: t('Military'), value: 'Military' },
                    { label: t('Electronic technician'), value: 'Electronic technician' },
                    { label: t('Imperator'), value: 'Imperator' },
                    { label: t('Animator'), value: 'Animator' },
                    { label: t('Art Director'), value: 'Art Director' },
                    { label: t('Information architecture'), value: 'Information architecture' },
                    { label: t('Software tester'), value: 'Software tester' },
                    { label: t('Hardware tester'), value: 'Hardware tester' },
                    { label: t('Patent tester'), value: 'Patent tester' },
                    { label: t('Biologist'), value: 'Biologist' },
                    { label: t('Biotechnologist'), value: 'Biotechnologist' },
                    { label: t('Director'), value: 'Director' },
                    { label: t('Banker'), value: 'Banker' },
                    { label: t('Quality Control'), value: 'Quality Control' },
                    { label: t('Gardener'), value: 'Gardener' },
                    { label: t('Graphologist'), value: 'Graphologist' },
                    { label: t('Graphic artist'), value: 'Graphic artist' },
                    { label: t('Spokesperson'), value: 'Spokesperson' },
                    { label: t('Model'), value: 'Model' },
                    { label: t('Postman'), value: 'Postman' },
                    { label: t('DJ'), value: 'DJ' },
                    { label: t('Historian'), value: 'Historian' },
                    { label: t('Event production'), value: 'Event production' },
                    { label: t('Veterinarian'), value: 'Veterinarian' },
                    { label: t('Glazier'), value: 'Glazier' },
                    { label: t('Singer'), value: 'Singer' },
                    { label: t('Police investigator'), value: 'Police investigator' },
                    { label: t('Farmer'), value: 'Farmer' },
                    { label: t('Electrician'), value: 'Electrician' },
                    { label: t('Chef / Cook'), value: 'Chef / Cook' },
                    { label: t('Electronics Technician'), value: 'Electronics Technician' },
                    { label: t('Air conditioner technicians'), value: 'Air conditioner technicians' },
                    { label: t('Computer Technician'), value: 'Computer Technician' },
                    { label: t('Cellular Technician'), value: 'Cellular Technician' },
                    { label: t('Refrigeration Technician'), value: 'Refrigeration Technician' },
                    { label: t('Roentgen Technician'), value: 'Roentgen Technician' },
                    { label: t('Dental Technician'), value: 'Dental Technician' },
                    { label: t('Aviator / Pilot'), value: 'Aviator / Pilot' },
                    { label: t('Importer'), value: 'Importer' },
                    { label: t('Diamond dealer'), value: 'Diamond dealer' },
                    { label: t('Organizational Consultant'), value: 'Organizational Consultant' },
                    {
                      label: t('Placement and personnel consultant'),
                      value: 'Placement and personnel consultant',
                    },
                    { label: t('Mortgage Consultant'), value: 'Mortgage Consultant' },
                    { label: t('Financial Advisor'), value: 'Financial Advisor' },
                    { label: t('Advertising Consultant'), value: 'Advertising Consultant' },
                    { label: t('Marketing Consultant'), value: 'Marketing Consultant' },
                    { label: t('Software Consultant'), value: 'Software Consultant' },
                    { label: t('Entrepreneur'), value: 'Entrepreneur' },
                    { label: t('Public relations'), value: 'Public relations' },
                    { label: t('Couples Counseling'), value: 'Couples Counseling' },
                    {
                      label: t('Manufacturer / Industrial Production'),
                      value: 'Manufacturer / Industrial Production',
                    },
                    { label: t('Firefighter'), value: 'Firefighter' },
                    { label: t('Hatter / Milliner'), value: 'Hatter / Milliner' },
                    { label: t('Beekeeper'), value: 'Beekeeper' },
                    { label: t('Chemist'), value: 'Chemist' },
                    { label: t('Economist'), value: 'Economist' },
                    {
                      label: t('Content writer and editor, technical writing'),
                      value: 'Content writer and editor, technical writing',
                    },
                    { label: t('Snake catcher'), value: 'Snake catcher' },
                    { label: t('Poultry farmer'), value: 'Poultry farmer' },
                    { label: t('Diagnostician'), value: 'Diagnostician' },
                    { label: t('Personal Coaching'), value: 'Personal Coaching' },
                    { label: t('Fitness Coach'), value: 'Fitness Coach' },
                    { label: t('Makeup artist'), value: 'Makeup artist' },
                    { label: t('Proofreader'), value: 'Proofreader' },
                    { label: t('Tourist guide'), value: 'Tourist guide' },
                    { label: t('Energy Engineer'), value: 'Energy Engineer' },
                    { label: t('Electrical Engineer – Electronics'), value: 'Electrical Engineer – Electronics' },
                    { label: t('Product Engineer'), value: 'Product Engineer' },
                    { label: t('Mechanical Engineer'), value: 'Mechanical Engineer' },
                    { label: t('Medical Device Engineer'), value: 'Medical Device Engineer' },
                    { label: t('System Engineer'), value: 'System Engineer' },
                    {
                      label: t('Industrial and Management Engineer'),
                      value: 'Industrial and Management Engineer',
                    },
                    { label: t('Seller in a store'), value: 'Seller in a store' },
                    { label: t('Teacher'), value: 'Teacher' },
                    { label: t('Private Tutor'), value: 'Private Tutor' },
                    { label: t('Swimming Teacher'), value: 'Swimming Teacher' },
                    { label: t('Medical secretary or secretary'), value: 'Medical secretary or secretary' },
                    { label: t('Art therapist'), value: 'Art therapist' },
                    { label: t('Bibliotherapy'), value: 'Bibliotherapy' },
                    { label: t('Occupational therapist'), value: 'Occupational therapist' },
                    {
                      label: t('Cosmetic Caretaker, Hair, Facial, Skin'),
                      value: 'Cosmetic Caretaker, Hair, Facial, Skin',
                    },
                    { label: t('Informatics'), value: 'Informatics' },
                    { label: t('Systems Implementor'), value: 'Systems Implementor' },
                    { label: t('Mechanic'), value: 'Mechanic' },
                    { label: t('Hotelier / hotel employee'), value: 'Hotelier / hotel employee' },
                    { label: t('Sailor / Captain'), value: 'Sailor / Captain' },
                    { label: t('Waiter'), value: 'Waiter' },
                    { label: t('Personnel Manager'), value: 'Personnel Manager' },
                    { label: t('Media Manager'), value: 'Media Manager' },
                    { label: t('Brand Manager'), value: 'Brand Manager' },
                    { label: t('Sales Manager'), value: 'Sales Manager' },
                    { label: t('Database Manager'), value: 'Database Manager' },
                    { label: t('Office Manager'), value: 'Office Manager' },
                    { label: t('Creative Director'), value: 'Creative Director' },
                    { label: t('Network Administrator'), value: 'Network Administrator' },
                    {
                      label: t('Purchasing, shipping,  logistics manager'),
                      value: 'Purchasing, shipping,  logistics manager',
                    },
                    { label: t('Marketing and Advertising Manager'), value: 'Marketing and Advertising Manager' },
                    {
                      label: t('Manager / communications specialist'),
                      value: 'Manager / communications specialist',
                    },
                    {
                      label: t('Manager / infrastructure specialist'),
                      value: 'Manager / infrastructure specialist',
                    },
                    { label: t('Locksmith'), value: 'Locksmith' },
                    { label: t('Cleaner'), value: 'Cleaner' },
                    { label: t('Massage therapist'), value: 'Massage therapist' },
                    { label: t('Fashion designer'), value: 'Fashion designer' },
                    { label: t('Web Designer'), value: 'Web Designer' },
                    { label: t('Interior designer'), value: 'Interior designer' },
                    { label: t('Industrial designer'), value: 'Industrial designer' },
                    { label: t('Event producer'), value: 'Event producer' },
                    { label: t('Film producer'), value: 'Film producer' },
                    { label: t('Music producer'), value: 'Music producer' },
                    { label: t('Website Promoter'), value: 'Website Promoter' },
                    {
                      label: t('Developer, development manager, software'),
                      value: 'Developer, development manager, software',
                    },
                    { label: t('Spy'), value: 'Spy' },
                    { label: t('Holistic Healer'), value: 'Holistic Healer' },
                    { label: t('Poet'), value: 'Poet' },
                    { label: t('Mediator'), value: 'Mediator' },
                    { label: t('Interpreter'), value: 'Interpreter' },
                    { label: t('Translator'), value: 'Translator' },
                    { label: t('Programmer'), value: 'Programmer' },
                    { label: t('Carpenter'), value: 'Carpenter' },
                    { label: t('Driver'), value: 'Driver' },
                    { label: t('Neuropsychologist'), value: 'Neuropsychologist' },
                    { label: t('Naturopath'), value: 'Naturopath' },
                    { label: t('Blacksmith'), value: 'Blacksmith' },
                    { label: t('Organizer'), value: 'Organizer' },
                    { label: t('Stock exchange trader'), value: 'Stock exchange trader' },
                    { label: t('Writer'), value: 'Writer' },
                    { label: t('Pollster'), value: 'Pollster' },
                    { label: t('Styling – Fashion Design'), value: 'Styling – Fashion Design' },
                    { label: t('Stand-up comedian'), value: 'Stand-up comedian' },
                    { label: t('Dental Aide'), value: 'Dental Aide' },
                    { label: t('Professional athlete'), value: 'Professional athlete' },
                    { label: t('Sailor'), value: 'Sailor' },
                    { label: t('Barber/hairdresser'), value: 'Barber/hairdresser' },
                    { label: t('Dog literature'), value: 'Dog literature' },
                    { label: t('Librarian'), value: 'Librarian' },
                    {
                      label: t('Sexologist – Sexual Couples Therapist'),
                      value: 'Sexologist – Sexual Couples Therapist',
                    },
                    { label: t('Domestic worker'), value: 'Domestic worker' },
                    { label: t('Social Work – Social Worker'), value: 'Social Work – Social Worker' },
                    {
                      label: t('Inspector or employee of KKL-JNF, forestry'),
                      value: 'Inspector or employee of KKL-JNF, forestry',
                    },
                    { label: t('Legal assistant'), value: 'Legal assistant' },
                    { label: t('Lawyer'), value: 'Lawyer' },
                    { label: t('Literary or linguistic editor'), value: 'Literary or linguistic editor' },
                    { label: t('Graphic design'), value: 'Graphic design' },
                    { label: t('Video editing'), value: 'Video editing' },
                    { label: t('Politician'), value: 'Politician' },
                    { label: t('Tinsmith'), value: 'Tinsmith' },
                    { label: t('Physiotherapist'), value: 'Physiotherapist' },
                    { label: t('philosopher'), value: 'philosopher' },
                    { label: t('App development'), value: 'App development' },
                    { label: t('Software development'), value: 'Software development' },
                    {
                      label: t('Strategy, Management and Business Development'),
                      value: 'Strategy, Management and Business Development',
                    },
                    { label: t('Voice development'), value: 'Voice development' },
                    { label: t('Psychologist'), value: 'Psychologist' },
                    { label: t('Organizational psychologist'), value: 'Organizational psychologist' },
                    { label: t('Child psychologist'), value: 'Child psychologist' },
                    { label: t('Clinical psychologist'), value: 'Clinical psychologist' },
                    { label: t('Psychotherapist'), value: 'Psychotherapist' },
                    { label: t('Sculptor'), value: 'Sculptor' },
                    { label: t('Painter'), value: 'Painter' },
                    { label: t('Photographer'), value: 'Photographer' },
                    { label: t('Birdwatcher'), value: 'Birdwatcher' },
                    { label: t('Undertaker'), value: 'Undertaker' },
                    { label: t('Confectioner'), value: 'Confectioner' },
                    { label: t('Cosmetologist'), value: 'Cosmetologist' },
                    { label: t('Copywriting and creative'), value: 'Copywriting and creative' },
                    { label: t('Typist'), value: 'Typist' },
                    { label: t('Narration and dubbing'), value: 'Narration and dubbing' },
                    { label: t('Rabbi'), value: 'Rabbi' },
                    { label: t('Accountant'), value: 'Accountant' },
                    { label: t('Physician / MD'), value: 'Physician / MD' },
                    { label: t('Dairy farmer'), value: 'Dairy farmer' },
                    { label: t('Welder'), value: 'Welder' },
                    { label: t('Police officer'), value: 'Police officer' },
                    { label: t('Guard'), value: 'Guard' },
                    { label: t('Actor - Film or theater'), value: 'Actor - Film or theater' },
                    { label: t('Digital Marketing'), value: 'Digital Marketing' },
                    { label: t('Dental Hygienist'), value: 'Dental Hygienist' },
                    { label: t('Handyman'), value: 'Handyman' },
                    { label: t('Courier'), value: 'Courier' },
                    { label: t('Watchmaker'), value: 'Watchmaker' },
                    { label: t('Restaurateur chef'), value: 'Restaurateur chef' },
                    { label: t('Jeweler'), value: 'Jeweler' },
                    { label: t('Screenwriter'), value: 'Screenwriter' },
                    { label: t('Budgetarian'), value: 'Budgetarian' },
                  ]
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map(({ label, value }) => (
                      <MenuItem key={`occupation-${value}`} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <p className='err-txt'>
                {missingInfo && !formData['occupation'] ? t('Required information is missing') : null}
              </p>
            </div>
          )}

          {isBusinessPerson ? (
            <div style={{ width: '95%', margin: '0.5rem auto' }}>
              <TextField
                variant='standard'
                label={t('Average Monthly Income as Reported to Tax Authoroties')}
                placeholder='0'
                value={formatBigNumberToCommas(formData['businessMonthlyIncome'])}
                onChange={(e) => {
                  const v = Number(formatBigNumberFromCommas(e.target.value))

                  if (!isNaN(v)) {
                    onChangeFormData('businessMonthlyIncome', v)
                  }
                }}
                fullWidth
              />
              <p className='err-txt'>
                {missingInfo && !formData['businessMonthlyIncome']
                  ? t('Required information is missing')
                  : formData['businessMonthlyIncome'] < 0 && formData['businessMonthlyIncome'] > 1000000
                  ? t('Monthly income is invalid')
                  : null}
              </p>
            </div>
          ) : (
            <div style={{ width: '95%', margin: '0.5rem auto' }}>
              <TextField
                variant='standard'
                label={t('Net. Monthly Income')}
                placeholder='0'
                value={formatBigNumberToCommas(formData['monthlyIncome'])}
                onChange={(e) => {
                  const v = Number(formatBigNumberFromCommas(e.target.value))

                  if (!isNaN(v)) {
                    onChangeFormData('monthlyIncome', v)
                  }
                }}
                fullWidth
              />
              <p className='err-txt'>
                {missingInfo && !formData['monthlyIncome']
                  ? t('Required information is missing')
                  : formData['monthlyIncome'] < 0 && formData['monthlyIncome'] > 1000000
                  ? t('Monthly income is invalid')
                  : null}
              </p>
            </div>
          )}

          <div>
            {t('Do you own a house?')}
            <div>
              <RedButton
                label={t('Yes')}
                onClick={() => onChangeFormData('isHomeOwner', 'Yes')}
                selected={formData['isHomeOwner'] === 'Yes'}
              />
              <RedButton
                label={t('No')}
                onClick={() => onChangeFormData('isHomeOwner', 'No')}
                selected={formData['isHomeOwner'] === 'No'}
              />
            </div>
            <p className='err-txt'>
              {missingInfo && !formData['isHomeOwner'] ? t('Required information is missing') : null}
            </p>
          </div>

          <div>
            {t('Do you have an alternative income?')}
            <div>
              <RedButton
                label={t('Yes')}
                onClick={() => onChangeFormData('isAdditionalIncome', 'YES')}
                selected={formData['isAdditionalIncome'] === 'YES'}
              />
              <RedButton
                label={t('No')}
                onClick={() => onChangeFormData('isAdditionalIncome', 'NO')}
                selected={formData['isAdditionalIncome'] === 'NO'}
              />
            </div>
            <p className='err-txt'>
              {missingInfo && !formData['isAdditionalIncome'] ? t('Required information is missing') : null}
            </p>
            {formData['isAdditionalIncome'] === 'YES' ? (
              <div style={{ width: '95%', margin: '0.5rem auto' }}>
                <TextField
                  variant='standard'
                  label={t('The amount of alternative income')}
                  placeholder='0'
                  value={formatBigNumberToCommas(formData['additionalIncomeAmount'])}
                  onChange={(e) => {
                    const v = Number(formatBigNumberFromCommas(e.target.value))

                    if (!isNaN(v)) {
                      onChangeFormData('additionalIncomeAmount', v)
                    }
                  }}
                  fullWidth
                />
                <p className='err-txt'>
                  {missingInfo && !formData['additionalIncomeAmount']
                    ? t('Required information is missing')
                    : formData['additionalIncomeAmount'] < 0 && formData['additionalIncomeAmount'] > 1000000
                    ? t('Additional income is invalid')
                    : null}
                </p>
              </div>
            ) : null}
          </div>

          <div>
            {t('Do you have any savings / investments?')}
            <div>
              <RedButton
                label={t('Yes')}
                onClick={() => onChangeFormData('isPortfolioOwner', 'YES')}
                selected={formData['isPortfolioOwner'] === 'YES'}
              />
              <RedButton
                label={t('No')}
                onClick={() => onChangeFormData('isPortfolioOwner', 'NO')}
                selected={formData['isPortfolioOwner'] === 'NO'}
              />
            </div>
            <p className='err-txt'>
              {missingInfo && !formData['isPortfolioOwner'] ? t('Required information is missing') : null}
            </p>
            {formData['isPortfolioOwner'] === 'YES' ? (
              <div style={{ width: '95%', margin: '0.5rem auto' }}>
                <TextField
                  variant='standard'
                  label={t('The amount of deposits')}
                  placeholder='0'
                  value={formatBigNumberToCommas(formData['portfolioAmount'])}
                  onChange={(e) => {
                    const v = Number(formatBigNumberFromCommas(e.target.value))

                    if (!isNaN(v) && v >= 0 && String(v).length <= 8) {
                      onChangeFormData('portfolioAmount', v)
                    }
                  }}
                  fullWidth
                />
                <p className='err-txt'>
                  {missingInfo && !formData['portfolioAmount'] ? t('Required information is missing') : null}
                </p>
              </div>
            ) : null}
          </div>

          {isBusinessPerson ? (
            <div>
              {t('Is Your Business Providing Financial Services?')}
              <div>
                <RedButton
                  label={t('Yes')}
                  onClick={() => onChangeFormData('isFinancialService', 'Yes')}
                  selected={formData['isFinancialService'] === 'Yes'}
                />
                <RedButton
                  label={t('No')}
                  onClick={() => onChangeFormData('isFinancialService', 'No')}
                  selected={formData['isFinancialService'] === 'No'}
                />
              </div>
            </div>
          ) : null}

          <Disclaimer>{t('financial_info_disclaimer')}</Disclaimer>
        </div>
      </main>

      <div style={{ position: 'sticky', bottom: '42px', zIndex: 3, padding: '0 1rem' }}>
        {loading ? <Loader /> : <GreyButton label={t('Continue')} onClick={onClickContinue} />}
      </div>
    </Fragment>
  )
}

export default FinancialInformation
