const {
  REACT_APP_LOAN_JOURNEY_SERVICE: LOAN_JOURNEY_SERVICE = 'http://localhost:1045',
  REACT_APP_TRANZILLA_SUPPLIER: TRANZILLA_SUPPLIER = 'ampa2022',
  REACT_APP_AUTH_SERVICE_URL: AUTH_SERVICE_URL = 'http://localhost:8080',
  REACT_APP_AMPA_CLIENT_ID: CLIENT_ID = '',
  REACT_APP_ENVIRONMENT: ENVIRONMENT = 'development',
} = process.env

const EIGHTEEN_YEARS_IN_MS = 567648000000
const TWENTY_ONE_YEARS_IN_MS = 662760000000
const TWENTY_FIVE_YEARS_IN_MS = 788940000000
const SEVENTY_FIVE_YEARS_IN_MS = 2366820000000

const CONTACT_EMAIL = 'tiful@ampa.co.il'

export {
  LOAN_JOURNEY_SERVICE,
  AUTH_SERVICE_URL,
  TRANZILLA_SUPPLIER,
  EIGHTEEN_YEARS_IN_MS,
  TWENTY_ONE_YEARS_IN_MS,
  TWENTY_FIVE_YEARS_IN_MS,
  SEVENTY_FIVE_YEARS_IN_MS,
  CONTACT_EMAIL,
  CLIENT_ID,
  ENVIRONMENT,
}
