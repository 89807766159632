import React from 'react'
import ReactDOM from 'react-dom'
import { Toaster } from 'react-hot-toast'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/styles'
import theme from './utils/theme'
import RtlProvider from './contexts/RtlContext'
import FormProvider from './contexts/FormContext'
import App from './App'
import './index.css'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Toaster position='top-center' reverseOrder={false} toastOptions={{ duration: 4000 }} />
      <CssBaseline />
      <RtlProvider>
        <FormProvider>
          <App />
        </FormProvider>
      </RtlProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
