
/* eslint no-unused-vars: 0 */
/* eslint @typescript-eslint/no-unused-vars: 0 */
/* eslint @typescript-eslint/no-empty-function: 0 */
import React, { createContext, useContext, useState } from 'react'

type ContextValue = {
  isRtl: boolean
  setIsRtl: React.Dispatch<React.SetStateAction<boolean>>
}

const initialValue: ContextValue = {
  isRtl: false,
  setIsRtl: (_bool) => {},
}

const RtlContext = createContext(initialValue)

export const useRtl = (): ContextValue => {
  return useContext(RtlContext)
}

const RtlProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [isRtl, setIsRtl] = useState<boolean>(initialValue.isRtl)

  return <RtlContext.Provider value={{ isRtl, setIsRtl }}>{children}</RtlContext.Provider>
}

export default RtlProvider
