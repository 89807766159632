import React from 'react'

const Woman = ({ style = {} }: { style?: React.CSSProperties }) => {
  return (
    <svg
      width='440'
      height='257'
      viewBox='0 0 440 257'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
    >
      <path
        opacity='0.16'
        d='M75.0002 66.5534C161.5 234.053 285.5 128.053 368 142.053C449.2 148.221 444.833 220.623 432.5 256.053L-92 256.053L-92 121.553C-92 1.55326 25.5679 -29.1677 75.0002 66.5534Z'
        fill='#949191'
      />
      <g clipPath='url(#clip0_61_5455)'>
        <path
          d='M25.5752 12.2853C25.5752 12.2853 19.6114 3.19393 16.4778 3.03334C13.3441 2.87274 1.8897 10.2422 -2.19032 19.967C-6.27034 29.6828 -4.07408 36.4277 1.41653 39.4076C6.90714 42.3875 9.72833 43.7972 12.5585 42.5392C15.3796 41.2812 17.1116 39.4076 17.1116 39.4076C17.1116 39.4076 14.9154 44.734 12.246 45.5191C9.57655 46.3042 8.16596 48.3384 11.7728 48.3384C15.3796 48.3384 20.2453 43.1637 19.6204 37.9891C18.9865 32.8233 25.5752 12.2853 25.5752 12.2853Z'
          fill='#5F5E60'
        />
        <path
          d='M96.4622 196.485C96.4622 196.485 125.808 228.702 127.46 230.558C129.111 232.414 131.593 234.278 131.593 234.278C131.593 234.278 152.877 228.292 157.011 227.872C161.144 227.462 171.884 236.955 174.366 240.265C176.848 243.566 177.464 246.046 177.464 246.046C175.402 246.662 151.841 246.662 146.672 246.662C141.503 246.662 123.942 244.181 122.496 243.566C121.049 242.95 87.9896 225.187 87.9896 225.187L88.8199 197.306C88.811 197.315 93.1589 195.665 96.4622 196.485Z'
          fill='white'
          stroke='#5F5E60'
          strokeMiterlimit='10'
        />
        <path
          d='M152.663 231.798C152.663 231.798 162.171 238.819 164.028 240.88C165.885 242.941 168.367 246.251 168.367 246.251'
          stroke='#5F5E60'
          strokeMiterlimit='10'
        />
        <path
          d='M159.278 230.147C159.278 230.147 169.608 237.374 170.849 240.06C172.09 242.745 173.741 246.465 173.741 246.465'
          stroke='#5F5E60'
          strokeMiterlimit='10'
        />
        <path
          d='M146.878 235.724C146.878 235.724 147.494 237.579 148.324 238.82C149.154 240.06 156.18 241.915 159.689 243.985C163.198 246.046 159.689 247.911 154.314 247.492C148.94 247.081 141.503 247.492 138.2 245.841C134.896 244.19 130.968 239.854 130.968 239.854'
          fill='white'
        />
        <path
          d='M146.878 235.724C146.878 235.724 147.494 237.579 148.324 238.82C149.154 240.06 156.18 241.915 159.689 243.985C163.198 246.046 159.689 247.911 154.314 247.492C148.94 247.081 141.503 247.492 138.2 245.841C134.896 244.19 130.968 239.854 130.968 239.854'
          stroke='#5F5E60'
          strokeMiterlimit='10'
        />
        <path
          d='M92.3286 182.442C92.3286 182.442 97.2835 181.202 97.7031 183.058C98.1138 184.914 101.006 195.861 99.7654 197.306C98.5245 198.751 91.0876 201.437 90.052 201.232C89.0253 201.027 92.3286 182.442 92.3286 182.442Z'
          fill='#EBD0D0'
          stroke='#5F5E60'
          strokeMiterlimit='10'
        />
        <path
          d='M64.0541 93.8398C64.0541 93.8398 73.0177 100.201 81.508 105.394C89.9984 110.577 93.3017 111.291 93.3017 114.583C93.3017 117.875 94.007 170.683 94.007 170.683C94.007 170.683 94.9534 180.114 92.3554 188.83C89.7574 197.556 89.0521 245.877 89.0521 245.877H-7.1899L-3.8866 213.82C-3.8866 213.82 -15.2071 115.529 -12.8502 111.514C-10.4932 107.508 17.3438 92.894 17.3438 92.894L64.0541 93.8398Z'
          fill='white'
          stroke='#5F5E60'
          strokeMiterlimit='10'
        />
        <path
          d='M44.6451 107.66C44.6451 107.66 40.0472 123.728 38.7973 123.104C37.5474 122.479 15.4153 94.5181 15.2011 92.6356C14.9957 90.762 22.2987 81.7867 22.2987 81.7867C22.2987 81.7867 25.4324 80.5376 27.1019 80.5376C28.7714 80.5376 61.1347 86.3814 61.974 87.4252C62.8132 88.4691 65.1076 94.0988 66.7771 96.811C68.4466 99.5232 80.3474 111.63 78.8922 111.835C77.428 112.041 61.7686 106.616 61.7686 106.616L56.3941 122.8C56.3941 122.8 48.8233 116.011 47.5734 114.342C46.3146 112.665 44.6451 107.66 44.6451 107.66Z'
          fill='#EBD0D0'
          stroke='#5F5E60'
          strokeMiterlimit='10'
        />
        <path
          d='M62.4203 83.99C62.4203 83.99 60.733 91.2166 59.5277 100.379C58.3225 109.533 56.3941 122.791 56.3941 122.791C56.3941 122.791 42.1631 101.825 41.1989 101.102C40.2347 100.379 25.7627 83.5082 25.7627 83.5082L24.7985 59.1694C24.7985 59.1694 31.789 71.4637 38.5474 77.0042C45.2968 82.5446 62.4203 83.99 62.4203 83.99Z'
          fill='white'
          stroke='#5F5E60'
          strokeMiterlimit='10'
        />
        <path
          d='M38.5474 77.0044C38.5474 77.0044 45.3771 87.4608 50.591 92.341C55.8048 97.2302 59.5278 100.38 59.5278 100.38L61.974 87.4162L38.5474 77.0044Z'
          fill='#5F5E60'
        />
        <path
          d='M41.8774 213.82L50.841 210.992L51.3141 216.176L41.8774 213.82Z'
          fill='white'
          stroke='#5F5E60'
          strokeMiterlimit='10'
        />
        <path
          d='M17.817 150.413L-0.110121 190.249H-31.0093C-31.0093 190.249 -17.7961 129.902 -17.3319 125.896C-16.8587 121.89 -18.0372 112.228 -14.9749 110.577C-11.9127 108.927 -3.88659 106.331 -3.88659 106.331'
          fill='white'
        />
        <path
          d='M17.817 150.413L-0.11012 190.249H-31.0093C-31.0093 190.249 -17.7961 129.902 -17.3319 125.896C-16.8587 121.89 -18.0372 112.228 -14.9749 110.577C-11.9127 108.927 -3.88659 106.331 -3.88659 106.331'
          stroke='#5F5E60'
          strokeMiterlimit='10'
        />
        <path d='M11.4514 164.554C11.4514 164.554 19.7096 159.13 22.0666 154.651L11.4514 164.554Z' fill='white' />
        <path
          d='M11.4514 164.554C11.4514 164.554 19.7096 159.13 22.0666 154.651'
          stroke='#5F5E60'
          strokeMiterlimit='10'
        />
        <path
          d='M-1.52075 197.556C-1.52075 197.556 23.0129 217.354 25.602 218.299C28.2 219.245 31.0301 220.182 31.0301 220.182C31.0301 220.182 39.7615 215.935 41.8774 213.821C44.0022 211.697 64.7594 219.477 64.7594 219.477L72.0713 226.079C72.0713 226.079 71.125 228.435 69.2412 228.435C67.3574 228.435 61.224 223.483 61.224 223.483L52.2605 223.01L64.5273 231.263L67.8306 238.097C67.8306 238.097 65.2326 240.693 63.1167 237.392C60.9919 234.091 59.8134 233.145 59.8134 233.145L48.2787 230.174C48.2787 230.174 53.9835 235.965 55.162 236.669C56.3405 237.374 62.2954 240.443 61.1883 241.737C60.2866 242.799 59.3402 242.576 57.9296 242.103C56.5101 241.63 51.3231 240.22 51.3231 240.22C51.3231 240.22 45.4218 240.693 43.779 240.693C42.1274 240.693 36.235 236.919 36.235 236.919L26.5662 231.263C26.5662 231.263 -8.58266 219.477 -14.9482 215.703C-21.3137 211.929 -31.4557 204.863 -30.9915 202.267C-30.5362 199.68 -1.52075 197.556 -1.52075 197.556Z'
          fill='white'
          stroke='#5F5E60'
          strokeMiterlimit='10'
        />
        <path
          d='M51.3141 240.22C51.3141 240.22 44.0022 237.624 41.4042 234.796'
          stroke='#5F5E60'
          strokeMiterlimit='10'
        />
        <path
          d='M3.19315 189.544L1.30937 193.318L0.130908 198.27C0.130908 198.27 -6.94886 199.689 -13.3144 200.866C-19.6799 202.044 -33.5984 205.345 -33.5984 205.345C-33.5984 205.345 -35.9554 203.222 -35.4822 199.689C-35.009 196.156 -35.4822 187.43 -34.0627 185.075C-32.661 182.71 -3.41345 183.647 3.19315 189.544Z'
          fill='#EBD0D0'
          stroke='#5F5E60'
          strokeMiterlimit='10'
        />
        <path
          d='M-0.226227 193.372C-0.226227 193.372 -6.81496 189.259 -19.796 189.045'
          stroke='#5F5E60'
          strokeMiterlimit='10'
        />
        <path
          d='M71.8481 62.4526C75.571 62.4526 78.5976 65.4682 78.5976 69.1975C78.5976 72.9179 75.58 75.9424 71.8481 75.9424C68.1252 75.9424 65.0987 72.9269 65.0987 69.1975C65.1076 65.4771 68.1252 62.4526 71.8481 62.4526ZM71.8481 74.1135C74.5711 74.1135 76.7763 71.9098 76.7763 69.1886C76.7763 66.4675 74.5711 64.2638 71.8481 64.2638C69.1251 64.2638 66.92 66.4675 66.92 69.1886C66.9289 71.9098 69.1341 74.1135 71.8481 74.1135Z'
          stroke='#5F5E60'
          strokeMiterlimit='10'
        />
        <path
          d='M77.7941 41.6648C77.7941 41.6648 76.4103 60.7843 75.2943 66.3248C74.1872 71.8652 62.2597 87.6658 59.7688 88.7721C57.269 89.8784 54.5014 89.6018 52.0016 88.7721C49.5018 87.9423 32.8692 77.4146 31.2087 73.5336C29.5481 69.6526 27.0483 63.559 27.0483 63.559C27.0483 63.559 19.8346 61.8995 19.0043 57.742C18.174 53.5844 18.7276 48.3205 18.7276 48.3205L17.8973 38.3459C17.8973 38.3459 22.0576 21.7156 32.8692 18.6733C43.6808 15.622 59.2153 20.8859 65.5897 26.4353C71.9731 31.9668 77.7941 41.6648 77.7941 41.6648Z'
          fill='white'
          stroke='#5F5E60'
          strokeMiterlimit='10'
        />
        <path
          d='M67.3039 41.2905C67.3039 41.2905 64.6344 45.0555 64.0095 47.7142'
          stroke='#5F5E60'
          strokeMiterlimit='10'
        />
        <path
          d='M66.3575 62.3013C66.3575 62.3013 63.8488 64.9689 59.7688 64.6566'
          stroke='#5F5E60'
          strokeMiterlimit='10'
        />
        <path
          d='M62.5989 72.3295C62.5989 72.3295 53.8139 74.8366 48.1626 68.1006'
          stroke='#5F5E60'
          strokeMiterlimit='10'
        />
        <path
          d='M56.0013 40.6658C56.0013 40.6658 48.7876 37.3736 43.9219 40.8263'
          stroke='#5F5E60'
          strokeMiterlimit='10'
        />
        <path
          d='M68.5538 40.5054C68.5538 40.5054 72.7855 38.6229 75.455 41.7634'
          stroke='#5F5E60'
          strokeMiterlimit='10'
        />
        <path
          d='M49.5732 51.4789C48.878 51.4789 48.3144 50.3565 48.3144 48.9719C48.3144 47.5873 48.878 46.4648 49.5732 46.4648C50.2684 46.4648 50.832 47.5873 50.832 48.9719C50.832 50.3565 50.2684 51.4789 49.5732 51.4789Z'
          fill='#5F5E60'
        />
        <path
          d='M69.6519 48.0354C69.6519 49.4182 70.2143 50.5424 70.9107 50.5424C71.6071 50.5424 72.1695 49.4182 72.1695 48.0354C72.1695 46.6525 71.6071 45.5283 70.9107 45.5283C70.2143 45.5283 69.6519 46.6435 69.6519 48.0354Z'
          fill='#5F5E60'
        />
        <path
          d='M21.9594 62.4526C25.6823 62.4526 28.7089 65.4682 28.7089 69.1975C28.7089 72.9179 25.6913 75.9424 21.9594 75.9424C18.2276 75.9424 15.21 72.9269 15.21 69.1975C15.2189 65.4771 18.2365 62.4526 21.9594 62.4526ZM21.9594 74.1135C24.6824 74.1135 26.8876 71.9098 26.8876 69.1886C26.8876 66.4675 24.6824 64.2638 21.9594 64.2638C19.2364 64.2638 17.0313 66.4675 17.0313 69.1886C17.0402 71.9098 19.2454 74.1135 21.9594 74.1135Z'
          fill='white'
          stroke='#5F5E60'
          strokeMiterlimit='10'
        />
        <path
          d='M43.1273 25.0347L77.7941 41.665C77.7941 41.665 88.8824 33.6264 88.8824 25.8734C88.8824 18.1203 70.5804 1.76661 53.6621 0.6603C36.7439 -0.446006 32.03 3.15841 27.3161 6.75391C22.6022 10.3583 7.63026 22.8221 9.5676 31.967C11.5049 41.1119 18.7186 48.3207 18.7186 48.3207C18.7186 48.3207 18.9954 43.0568 22.0487 44.4397C25.102 45.8226 24.5485 49.9802 26.7626 49.7036C28.9767 49.427 30.3694 45.2694 30.3694 45.2694C30.3694 45.2694 44.2433 33.0733 43.1273 25.0347Z'
          fill='#5F5E60'
        />
      </g>
      <defs>
        <clipPath id='clip0_61_5455'>
          <rect width='214' height='240' fill='white' transform='matrix(-1 0 0 1 178 0)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Woman
