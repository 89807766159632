/* eslint no-unused-vars: 0 */
/* eslint @typescript-eslint/no-unused-vars: 0 */
/* eslint @typescript-eslint/no-empty-function: 0 */
import React, { createContext, useContext, useState } from 'react'
import {
  ActivityArea,
  CarType,
  CreditPurpose,
  EmploymentStatus,
  Gender,
  GoNogo,
  Occupation,
  SubsidyType,
  YesNoV1,
  YesNoV2,
} from '../@types'

// @ts-ignore
Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf())
  date.setDate(date.getDate() + days)
  return date
}

interface ContextValue {
  token: string
  setToken: (_token: string) => void
  formData: {
    selectedProduct: CreditPurpose | ''
    agreeToTermsAndPrivacy: boolean
    agreeToSpamMail: boolean

    loanAmount: number
    balloonAmount: number
    loanTerm: number
    loanInterest: number
    monthlyReturn: number
    subsidyType: SubsidyType
    subsidyMonthlyAmount: number
    interestAfterSubsidy: number

    isVehicleSubjected: boolean
    vehicleLicenseNumber: string
    vehicleType: CarType | ''
    vehicleAge: number
    vehicleBrand: string
    vehicleModel: string
    vehicleModelCode: string
    vehicleBrandCode: string
    isVehicleNew: 'yes' | 'no' | ''
    vehicleValue: number
    vehicleValueFromApi: number

    firstName: string
    lastName: string
    nationalId: string
    dateOfBirth: string
    gender: Gender | ''
    emailAddress: string
    mobileNumber: string

    homeCity: string
    homeStreet: string
    homeNumber: number
    homeZipCode: string
    geographicScore: number | null

    employmentStatus: EmploymentStatus | ''
    businessEstablishmentYear: string
    yearsEmployedCurrent: number | ''
    yearsEmployedPrevious: number | ''
    occupation: Occupation | ''
    fieldOfWork: ActivityArea | ''
    businessMonthlyIncome: number | ''
    monthlyIncome: number | ''
    businessCustomerCount: number | ''
    isHomeOwner: YesNoV2 | ''
    isAdditionalIncome: YesNoV1 | ''
    additionalIncomeAmount: number | ''
    isPortfolioOwner: YesNoV1 | ''
    portfolioAmount: number | ''
    isFinancialService: YesNoV2 | ''

    Consent_Before_Credit_Pool__c: boolean
    Credit_Data_Effective_Date__c: string
    Credit_Data_Transaction_Approved_Ef_Date__c: string
    tranzillaStatus: GoNogo | ''

    isForeignCitizen: YesNoV1 | ''
    isPublicFigure: YesNoV1 | ''
    hasGuarantors: YesNoV1 | ''
    hasBankRejectionsPastYear: YesNoV1 | ''
    hasRefusedCredit: YesNoV1 | ''
    isExemptDealer: YesNoV1 | ''

    debtorBank: string
    debtorBankBranch: string
    debtorBankAccountNumber: string
    debtorBankAccountName: string
  }
  onChangeFormData: (_key: string, _value: any) => void
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const initialValue: ContextValue = {
  token: '',
  setToken: (_token) => {},
  formData: {
    selectedProduct: '',
    agreeToTermsAndPrivacy: false,
    agreeToSpamMail: false,

    loanAmount: 0,
    balloonAmount: 0,
    loanTerm: 24,
    loanInterest: 0,
    monthlyReturn: 0,
    subsidyType: '',
    subsidyMonthlyAmount: 0,
    interestAfterSubsidy: 0,

    isVehicleSubjected: true,
    vehicleLicenseNumber: '',
    vehicleType: '',
    vehicleAge: 0,
    vehicleBrand: '',
    vehicleModel: '',
    isVehicleNew: '',
    vehicleModelCode: '',
    vehicleBrandCode: '',
    vehicleValue: 50000,
    vehicleValueFromApi: 0,

    firstName: '',
    lastName: '',
    nationalId: '',
    dateOfBirth: '',
    gender: '',
    emailAddress: '',
    mobileNumber: '',

    homeCity: '',
    homeStreet: '',
    homeNumber: 0,
    homeZipCode: '',
    geographicScore: null,

    employmentStatus: '',
    businessEstablishmentYear: '',
    yearsEmployedCurrent: '',
    yearsEmployedPrevious: '',
    occupation: '',
    fieldOfWork: '',
    businessMonthlyIncome: '',
    monthlyIncome: '',
    businessCustomerCount: '',
    isHomeOwner: '',
    isAdditionalIncome: '',
    additionalIncomeAmount: '',
    isPortfolioOwner: '',
    portfolioAmount: '',
    isFinancialService: '',

    Consent_Before_Credit_Pool__c: false,
    // @ts-ignore
    Credit_Data_Effective_Date__c: new Date().addDays(60).toISOString().split('T')[0],
    Credit_Data_Transaction_Approved_Ef_Date__c: '',
    tranzillaStatus: '',

    isForeignCitizen: '',
    isPublicFigure: '',
    hasGuarantors: '',
    hasBankRejectionsPastYear: '',
    hasRefusedCredit: '',
    isExemptDealer: '',

    debtorBank: '',
    debtorBankBranch: '',
    debtorBankAccountNumber: '',
    debtorBankAccountName: '',
  },
  onChangeFormData: (_key, _value) => {},
  loading: false,
  setLoading: (_bool) => {},
}

const FormContext = createContext(initialValue)

export const useForm = (): ContextValue => {
  return useContext(FormContext)
}

const FormProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [token, setToken] = useState(initialValue.token)
  const [formData, setFormData] = useState(initialValue.formData)
  const [loading, setLoading] = useState(initialValue.loading)

  const onChangeFormData = (key: string, value: any): void => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }))
  }

  return (
    <FormContext.Provider
      value={{
        token,
        setToken,
        formData,
        onChangeFormData,
        loading,
        setLoading,
      }}
    >
      {children}
    </FormContext.Provider>
  )
}

export default FormProvider
