import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import { FormControl, IconButton, InputLabel, MenuItem, Select, Slider, Typography } from '@mui/material'
import { useRtl } from '../../contexts/RtlContext'
import { useForm } from '../../contexts/FormContext'
import { StepContext } from '../../components/DialogWrapper'
import RedButton from '../../components/buttons/RedButton'
import GreyButton from '../../components/buttons/GreyButton'
import Disclaimer from '../../components/Disclaimer'
import { formatFromCurrency, formatToCurrency } from '../../functions/format-currency'
import Arrows from '../../vectors/Arrows'
import Vacation from '../../vectors/Vacation'
import Education from '../../vectors/Education'
import Liability from '../../vectors/Liability'
import Renovation from '../../vectors/Renovation'
import Investment from '../../vectors/Investment'
import Gift from '../../vectors/Gift'
import getQuery from '../../functions/get-query'

const useStyles = makeStyles(
  () => ({
    inp: {
      padding: '0 1px',
      background: 'none',
      border: 'none',
      borderBottom: '0.5px solid var(--grey)',
      color: 'var(--ampa-red)',
      fontSize: '1.5rem',
      outline: 'none',
    },
    slider: {
      maxWidth: '300px',
      marginTop: '0.3rem',
      '& .MuiSlider-thumb': {
        backgroundColor: 'var(--darker-grey)',
      },
      '& .MuiSlider-rail': {
        backgroundColor: 'var(--darker-grey)',
      },
      '& .MuiSlider-track': {
        backgroundColor: 'var(--ampa-red)',
      },
    },
    markedSlider: {
      '& .MuiSlider-mark': {
        width: 2,
        height: 10,
        backgroundColor: 'var(--ampa-red)',
        borderRadius: '1rem',
      },
    },
  }),
  { name: 'ProductInformation' }
)

const MIN_LOAN_AMOUNT_SOLO = 25000
const MAX_LOAN_AMOUNT_SOLO = 1000000
const LOAN_AMOUNT_SLIDER_INCREMENTS = 200000

export const PRIME_FEE_PERCENT = 0.0625 // 6.25%
export const VEHICLE_INIT_FEE_PERCENT = 0.015 // 1.5%
export const SOLO_INIT_FEE_PERCENT = 0.0075 // 0.75%
export const INTEREST_SUBSIDY_PERCENT = 0.6 // 60%

const ALLOW_SUBJECTIVE_VEHICLE = false

const getPmt = (amount: number, term: number, rate: number): number =>
  (rate / 12 / (1 - 1 / Math.pow(1 + rate / 12, term))) * amount

const getMonthlyReturns = (
  withSubsidy: boolean,
  term: number,
  amount: number,
  isVehicle?: boolean,
  balloonAmount?: number
): {
  monthlyReturn: number
  monthlyReturnAfterDiscount: number
  discountedAmount: number
  interest: number
  discountedInterest: number
} => {
  const interestRate = isVehicle
    ? term <= 45
      ? 0.035
      : term <= 52
      ? 0.04
      : term <= 59
      ? 0.045
      : term <= 66
      ? 0.05
      : term <= 73
      ? 0.055
      : term <= 80
      ? 0.06
      : term <= 87
      ? 0.065
      : term <= 94
      ? 0.07
      : term <= 101
      ? 0.075
      : 1
    : term <= 45
    ? 0.06
    : term <= 52
    ? 0.065
    : term <= 59
    ? 0.07
    : term <= 66
    ? 0.075
    : 1

  const discountedInterest = interestRate * (withSubsidy ? 1 - INTEREST_SUBSIDY_PERCENT : 1)

  const initMontlyReturn = (amount * (isVehicle ? VEHICLE_INIT_FEE_PERCENT : SOLO_INIT_FEE_PERCENT)) / term
  const balloonMonthlyAmount = (((interestRate + PRIME_FEE_PERCENT) * (balloonAmount || 0)) / 12) * 2

  const amountPmt = getPmt(amount - (balloonAmount || 0), term, interestRate + PRIME_FEE_PERCENT)
  const amountPmtAfterDiscount = getPmt(
    amount - (balloonAmount || 0),
    term,
    discountedInterest + PRIME_FEE_PERCENT
  )

  return {
    monthlyReturn: amountPmt + initMontlyReturn + balloonMonthlyAmount,
    monthlyReturnAfterDiscount: amountPmtAfterDiscount + initMontlyReturn + balloonMonthlyAmount,
    discountedAmount: amountPmt - amountPmtAfterDiscount,
    interest: interestRate * 100,
    discountedInterest: discountedInterest * 100,
  }
}

const PlusButton = (): JSX.Element => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='10' cy='10' r='9' fill='#D52027' stroke='#EBD9D9' strokeWidth='2' />
    <path d='M10 14.2431V5.75781M14.2426 10.0005H5.75736' stroke='#EBD9D9' strokeWidth='2' strokeLinecap='round' />
  </svg>
)

const ProductInformation = ({ nextStep }: { nextStep: () => void }): JSX.Element => {
  const styles = useStyles()
  const { branchType, branch, contact } = getQuery()
  const isWithAgent = !!branchType || !!branch || !!contact

  const { t } = useTranslation()
  const { isRtl } = useRtl()
  const { formData, onChangeFormData } = useForm()

  const {
    setPreventBack,
    setHeaderActive,
    setDarkHeading,
    setStepTitle,
    setStepperActive,
    setStepperStep,
    setImageRenderer,
  } = useContext(StepContext)

  const sliderAlert = useRef(false)

  useEffect(() => {
    setPreventBack(false)
    setHeaderActive(true)
    setDarkHeading(true)
    setStepTitle(t('Getting Started'))
    setStepperActive(true)
    setStepperStep(1)
    setImageRenderer(
      formData['selectedProduct'] === 'Car' ? (
        <Arrows />
      ) : formData['selectedProduct'] === 'Trip' ? (
        <Vacation />
      ) : formData['selectedProduct'] === 'Studies' ? (
        <Education />
      ) : formData['selectedProduct'] === 'Liability Coverage' ? (
        <Liability />
      ) : formData['selectedProduct'] === 'Renovations' ? (
        <Renovation />
      ) : formData['selectedProduct'] === 'Business Investment' ? (
        <Investment />
      ) : formData['selectedProduct'] === 'Other' ? (
        <Gift />
      ) : (
        <Arrows />
      )
    )
  }, [isRtl])

  const isVehicle =
    formData['selectedProduct'] === 'Car' && formData['isVehicleSubjected'] && ALLOW_SUBJECTIVE_VEHICLE
  const minLoanTerm = 24
  const maxLoanTerm = isVehicle ? 100 : 60

  useEffect(() => {
    onChangeFormData('isVehicleSubjected', ALLOW_SUBJECTIVE_VEHICLE)
  }, [ALLOW_SUBJECTIVE_VEHICLE])

  const [minLoanAmount, setMinLoanAmount] = useState(isVehicle ? 0 : MIN_LOAN_AMOUNT_SOLO)
  const [maxLoanAmount, setMaxLoanAmount] = useState(
    isVehicle ? Math.round(formData['vehicleValue'] * 0.9) : LOAN_AMOUNT_SLIDER_INCREMENTS
  )

  const isAmountValid = (v: number, withMinAmount = true): boolean =>
    ((!withMinAmount && v >= 0) || (withMinAmount && v >= minLoanAmount)) && v <= maxLoanAmount

  const isBalloonValid = (v: number): boolean =>
    v >= 0 &&
    v / formData['loanAmount'] <=
      (formData['loanTerm'] <= 24
        ? 0.5
        : formData['loanTerm'] <= 36
        ? 0.4
        : formData['loanTerm'] <= 48
        ? 0.3
        : formData['loanTerm'] <= 60
        ? 0.2
        : formData['loanTerm'] <= 72
        ? 0.1
        : 0)

  const isTermValid = (v: number): boolean => v !== 0 && v >= minLoanTerm && v <= maxLoanTerm

  useEffect(() => {
    onChangeFormData('loanAmount', isAmountValid(formData['loanAmount']) ? formData['loanAmount'] : minLoanAmount)
    onChangeFormData('balloonAmount', isBalloonValid(formData['balloonAmount']) ? formData['balloonAmount'] : 0)
    onChangeFormData('loanTerm', isTermValid(formData['loanTerm']) ? formData['loanTerm'] : minLoanTerm)
  }, [isVehicle, minLoanAmount, maxLoanAmount])

  useEffect(() => {
    const res = getMonthlyReturns(
      formData['subsidyType'] === 'סבסוד חלקי',
      formData['loanTerm'],
      formData['loanAmount'],
      isVehicle,
      formData['balloonAmount']
    )

    onChangeFormData('loanInterest', res.interest)
    onChangeFormData('monthlyReturn', res.monthlyReturnAfterDiscount)
    onChangeFormData('subsidyMonthlyAmount', res.discountedAmount * formData['loanTerm'])
    onChangeFormData('interestAfterSubsidy', res.discountedInterest)
  }, [isVehicle, formData['balloonAmount'], formData['loanAmount'], formData['loanTerm'], formData['subsidyType']])

  const onClickContinue = (): void => {
    if (
      !isAmountValid(formData['loanAmount']) ||
      !isTermValid(formData['loanTerm']) ||
      (isVehicle && (!formData['vehicleValue'] || !isBalloonValid(formData['balloonAmount']))) ||
      (isWithAgent && !formData['subsidyType'])
    ) {
      if (isVehicle && !isBalloonValid(formData['balloonAmount'])) {
        toast.error(t('Invalid values'))
        return
      }
      toast.error(t('Required information is missing'))
      return
    }

    nextStep()
  }

  const getWidthForInputValue = (v: string | number, addLength: number) => {
    const len = String(v).length

    return `${len * 0.7 + addLength}rem`
  }

  return (
    <Fragment>
      <main style={{ padding: '0 2rem' }}>
        <div>
          {formData['selectedProduct'] === 'Car' && ALLOW_SUBJECTIVE_VEHICLE ? (
            <div>
              <Typography variant='body1'>{t('Will the vehicle be subjected?')}</Typography>

              <div>
                <RedButton
                  label={t('Yes')}
                  onClick={() => {
                    onChangeFormData('isVehicleSubjected', true)
                    setMinLoanAmount(0)
                    setMaxLoanAmount(Math.round(formData['vehicleValue'] * 0.9))
                  }}
                  selected={formData['isVehicleSubjected']}
                />
                <RedButton
                  label={t('No')}
                  onClick={() => {
                    onChangeFormData('isVehicleSubjected', false)
                    setMinLoanAmount(MIN_LOAN_AMOUNT_SOLO)
                    setMaxLoanAmount(LOAN_AMOUNT_SLIDER_INCREMENTS)
                  }}
                  selected={!formData['isVehicleSubjected']}
                />
              </div>
            </div>
          ) : null}

          {formData['selectedProduct'] === 'Car' && ALLOW_SUBJECTIVE_VEHICLE ? (
            <Disclaimer>{t('subjected_vehicle_disclaimer')}</Disclaimer>
          ) : null}

          <div
            className='flex-col'
            style={{
              margin: '0.5rem auto',
              padding: '0.5rem 0',
              backgroundColor: 'var(--soft-red)',
              borderRadius: '0.5rem',
            }}
          >
            {isVehicle ? (
              <Fragment>
                <p>{t('What is the vehicle price?')}</p>
                <input
                  className={styles.inp}
                  style={{ width: getWidthForInputValue(formData['vehicleValue'], 1) }}
                  value={formatToCurrency(formData['vehicleValue'])}
                  onChange={(e) => {
                    const v = formatFromCurrency(e.target.value)

                    onChangeFormData('vehicleValue', v)
                    setMaxLoanAmount(Math.round(v * 0.9))
                  }}
                />
                <br />
              </Fragment>
            ) : null}

            <p>{t('What is the requested loan amount?')}</p>
            <input
              className={styles.inp}
              style={{ width: getWidthForInputValue(formData['loanAmount'], 1) }}
              value={formatToCurrency(formData['loanAmount'])}
              onChange={(e) => {
                const v = formatFromCurrency(e.target.value)

                if (isAmountValid(v, false)) {
                  onChangeFormData('loanAmount', v)
                }
              }}
            />

            <div style={{ maxWidth: '370px', width: '100%', position: 'relative' }}>
              <Slider
                className={styles.slider}
                size='small'
                valueLabelDisplay='auto'
                min={minLoanAmount}
                max={maxLoanAmount}
                marks={[
                  { value: minLoanAmount, label: formatToCurrency(minLoanAmount) },
                  {
                    value: maxLoanAmount,
                    label: formatToCurrency(maxLoanAmount),
                  },
                ]}
                value={formData['loanAmount']}
                onChange={(e) => {
                  // @ts-ignore
                  const v = e.target.value

                  if (isAmountValid(v)) {
                    onChangeFormData('loanAmount', v)
                  } else {
                    onChangeFormData('loanAmount', minLoanAmount)
                  }
                }}
              />
              {(isVehicle &&
                maxLoanAmount < formData['vehicleValue'] &&
                maxLoanAmount === formData['loanAmount']) ||
              (!isVehicle && maxLoanAmount < MAX_LOAN_AMOUNT_SOLO && maxLoanAmount === formData['loanAmount']) ? (
                <IconButton
                  onClick={() =>
                    setMaxLoanAmount((prev) =>
                      isVehicle
                        ? formData['vehicleValue']
                        : maxLoanAmount < MAX_LOAN_AMOUNT_SOLO
                        ? prev + LOAN_AMOUNT_SLIDER_INCREMENTS
                        : MAX_LOAN_AMOUNT_SOLO
                    )
                  }
                  style={{ position: 'absolute', right: '0' }}
                >
                  <PlusButton />
                </IconButton>
              ) : null}
            </div>
          </div>

          <div className='flex-col' style={{ margin: '0.5rem auto' }}>
            <p>{t('Loan term')}</p>
            <input
              className={styles.inp}
              style={{ width: getWidthForInputValue(formData['loanTerm'], isRtl ? 4 : 5) }}
              value={`${formData['loanTerm'] || minLoanTerm} ${t('months')}`}
              onChange={(e) => {
                const v = Number(e.target.value.split(' ')[0])

                if (isTermValid(v)) {
                  onChangeFormData('loanTerm', v)
                } else {
                  onChangeFormData('loanTerm', minLoanTerm)
                }
              }}
            />

            <Slider
              className={`${styles.slider} ${styles.markedSlider}`}
              size='small'
              valueLabelDisplay='auto'
              min={minLoanTerm || minLoanTerm}
              max={maxLoanTerm}
              marks={(() => {
                const payload = []

                for (let i = minLoanTerm; i <= maxLoanTerm; i++) {
                  if (i === minLoanTerm || i % 12 === 0 || i === maxLoanTerm) {
                    payload.push({ value: i, label: String(i) })
                  }
                }

                return payload
              })()}
              value={formData['loanTerm']}
              onChange={(e) => {
                // @ts-ignore
                const v = e.target.value

                if (isTermValid(v)) {
                  onChangeFormData('loanTerm', v)
                } else {
                  onChangeFormData('loanTerm', minLoanTerm)
                }
              }}
            />
          </div>

          {isVehicle && formData['loanAmount'] ? (
            <div className='flex-col' style={{ margin: '0.5rem auto' }}>
              <p>{t('Return at end of term')}</p>
              <input
                className={styles.inp}
                style={{ width: getWidthForInputValue(formData['balloonAmount'], 1) }}
                value={formatToCurrency(formData['balloonAmount'])}
                onChange={(e) => {
                  const v = formatFromCurrency(e.target.value)

                  if (isBalloonValid(v)) {
                    onChangeFormData('balloonAmount', v)
                  } else {
                    if (!sliderAlert.current) {
                      sliderAlert.current = true
                      toast.error(t('Balloon exceeded the allowed amount'), { duration: 4000 })

                      setTimeout(() => {
                        sliderAlert.current = false
                      }, 4000)
                    }
                  }
                }}
              />

              <Slider
                className={styles.slider}
                size='small'
                valueLabelDisplay='auto'
                min={0}
                max={Math.floor(formData['loanAmount'] / 2)}
                marks={[
                  { value: 0, label: formatToCurrency(0) },
                  {
                    value: Math.floor(formData['loanAmount'] / 2),
                    label: formatToCurrency(Math.floor(formData['loanAmount'] / 2)),
                  },
                ]}
                value={formData['balloonAmount']}
                onChange={(e) => {
                  // @ts-ignore
                  const v = e?.target?.value

                  if (isBalloonValid(v)) {
                    onChangeFormData('balloonAmount', v)
                  } else {
                    if (!sliderAlert.current) {
                      sliderAlert.current = true
                      toast.error(t('Balloon exceeded the allowed amount'), { duration: 4000 })

                      setTimeout(() => {
                        sliderAlert.current = false
                      }, 4000)
                    }
                  }
                }}
              />
            </div>
          ) : null}

          <div className='flex-col' style={{ width: 'fit-content', margin: '0.5rem auto' }}>
            <div>
              <p>{t('Monthly Repayment')}</p>
              <p
                style={{
                  width: 'fit-content',
                  margin: '0 auto',
                  padding: '0.3rem 1.5rem',
                  border: '1px solid var(--ampa-red)',
                  borderRadius: '100px',
                  backgroundColor: 'var(--lighter-grey)',
                  color: 'var(--ampa-red)',
                  fontSize: '1.5rem',
                }}
              >
                {formatToCurrency(Math.round(formData['monthlyReturn']))}
              </p>
              {isWithAgent && formData['subsidyType'] === 'סבסוד חלקי' ? (
                <p
                  style={{
                    width: 'fit-content',
                    margin: '0.5rem auto',
                    padding: '0.3rem 1.5rem',
                    backgroundColor: isWithAgent ? 'var(--soft-red)' : 'transparent',
                    borderRadius: '100px',
                  }}
                >
                  {t('Total Discount:')}{' '}
                  <span
                    style={{
                      color: 'var(--ampa-red)',
                      fontSize: '1.5rem',
                    }}
                  >
                    {formatToCurrency(formData['subsidyMonthlyAmount'])}
                  </span>
                </p>
              ) : null}
            </div>
          </div>

          {isWithAgent ? (
            <div style={{ margin: '0.5rem auto' }}>
              <FormControl variant='standard' fullWidth>
                <InputLabel>{t('Subsidy Type')}</InputLabel>
                <Select
                  value={formData['subsidyType']}
                  onChange={(e) => onChangeFormData('subsidyType', e.target.value)}
                >
                  {[
                    { label: t('No Subsidy'), value: 'ללא סבסוד' },
                    { label: t('Partial Subsidy'), value: 'סבסוד חלקי' },
                  ].map(({ label, value }) => (
                    <MenuItem key={`subsidyType-${value}`} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          ) : null}

          <Disclaimer>{t('end_of_loan_plan_disclaimer')}</Disclaimer>
        </div>
      </main>

      <div style={{ position: 'sticky', bottom: '42px', zIndex: 3, padding: '0 1rem' }}>
        <GreyButton label={t('Next')} onClick={onClickContinue} />
      </div>
    </Fragment>
  )
}

export default ProductInformation
