import { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import {
  Autocomplete,
  Checkbox,
  createFilterOptions,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { useRtl } from '../../contexts/RtlContext'
import { useForm } from '../../contexts/FormContext'
import { StepContext } from '../../components/DialogWrapper'
import RedButton from '../../components/buttons/RedButton'
import GreyButton from '../../components/buttons/GreyButton'
import Disclaimer from '../../components/Disclaimer'
import Woman from '../../vectors/Woman'
import {
  LOAN_JOURNEY_SERVICE,
  SEVENTY_FIVE_YEARS_IN_MS,
  TWENTY_ONE_YEARS_IN_MS,
  TWENTY_FIVE_YEARS_IN_MS,
} from '../../consts'
import { ObjectType } from '../../@types'

const useStyles = makeStyles(
  () => ({
    mobilePrefix: {
      borderRadius: '100px !important',
      '& fieldset': {
        border: '1px solid var(--ampa-red)',
      },
    },
  }),
  { name: 'PersonalInformation' }
)

const PersonalInformation = ({
  nextStep,
  failedCallback,
}: {
  nextStep: () => void
  failedCallback: () => void
}): JSX.Element => {
  const styles = useStyles()
  const { t } = useTranslation()
  const { isRtl } = useRtl()
  const { formData, onChangeFormData } = useForm()
  const {
    setPreventBack,
    setHeaderActive,
    setDarkHeading,
    setStepTitle,
    setStepperActive,
    setStepperStep,
    setImageRenderer,
  } = useContext(StepContext)

  const [missingInfo, setMissingInfo] = useState(false)

  useEffect(() => {
    setPreventBack(false)
    setHeaderActive(true)
    setDarkHeading(true)
    setStepTitle(t('Personal Information'))
    setStepperActive(true)
    setStepperStep(formData['selectedProduct'] === 'Car' ? 3 : 2)
    setImageRenderer(<Woman style={{ opacity: 0.55 }} />)
  }, [isRtl])

  const [mobilePrefix, setMobilePrefix] = useState(
    formData['mobileNumber'] && formData['mobileNumber'].length >= 3
      ? formData['mobileNumber'].substring(0, 3)
      : ''
  )

  const [fetching, setFetching] = useState(false)
  const [fetchedCities, setFetchedCities] = useState<ObjectType[]>([])
  const [fetchedStreets, setFetchedStreets] = useState<ObjectType[]>([])

  const cityNumber = useMemo(
    () => fetchedCities.find((item) => item.cityName.S === formData['homeCity'])?.cityNumber.S || '',
    [fetchedCities, formData['homeCity']]
  )

  const streetNumber = useMemo(
    () => fetchedStreets.find((item) => item.streetName.S === formData['homeStreet'])?.streetNumber.S || '',
    [fetchedStreets, formData['homeStreet']]
  )

  const isNameValid = (name: string) => {
    const regexOk = name.toLowerCase().match('^[A-Za-z :\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*$')

    if (regexOk) {
      return name.length >= 2 && name.length <= 32
    }

    return regexOk
  }

  const isEmailValid = (email: string) => {
    return email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }

  const isPhoneValid = (phone: string) => {
    if (phone.length === 10 && !isNaN(Number(phone))) {
      return true
    } else {
      return false
    }
  }

  const isNationalIdValid = (nationalId: string): boolean => {
    let id = String(nationalId).trim()

    if (id.length > 9 || id.length < 5 || isNaN(Number(nationalId))) return false

    id = id.length < 9 ? ('00000000' + id).slice(-9) : id

    return (
      Array.from(id, Number).reduce((counter, digit, i) => {
        const step = digit * ((i % 2) + 1)

        return counter + (step > 9 ? step - 9 : step)
      }) %
        10 ===
      0
    )
  }

  const isCityValid = (str: string) => {
    return str.length >= 3
  }

  const isStreetValid = (str: string) => {
    return str.length >= 2
  }

  const getCities = async (search: string): Promise<void> => {
    setFetching(true)

    try {
      const { data } = await axios.get(`${LOAN_JOURNEY_SERVICE}/geo/cities?search=${search}`)

      // @ts-ignore
      setFetchedCities(data.sort((a, b) => a.cityName.S.localeCompare(b.cityName.S)))
    } catch (error: any) {
      console.error(error)
      toast.error(
        'אנחנו מתנצלים אך זמנית לא ניתן להמשיך בתהליך, אנא וודא שהחיבור לאינטרנט תקין ונסה שוב בעוד מספר דקות'
      )
    }

    setFetching(false)
  }

  const getStreets = async (cityNumber: string | number, search: string): Promise<void> => {
    setFetching(true)

    try {
      const { data } = await axios.get(
        `${LOAN_JOURNEY_SERVICE}/geo/streets?cityNumber=${cityNumber}&search=${search}`
      )

      // @ts-ignore
      setFetchedStreets(data.sort((a, b) => a.streetName.S.localeCompare(b.streetName)))
    } catch (error: any) {
      console.error(error)
      toast.error(
        'אנחנו מתנצלים אך זמנית לא ניתן להמשיך בתהליך, אנא וודא שהחיבור לאינטרנט תקין ונסה שוב בעוד מספר דקות'
      )
    }

    setFetching(false)
  }

  const getZipCode = async (
    cityNumber: string | number,
    streetNumber: string | number,
    House_Number__c: string | number
  ): Promise<void> => {
    setFetching(true)

    try {
      const { data } = await axios.get(
        `${LOAN_JOURNEY_SERVICE}/geo/zip-code?cityNumber=${cityNumber}&streetNumber=${streetNumber}&houseNumber=${House_Number__c}`
      )

      onChangeFormData('homeZipCode', data.zipCode)
    } catch (error: any) {
      console.error(error)
      toast.error(
        'אנחנו מתנצלים אך זמנית לא ניתן להמשיך בתהליך, אנא וודא שהחיבור לאינטרנט תקין ונסה שוב בעוד מספר דקות'
      )
    }

    setFetching(false)
  }

  useEffect(() => {
    getCities('')
  }, [])

  useEffect(() => {
    if (cityNumber) getStreets(cityNumber, '')
  }, [cityNumber])

  useEffect(() => {
    if (cityNumber && streetNumber && formData['homeNumber']) {
      getZipCode(cityNumber, streetNumber, formData['homeNumber'])
    }
  }, [cityNumber, streetNumber, formData['homeNumber']])

  const onClickContinue = (): void => {
    setMissingInfo(false)

    if (fetching) {
      toast.error(t('Process running in background'))
      return
    }

    if (
      !formData['firstName'] ||
      !formData['lastName'] ||
      !formData['nationalId'] ||
      !formData['mobileNumber'] ||
      !formData['dateOfBirth'] ||
      !formData['homeCity'] ||
      !formData['homeStreet'] ||
      !formData['homeNumber'] ||
      !formData['emailAddress'] ||
      !formData['gender'] ||
      !formData['agreeToTermsAndPrivacy']
    ) {
      setMissingInfo(true)
      toast.error(t('Required information is missing'))
      return
    }

    if (
      !isNationalIdValid(formData['nationalId']) ||
      !isPhoneValid(formData['mobileNumber']) ||
      !isEmailValid(formData['emailAddress']) ||
      !isNameValid(formData['firstName']) ||
      !isNameValid(formData['lastName']) ||
      !isCityValid(formData['homeCity']) ||
      !isStreetValid(formData['homeStreet'])
    ) {
      toast.error(t('Invalid values'))
      return
    }

    if (
      new Date().getTime() - new Date(formData['dateOfBirth']).getTime() < TWENTY_FIVE_YEARS_IN_MS ||
      new Date().getTime() - new Date(formData['dateOfBirth']).getTime() > SEVENTY_FIVE_YEARS_IN_MS
    ) {
      failedCallback()
      return
    }

    nextStep()
  }

  return (
    <Fragment>
      <main>
        <div>
          <div style={{ width: '95%', margin: '0.5rem auto' }}>
            <TextField
              variant='standard'
              label={t('First Name')}
              placeholder={t('John')}
              value={formData['firstName']}
              onChange={(e) => onChangeFormData('firstName', e.target.value)}
              fullWidth
            />
            <p className='err-txt'>
              {missingInfo && !formData['firstName']
                ? t('Required information is missing')
                : formData['firstName'] && !isNameValid(formData['firstName'])
                ? t('First name is invalid')
                : null}
            </p>
          </div>

          <div style={{ width: '95%', margin: '0.5rem auto' }}>
            <TextField
              variant='standard'
              label={t('Last Name')}
              placeholder={t('Doe')}
              value={formData['lastName']}
              onChange={(e) => onChangeFormData('lastName', e.target.value)}
              fullWidth
            />
            <p className='err-txt'>
              {missingInfo && !formData['lastName']
                ? t('Required information is missing')
                : formData['lastName'] && !isNameValid(formData['lastName'])
                ? t('Last name is invalid')
                : null}
            </p>
          </div>

          <div style={{ width: '95%', margin: '0.5rem auto' }}>
            <TextField
              variant='standard'
              label={t('National ID')}
              type='string'
              placeholder='123456789'
              value={formData['nationalId'] || ''}
              onChange={(e) => {
                const v = e.target.value

                if (!isNaN(Number(v)) && v.length <= 9) {
                  onChangeFormData('nationalId', v)
                }
              }}
              fullWidth
            />
            <p className='err-txt'>
              {missingInfo && !formData['nationalId']
                ? t('Required information is missing')
                : formData['nationalId'] && !isNationalIdValid(formData['nationalId'])
                ? t('National ID is invalid')
                : null}
            </p>
          </div>

          <div>
            <div dir='ltr' className='flex-row' style={{ alignItems: 'center' }}>
              <FormControl style={{ width: '4rem', margin: 'auto auto 0.5rem auto' }} size='small'>
                <Select
                  value={mobilePrefix}
                  onChange={(e) => {
                    const prefix = e.target.value
                    const remainingMobileNumber = formData['mobileNumber']
                      ? formData['mobileNumber'].substring(3)
                      : ''

                    setMobilePrefix(prefix)
                    if (remainingMobileNumber.length <= 7) {
                      onChangeFormData('mobileNumber', `${prefix}${remainingMobileNumber}`)
                    }
                  }}
                  className={styles.mobilePrefix}
                >
                  {['050', '051', '052', '053', '054', '055', '056', '058', '059'].map((prefix) => (
                    <MenuItem key={`mobile-prefix-${prefix}`} value={prefix}>
                      {prefix}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                dir={isRtl ? 'rtl' : 'ltr'}
                variant='standard'
                label={t('Phone Number')}
                placeholder='0000000'
                value={formData['mobileNumber'] ? formData['mobileNumber'].substring(3) : ''}
                onChange={(e) => {
                  const prefix = mobilePrefix
                  const remainingMobileNumber = e.target.value

                  onChangeFormData('mobileNumber', `${prefix}${remainingMobileNumber}`)
                }}
                style={{ width: 'calc(95% - 4.5rem)', margin: '0.5rem auto' }}
              />
            </div>
            <p className='err-txt'>
              {missingInfo && (!formData['mobileNumber'] || formData['mobileNumber'].length <= 3)
                ? t('Required information is missing')
                : formData['mobileNumber'] &&
                  formData['mobileNumber'].length > 3 &&
                  !isPhoneValid(formData['mobileNumber'])
                ? t('Phone is invalid')
                : null}
            </p>
          </div>

          <div>
            <TextField
              variant='standard'
              label={t('Date of Birth')}
              type='date'
              value={formData['dateOfBirth']}
              onChange={(e) => {
                const v = e.target.value

                // if (new Date(v).getTime() > new Date().getTime()) {
                //   toast.error('Invalid values')
                // }

                onChangeFormData('dateOfBirth', v)
              }}
              style={{ width: '95%', margin: '0.5rem auto' }}
              focused={!formData['dateOfBirth']}
            />
            <p className='err-txt'>
              {missingInfo && !formData['dateOfBirth']
                ? t('Required information is missing')
                : formData['dateOfBirth'] &&
                  new Date().getTime() - new Date(formData['dateOfBirth']).getTime() < TWENTY_ONE_YEARS_IN_MS
                ? t('You can receive an offer only above the age of 21')
                : null}
            </p>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '45%', margin: '0.5rem auto' }}>
              <Autocomplete
                disablePortal
                autoHighlight
                options={fetching ? [t('Loading...')] : fetchedCities.map((item) => item.cityName.S)}
                filterOptions={createFilterOptions({
                  trim: true,
                })}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='standard'
                    label={t('City')}
                    placeholder={t('Tel Aviv')}
                    value={formData['homeCity']}
                  />
                )}
                value={formData['homeCity']}
                onSelect={(e) => {
                  // @ts-ignore
                  const v = e.target.value

                  if (!fetching) {
                    onChangeFormData('homeCity', v)
                  }
                }}
              />
              <p className='err-txt'>
                {missingInfo && !formData['homeCity']
                  ? t('Required information is missing')
                  : formData['homeCity'] && !isCityValid(formData['homeCity'])
                  ? t('City is invalid')
                  : null}
              </p>
            </div>

            <div style={{ width: '45%', margin: '0.5rem auto' }}>
              <Autocomplete
                disablePortal
                autoHighlight
                options={
                  fetching
                    ? [t('Loading...')]
                    : fetchedStreets
                        .filter((item) => item.streetName.S.charAt(item.streetName.S.length - 1) !== ' ')
                        .map((item) => item.streetName.S)
                }
                filterOptions={createFilterOptions({
                  trim: true,
                })}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='standard'
                    label={t('Street')}
                    placeholder={t('Harbaah St.')}
                    value={formData['homeStreet']}
                  />
                )}
                value={formData['homeStreet']}
                onSelect={(e) => {
                  // @ts-ignore
                  const v = e.target.value

                  if (!fetching) {
                    onChangeFormData('homeStreet', v)
                  }
                }}
              />
              <p className='err-txt'>
                {missingInfo && !formData['homeStreet']
                  ? t('Required information is missing')
                  : formData['homeStreet'] && !isStreetValid(formData['homeStreet'])
                  ? t('Street is invalid')
                  : null}
              </p>
            </div>
          </div>

          <div className='flex-col' style={{ alignItems: 'start' }}>
            <TextField
              variant='standard'
              label={t('House Number')}
              placeholder='28'
              value={formData['homeNumber'] || ''}
              onChange={(e) => {
                const v = Number(e.target.value)

                if (!isNaN(v) && v >= 0) {
                  onChangeFormData('homeNumber', v)
                }
              }}
              style={{ width: '45%', margin: isRtl ? '0.5rem 2.5% 0 auto' : '0.5rem auto 0 2.5%' }}
            />
            <p className='err-txt'>
              {missingInfo && !formData['homeNumber'] ? t('Required information is missing') : null}
            </p>
          </div>

          <div>
            <TextField
              variant='standard'
              label={t('Email Address')}
              type='email'
              placeholder='john.doe@example.com'
              value={formData['emailAddress']}
              onChange={(e) => onChangeFormData('emailAddress', e.target.value)}
              style={{ width: '95%', margin: '0.5rem auto' }}
            />
            <p className='err-txt'>
              {missingInfo && !formData['emailAddress']
                ? t('Required information is missing')
                : formData['emailAddress'] && !isEmailValid(formData['emailAddress'])
                ? t('Email is invalid')
                : null}
            </p>
          </div>

          <div>
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <RedButton
                label={t('Male')}
                onClick={() => onChangeFormData('gender', 'Male')}
                selected={formData['gender'] === 'Male'}
              />
              <RedButton
                label={t('Female')}
                onClick={() => onChangeFormData('gender', 'Female')}
                selected={formData['gender'] === 'Female'}
              />
            </div>
            <p className='err-txt'>
              {missingInfo && !formData['gender'] ? t('Required information is missing') : null}
            </p>
          </div>

          <FormGroup style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    m: 0,
                    ml: 1,
                    color: 'var(--ampa-red)',
                    '&.Mui-checked': {
                      color: 'var(--ampa-red)',
                    },
                  }}
                  defaultChecked={formData['agreeToTermsAndPrivacy']}
                  onChange={(e) => onChangeFormData('agreeToTermsAndPrivacy', e.target.checked)}
                />
              }
              label=''
            />
            <Disclaimer style={{ margin: '0.5rem 0', flex: 0.99 }}>
              <p>
                בלחיצתי על הכפתור מטה אני מאשר/ת כי קראתי את{' '}
                <a
                  href='https://www.ampacapital.co.il/#/privacy'
                  target='_blank'
                  rel='noopener'
                  style={{ color: 'var(--blue)', cursor: 'pointer' }}
                >
                  מדיניות הפרטיות
                </a>{' '}
                ואת{' '}
                <a
                  href='https://www.ampacapital.co.il/#/tos'
                  target='_blank'
                  rel='noopener'
                  style={{ color: 'var(--blue)', cursor: 'pointer' }}
                >
                  תנאי השימוש
                </a>{' '}
                ואני מסכים/מסכימה לאמור בהם, ולעיבוד המידע האישי שלי והעברתו לצדדים שלישיים למטרות המפורטות
                במדיניות הפרטיות.
              </p>
            </Disclaimer>
          </FormGroup>

          <FormGroup style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    m: 0,
                    ml: 1,
                    color: 'var(--ampa-red)',
                    '&.Mui-checked': {
                      color: 'var(--ampa-red)',
                    },
                  }}
                  defaultChecked={formData['agreeToSpamMail']}
                  onChange={(e) => onChangeFormData('agreeToSpamMail', e.target.checked)}
                />
              }
              label=''
            />
            <Disclaimer style={{ margin: '0.5rem 0', flex: 0.99 }}>
              <p>אני מאשר/ת קבלת דיוור שיווקי מטעם אמפא אשראי ומימון בע"מ בדוא"ל, מסרונים ואמצעי תקשורת נוספים.</p>
            </Disclaimer>
          </FormGroup>

          <Disclaimer>{t('credit_score_disclaimer')}</Disclaimer>
        </div>
      </main>

      <div style={{ position: 'sticky', bottom: '42px', zIndex: 3, padding: '0 1rem' }}>
        <GreyButton label={t('Continue')} onClick={onClickContinue} />
      </div>
    </Fragment>
  )
}

export default PersonalInformation
