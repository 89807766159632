import { Fragment, useContext, useEffect, useState } from 'react'
import axios from 'axios'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { useRtl } from '../../contexts/RtlContext'
import { useForm } from '../../contexts/FormContext'
import { StepContext } from '../../components/DialogWrapper'
import GreyButton from '../../components/buttons/GreyButton'
import Disclaimer from '../../components/Disclaimer'
import Loader from '../../components/Loader'
import Car from '../../vectors/Car'
import { GovVehicle } from '../../@types'

const VehicleInformation = ({ nextStep }: { nextStep: () => void }): JSX.Element => {
  const { t } = useTranslation()
  const { isRtl } = useRtl()
  const { formData, onChangeFormData, loading, setLoading } = useForm()
  const {
    setPreventBack,
    setHeaderActive,
    setDarkHeading,
    setStepTitle,
    setStepperActive,
    setStepperStep,
    setImageRenderer,
  } = useContext(StepContext)

  const [lockInputs, setLockInputs] = useState(false)
  const [missingInfo, setMissingInfo] = useState(false)

  useEffect(() => {
    setPreventBack(false)
    setHeaderActive(true)
    setDarkHeading(true)
    setStepTitle(t('Vehicle Information'))
    setStepperActive(true)
    setStepperStep(2)
    setImageRenderer(
      <Car
        style={{
          width: 'calc(100% + 2rem)',
        }}
      />
    )
  }, [isRtl])

  const onClickContinue = (): void => {
    setMissingInfo(false)

    if (!formData['vehicleType'] || !formData['vehicleBrand'] || !formData['vehicleModel']) {
      setMissingInfo(true)
      toast.error(t('Required information is missing'))
      return
    }

    nextStep()
  }

  const getVehicleInfoAndValue = async (): Promise<void> => {
    setLoading(true)
    const GOV_API =
      'https://data.gov.il/api/3/action/datastore_search?resource_id=053cea08-09bc-40ec-8f7a-156f0677aff3'

    if (!formData['vehicleLicenseNumber']) {
      setLockInputs(false)
      toast.error(t('Please type a license number'))
    } else {
      try {
        //fix: add this to our backend
        const { data: govData } = await axios.get(`${GOV_API}&q=${formData['vehicleLicenseNumber']}`)

        if (!govData.result.records.length) {
          setLockInputs(false)
          throw new Error(t('No registered vehicle found!'))
        }

        const govVehicle: GovVehicle = govData.result.records.find(
          (obj: GovVehicle) => obj.mispar_rechev === Number(formData['vehicleLicenseNumber'])
        )

        if (!govVehicle) {
          setLockInputs(false)
          throw new Error(t('No registered vehicle found!'))
        }

        toast.success(t('Succesfully got vehicle information'))
        setLockInputs(true)

        onChangeFormData(
          'vehicleType',
          govVehicle.baalut === 'פרטי' ? 'Private' : govVehicle.baalut === 'חברה' ? 'Commercial' : ''
        )
        onChangeFormData('vehicleBrand', govVehicle.tozeret_nm)
        onChangeFormData('vehicleBrandCode', govVehicle.tozeret_cd)
        onChangeFormData('vehicleModel', govVehicle.kinuy_mishari)
        onChangeFormData('vehicleModelCode', govVehicle.degem_cd)
        onChangeFormData('vehicleAge', new Date().getFullYear() - govVehicle.shnat_yitzur)

        const [onRoadYear, onRoadMonth] = govVehicle.moed_aliya_lakvish.split('-')
        const nowDate = new Date().getTime()
        const onRoadDate = new Date(Number(onRoadYear), Number(onRoadMonth) + 1).getTime()
        const yearInMiliseconds = 3.156e10

        onChangeFormData('isVehicleNew', nowDate - onRoadDate <= yearInMiliseconds ? 'yes' : 'no')
      } catch (error: any) {
        console.error(error)
        toast.error(
          'אנחנו מתנצלים אך זמנית לא ניתן להמשיך בתהליך, אנא וודא שהחיבור לאינטרנט תקין ונסה שוב בעוד מספר דקות'
        )
        setLockInputs(false)
      }
    }

    setLoading(false)
  }

  return (
    <Fragment>
      <main>
        <div>
          <TextField
            variant='standard'
            label={t('Registration Number')}
            placeholder='123-45-678'
            value={formData['vehicleLicenseNumber']}
            onChange={(e) => onChangeFormData('vehicleLicenseNumber', e.target.value)}
            style={{ width: '95%', margin: '0.5rem auto' }}
          />
          <Disclaimer>{t('registration_number_disclaimer')}</Disclaimer>

          <div style={{ marginBottom: '2rem' }}>
            {loading ? <Loader /> : <GreyButton label={t('Search')} onClick={getVehicleInfoAndValue} />}
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '45%', margin: '0.5rem auto', textAlign: 'start' }}>
              <FormControl style={{ width: '100%' }}>
                <InputLabel id='select_vehicle_type'>{t('Vehicle Type')}</InputLabel>
                <Select
                  variant='standard'
                  labelId='select_vehicle_type'
                  label={t('Vehicle Type')}
                  value={formData['vehicleType']}
                  onChange={(e) => onChangeFormData('vehicleType', e.target.value)}
                  disabled={lockInputs}
                >
                  {[
                    { label: t('Private'), value: 'Private' },
                    { label: t('Commercial'), value: 'Commercial' },
                  ].map((obj) => (
                    <MenuItem key={`vehicleType-${obj.value}`} value={obj.value}>
                      {obj.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <p className='err-txt'>
                {missingInfo && !formData['vehicleType'] ? t('Required information is missing') : null}
              </p>
            </div>

            <TextField
              variant='standard'
              label={t('Vehicle Age (years)')}
              value={formData['vehicleAge'] || ''}
              onChange={(e) => {
                const v = Number(e.target.value)

                onChangeFormData('vehicleAge', v)
                if (v <= 1) {
                  onChangeFormData('isVehicleNew', 'yes')
                } else {
                  onChangeFormData('isVehicleNew', 'no')
                }
              }}
              disabled={lockInputs}
              style={{ width: '45%', margin: '0.5rem auto' }}
            />
          </div>

          <div style={{ width: '95%', margin: '0.5rem auto' }}>
            <TextField
              variant='standard'
              label={t('Vehicle Brand')}
              placeholder={t('Audi')}
              value={formData['vehicleBrand']}
              onChange={(e) => onChangeFormData('vehicleBrand', e.target.value)}
              disabled={lockInputs}
              style={{ width: '100%' }}
            />
            <p className='err-txt'>
              {missingInfo && !formData['vehicleBrand'] ? t('Required information is missing') : null}
            </p>
          </div>
          <div style={{ width: '95%', margin: '0.5rem auto' }}>
            <TextField
              variant='standard'
              label={t('Vehicle Model')}
              placeholder='A4'
              value={formData['vehicleModel']}
              onChange={(e) => onChangeFormData('vehicleModel', e.target.value)}
              disabled={lockInputs}
              style={{ width: '100%' }}
            />
            <p className='err-txt'>
              {missingInfo && !formData['vehicleBrand'] ? t('Required information is missing') : null}
            </p>
          </div>
          <FormControl style={{ width: '95%', margin: '0.5rem 0', textAlign: 'start' }}>
            <InputLabel id='select_vehicle_is_new'>{t('Is the vehicle new? (up to 1 year on-road)')}</InputLabel>
            <Select
              variant='standard'
              labelId='select_vehicle_is_new'
              label={t('Is the vehicle new? (up to 1 year on-road)')}
              value={formData['isVehicleNew']}
              onChange={(e) => onChangeFormData('isVehicleNew', e.target.value)}
              disabled={lockInputs}
            >
              {[
                { label: t('Yes'), value: 'yes' },
                { label: t('No'), value: 'no' },
              ].map((obj) => (
                <MenuItem key={`isVehicleNew-${obj.label}`} value={obj.value}>
                  {obj.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Disclaimer>{t('vehicle_information_disclaimer')}</Disclaimer>
        </div>
      </main>

      <div style={{ position: 'sticky', bottom: '42px', zIndex: 3, padding: '0 1rem' }}>
        {loading ? <Loader /> : <GreyButton label={t('Continue')} onClick={onClickContinue} />}
      </div>
    </Fragment>
  )
}

export default VehicleInformation
