const formatBigNumberToCommas = (v: number | string): string => {
  if (v === '') return ''

  const num = Number(v)

  if (isNaN(num)) {
    return String(v)
  } else {
    return num.toLocaleString('he-IL')
  }
}

const formatBigNumberFromCommas = (v: string): string => {
  const str = String(v)

  return str.replaceAll(',', '')
}

export { formatBigNumberToCommas, formatBigNumberFromCommas }
