import { useState } from 'react'

const Star = ({ selected }: { selected: boolean }) => {
  const [hover, setHover] = useState(selected)

  return (
    <svg
      width='103'
      height='102'
      viewBox='0 0 103 102'
      fill='none'
      onMouseEnter={() => {
        if (!selected) {
          setHover(true)
        }
      }}
      onMouseLeave={() => {
        if (!selected) {
          setHover(false)
        }
      }}
    >
      <circle cx='51.5' cy='51' r='51' fill={hover ? 'var(--ampa-red)' : 'var(--lighter-grey)'} />

      <g clipPath='url(#clip0_1729_1551)'>
        <path
          d='M52 25.424l.64 1.26 7.638 15.028v.01c.1.209.254.395.44.526.188.143.409.23.64.263l16.743 2.366 1.466.208-1.069 1.019-12.003 11.358c-.188.187-.331.406-.408.658-.077.252-.1.515-.055.777l2.866 16.255.242 1.424-1.278-.657-15.233-7.777a1.4 1.4 0 00-.629-.153c-.22 0-.43.054-.617.153L36.15 75.919l-1.29.657.254-1.413 2.866-16.255a1.552 1.552 0 00-.055-.777 1.577 1.577 0 00-.408-.658l-12.015-11.38-1.069-1.008 1.466-.208 16.743-2.366a1.45 1.45 0 001.08-.789v-.01l7.628-15.028.65-1.26zM52 24c-.54 0-1.036.307-1.278.778l-.64 1.26-7.627 15.027c-.022 0-.066.033-.1.044L25.69 43.464l-1.466.208c-.54.077-.992.46-1.158.975a1.433 1.433 0 00.375 1.468l1.07 1.018 12.014 11.37a.343.343 0 01.044.066.24.24 0 01.01.087l-2.887 16.255-.254 1.413c-.099.537.122 1.074.563 1.391.253.186.55.274.848.274.22 0 .452-.055.65-.153l1.28-.657 15.2-7.766 15.2 7.766 1.278.657c.21.11.43.153.65.153.298 0 .595-.099.849-.274.44-.317.65-.865.562-1.39l-.254-1.414L67.4 58.656c-.011-.043 0-.065 0-.098a.176.176 0 01.044-.077l12.003-11.37 1.07-1.018a1.422 1.422 0 00-.794-2.443l-1.466-.208-16.732-2.355h-.034l-7.572-15.05-.64-1.26A1.445 1.445 0 0052 24z'
          fill={hover ? 'var(--lighter-grey)' : 'var(--ampa-red)'}
          stroke={hover ? 'var(--ampa-red)' : 'var(--lighter-grey)'}
          strokeWidth='0.4'
        />
      </g>
      <defs>
        <clipPath id='clip0_1729_1551'>
          <path fill='#fff' d='M0 0H58V54H0z' transform='translate(23 24)'></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default Star
