import { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import { Autocomplete, createFilterOptions, TextField } from '@mui/material'
import { useRtl } from '../../contexts/RtlContext'
import { useForm } from '../../contexts/FormContext'
import { StepContext } from '../../components/DialogWrapper'
import GreyButton from '../../components/buttons/GreyButton'
import MoneyJar from '../../vectors/MoneyJar'
import Loader from '../../components/Loader'
import { DigitalRequest } from '../../@types'
import bankData from '../../data/banks.json'

const BankInformation = ({
  nextStep,
  updateDigitalRequest,
  digitalRequestId,
}: {
  nextStep: () => void
  updateDigitalRequest: (
    payload: Partial<DigitalRequest>,
    token: string,
    digitalRequestId: string
  ) => Promise<AxiosResponse<any, any>>
  digitalRequestId: string
}): JSX.Element => {
  const { t } = useTranslation()
  const { isRtl } = useRtl()
  const { token, formData, onChangeFormData, loading, setLoading } = useForm()
  const { setPreventBack, setHeaderActive, setDarkHeading, setStepTitle, setStepperActive, setImageRenderer } =
    useContext(StepContext)

  const [missingInfo, setMissingInfo] = useState(false)

  useEffect(() => {
    setPreventBack(true)
    setHeaderActive(true)
    setDarkHeading(true)
    setStepTitle(t('Bank Information for Charge'))
    setStepperActive(false)
    setImageRenderer(null)
  }, [isRtl])

  const isNameValid = (name: string) => {
    const regexOk = name.toLowerCase().match('^[A-Za-z :\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*$')

    if (regexOk) {
      return name.length >= 2 && name.indexOf(' ') !== -1
    }

    return regexOk
  }

  const onClickContinue = async (): Promise<void> => {
    setMissingInfo(false)

    if (
      !formData['debtorBankAccountName'] ||
      !formData['debtorBank'] ||
      !formData['debtorBankBranch'] ||
      !formData['debtorBankAccountNumber']
    ) {
      setMissingInfo(true)
      toast.error(t('Required information is missing'))
      return
    }

    if (!isNameValid(formData['debtorBankAccountName'])) {
      toast.error(t('Invalid values'))
      return
    }

    setLoading(true)

    await updateDigitalRequest(
      {
        Bank_Account_Owner_Name__c: formData['debtorBankAccountName'],
        Bank_Account_Number__c: Number(formData['debtorBankAccountNumber']),
        Bank_Number__c: Number(formData['debtorBank'].split('#')[0]),
        Bank_Name__c: Number(formData['debtorBank'].split('#')[0]),
        // Bank_Name__c: formData['debtorBank'].split('#')[1].replace('"', ''),
        Bank_Branch_Number__c: Number(formData['debtorBankBranch'].split('#')[0]),
        Bank_Branch_Name__c: formData['debtorBankBranch'].split('#')[1],
        End_Digital_Request__c: true,
      },
      token,
      digitalRequestId
    )

    setLoading(false)
    nextStep()
  }

  return (
    <main>
      <div>
        <p style={{ padding: '1rem', fontSize: '0.8rem', textAlign: 'start' }}>
          כאן המקום להזין את פרטי חשבון הבנק שלך שאליו יועברו כספי ההלוואה וממנו יחויבו תשלומי ההלוואה.
          <br />
          החשבון צריך להיות על שמך (יכול להיות חשבון משותף לך ולבן/בת זוגך).
          <br />
          <br />
          לתשומת לבך, במקרה של הלוואה למטרת רכישת שירות או מוצר, לרבות חתימה על מסמכי ההלוואה בבית העסק, כספי
          ההלוואה יועברו לאותו בית העסק באופן ישיר ותשלומי ההלוואה יחויבו מהחשבון שיוזן. אמפא קפיטל לא תהיה אחראית
          על השירותים ו/או המוצרים שיוצעו ו/או יימכרו ו/או יסופקו ללווה ע"י בית העסק הנ"ל, לרבות הספקתם, התאמתם,
          טיבם ואיכותם והלווה לא יבוא בכל טענה בענין זה כלפי המלווה.
        </p>

        <div style={{ width: '95%', margin: '0.5rem auto' }}>
          <TextField
            variant='standard'
            label={t('Account Name')}
            placeholder={t('John Doe')}
            value={formData['debtorBankAccountName']}
            onChange={(e) => onChangeFormData('debtorBankAccountName', e.target.value)}
            fullWidth
          />
          <p className='err-txt'>
            {missingInfo && !formData['debtorBankAccountName']
              ? t('Required information is missing')
              : formData['debtorBankAccountName'] && !isNameValid(formData['debtorBankAccountName'])
              ? t('Bank account name is invalid')
              : null}
          </p>
        </div>

        <div style={{ width: '95%', margin: '0.5rem auto' }}>
          <Autocomplete
            options={bankData
              .sort((a, b) => a.bankName.localeCompare(b.bankName))
              .map(({ bankCode, bankName }) => `${bankName} (${bankCode})`)}
            value={
              bankData
                .filter(({ bankCode, bankName }) => `${bankCode}#${bankName}` === formData['debtorBank'])
                .map(({ bankCode, bankName }) => `${bankName} (${bankCode})`)[0] || ''
            }
            onSelect={(e) => {
              // @ts-ignore
              const arr = e.target.value.split(' ')
              const branchCode = arr.pop().replace('(', '').replace(')', '')
              const v = `${branchCode}#${arr.join(' ')}`

              onChangeFormData('debtorBank', v)
            }}
            disablePortal
            autoHighlight
            filterOptions={createFilterOptions({ trim: true })}
            renderInput={(params) => <TextField {...params} variant='standard' label={t('Bank')} />}
          />
          <p className='err-txt'>
            {missingInfo && !formData['debtorBank'] ? t('Required information is missing') : null}
          </p>
        </div>

        <div style={{ width: '95%', margin: '0.5rem auto' }}>
          <Autocomplete
            options={bankData
              .filter(({ bankCode, bankName }) => `${bankCode}#${bankName}` === formData['debtorBank'])
              .map(({ branches }) =>
                branches
                  .sort((a, b) => a.branchName.localeCompare(b.branchName))
                  .map(({ branchCode, branchName }) => `${branchName} (${branchCode})`)
              )
              .flat()}
            value={
              bankData
                .filter(({ bankCode, bankName }) => `${bankCode}#${bankName}` === formData['debtorBank'])
                .map(({ branches }) =>
                  branches
                    .filter(
                      ({ branchCode, branchName }) =>
                        `${branchCode}#${branchName}` === formData['debtorBankBranch']
                    )
                    .map(({ branchCode, branchName }) => `${branchName} (${branchCode})`)
                )
                .flat()[0] || ''
            }
            onSelect={(e) => {
              // @ts-ignore
              const arr = e.target.value.split(' ')
              const branchCode = arr.pop().replace('(', '').replace(')', '')
              const v = `${branchCode}#${arr.join(' ')}`

              onChangeFormData('debtorBankBranch', v)
            }}
            disablePortal
            autoHighlight
            filterOptions={createFilterOptions({ trim: true })}
            renderInput={(params) => <TextField {...params} variant='standard' label={t('Branch Number')} />}
            fullWidth
          />
          <p className='err-txt'>
            {missingInfo && !formData['debtorBankBranch'] ? t('Required information is missing') : null}
          </p>
        </div>

        <div style={{ width: '95%', margin: '0.5rem auto' }}>
          <TextField
            variant='standard'
            label={t('Account Number')}
            placeholder='000000000'
            value={formData['debtorBankAccountNumber']}
            onChange={(e) => {
              const v = e.target.value

              if (!isNaN(Number(v)) && v.length <= 9) {
                onChangeFormData('debtorBankAccountNumber', v)
              }
            }}
            fullWidth
          />
          <p className='err-txt'>
            {missingInfo && !formData['debtorBankAccountNumber'] ? t('Required information is missing') : null}
          </p>
        </div>
      </div>

      <div>
        <MoneyJar />
        {loading ? <Loader /> : <GreyButton label={t('Continue')} onClick={onClickContinue} />}

        <p style={{ margin: '0.5rem', color: 'var(--grey)', fontSize: '0.8rem', textAlign: 'start' }}>
          {t('bank_disclaimer')}
        </p>
      </div>
    </main>
  )
}

export default BankInformation
