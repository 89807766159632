import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FormHelperText, Typography } from '@mui/material'
import { useRtl } from '../../contexts/RtlContext'
import { useForm } from '../../contexts/FormContext'
import { StepContext } from '../../components/DialogWrapper'
import formatPhone from '../../functions/format-phone'
import ReactOtpInput from 'react-otp-input'
import Loader from '../../components/Loader'
import { AUTH_SERVICE_URL, CLIENT_ID } from '../../consts'
import ManWithPhone from '../../vectors/ManWithPhone'

const MAX_ATTEMPTS = 5

export default function Otp({
  nextStep,
  failedCallback,
}: {
  nextStep: () => void
  failedCallback: () => void
}): JSX.Element {
  const { t } = useTranslation()
  const { isRtl } = useRtl()
  const { formData, setToken } = useForm()
  const { setPreventBack, setHeaderActive, setDarkHeading, setStepTitle, setStepperActive, setImageRenderer } =
    useContext(StepContext)

  useEffect(() => {
    setPreventBack(false)
    setHeaderActive(true)
    setDarkHeading(false)
    setStepTitle(t('Verify Phone Number'))
    setStepperActive(false)
    setImageRenderer(<ManWithPhone />)
  }, [isRtl])

  const phone = formatPhone(formData['mobileNumber'])
  const [otp, setOtp] = useState('')
  const [attempts, setAttempts] = useState(0)

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const sendOtpHandler = async (): Promise<void> => {
    setError('')
    setIsLoading(true)

    try {
      const res = await axios.post(`${AUTH_SERVICE_URL}/send-otp `, { phoneNumber: phone, clientId: CLIENT_ID })

      if (res.status !== 200) {
        console.warn('unexpected response', res)
        setError(t('Invalid phone number'))
      }
    } catch (error: any) {
      console.error(error)
      toast.error(
        'אנחנו מתנצלים אך זמנית לא ניתן להמשיך בתהליך, אנא וודא שהחיבור לאינטרנט תקין ונסה שוב בעוד מספר דקות'
      )
      setError(error.message)
    }

    setIsLoading(false)
  }

  const verifyOtpHandler = async (): Promise<void> => {
    if (attempts === MAX_ATTEMPTS) {
      return
    }

    if (!otp) {
      return setError(t('Please enter a code'))
    }

    const v = otp

    setOtp('')
    setError('')
    setIsLoading(true)

    try {
      const otpRes = await axios.post(`${AUTH_SERVICE_URL}/verify-otp`, {
        phoneNumber: phone,
        otp: v,
        clientId: CLIENT_ID,
      })

      if (otpRes.status !== 200 || !otpRes.data.accessToken) {
        console.warn('unexpected response', otpRes)
        setError(t('Invalid code'))
      } else {
        setToken(otpRes.data.accessToken)
        nextStep()
      }
    } catch (error: any) {
      console.error(error)
      toast.error(
        'אנחנו מתנצלים אך זמנית לא ניתן להמשיך בתהליך, אנא וודא שהחיבור לאינטרנט תקין ונסה שוב בעוד מספר דקות'
      )
      setError(error.message)
    }

    setAttempts((prev) => prev + 1)
    setIsLoading(false)
  }

  useEffect(() => {
    sendOtpHandler()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (attempts === MAX_ATTEMPTS) failedCallback()
  }, [attempts]) // eslint-disable-line

  useEffect(() => {
    if (otp.length === 6) verifyOtpHandler()
  }, [otp]) // eslint-disable-line

  return (
    <main>
      <br />

      <div>
        <div dir='ltr'>
          <Typography style={{ padding: '0.5rem', letterSpacing: '0.2' }}>
            {t('Please enter the verification code sent to:')}
            <br />
            {formatPhone(formData['mobileNumber'], true)}
          </Typography>

          {isLoading ? (
            <Loader />
          ) : (
            <ReactOtpInput
              value={otp}
              onChange={(otp: string) => setOtp(otp)}
              numInputs={6}
              separator={<span style={{ padding: 6 }} />}
              containerStyle={{ display: 'flex', justifyContent: 'center' }}
              inputStyle={{
                height: '50px',
                width: '42px',
                borderRadius: '0.5rem',
                border: '1px solid #D4D4D4',
                fontSize: '1.5rem',
                color: 'var(--ampa-red)',
              }}
              shouldAutoFocus={true}
              isInputNum={true}
            />
          )}

          {error ? (
            <FormHelperText error sx={{ display: 'flex', justifyContent: 'center' }}>
              {error}
            </FormHelperText>
          ) : null}

          {attempts ? (
            <FormHelperText error sx={{ display: 'flex', justifyContent: 'center' }}>
              {MAX_ATTEMPTS - attempts} {t('attempts left')}
            </FormHelperText>
          ) : null}
        </div>

        <br />

        <div>
          <Typography sx={{ fontSize: 14, color: 'var(--darker-grey)' }}>
            {t('Did you not get the verification code?')}
          </Typography>
          <Typography
            onClick={() => {
              setAttempts(0)
              sendOtpHandler()
            }}
            style={{
              paddingTop: '0',
              paddingBottom: '0.75rem',
              fontSize: '14px',
              color: '#3583eb',
              cursor: 'pointer',
            }}
          >
            {t('Send again')}
          </Typography>
        </div>
      </div>

      <br />
    </main>
  )
}
