import AmpaLogoClean from '../../icons/AmpaLogoClean'
import 'animate.css'

const LoadingScreen = (): JSX.Element => {
  return (
    <main>
      <div
        className='animate__animated animate__infinite animate__slow animate__heartBeat'
        style={{ margin: 'auto' }}
      >
        <AmpaLogoClean />
      </div>
    </main>
  )
}

export default LoadingScreen
