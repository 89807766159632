import { useState, createContext, useEffect, Fragment } from 'react'
import { makeStyles } from '@mui/styles'
import { Dialog, IconButton, Typography, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useRtl } from '../contexts/RtlContext'
import Close from '../icons/Close'
import SelectLanguage from './SelectLanguage'
import StepperCar from '../icons/StepperCar'

type StylesProps = { isMobile: boolean; isRtl: boolean }

const useStyles = makeStyles(
  () => ({
    root: {
      direction: ({ isRtl }: StylesProps) => (isRtl ? 'rtl' : 'ltr'),
      width: ({ isMobile }: StylesProps) => (isMobile ? '100vw' : '420px'),
      height: ({ isMobile }: StylesProps) => (isMobile ? '100vh' : '85vh'),
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'hidden',
      '& main': {
        flex: 1,
        width: '100%',
        padding: '0 1rem',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        zIndex: 1,
      },
    },
    header: {
      padding: '0.5rem',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      position: 'sticky',
      top: 0,
      zIndex: 2,
      '& svg': {
        width: 25,
        height: 25,
      },
    },
    imageRenderer: {
      opacity: 0.5,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      bottom: 0,
      left: 0,
      zIndex: 0,
      '& svg': {
        width: '100%',
      },
    },
    footer: {
      padding: '0.5rem 0',
      backgroundColor: 'white',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'sticky',
      bottom: 0,
      zIndex: 2,
    },
  }),
  { name: 'Dialog' }
)

const EmptyDiv = (): JSX.Element => <div style={{ width: '41px' }} />

export const StepContext = createContext({
  step: 0,
  setStep: (value: number | ((prevState: number) => number)) => {},
  increment: () => {},
  decrement: () => {},
  setPreventBack: (value: boolean | ((prevState: boolean) => boolean)) => {},

  setHeaderActive: (value: boolean | ((prevState: boolean) => boolean)) => {},
  setDarkHeading: (value: boolean | ((prevState: boolean) => boolean)) => {},
  setStepTitle: (value: string | ((prevState: string) => string)) => {},

  setStepperActive: (value: boolean | ((prevState: boolean) => boolean)) => {},
  setMaxSteps: (value: number | ((prevState: number) => number)) => {},
  setStepperStep: (value: number | ((prevState: number) => number)) => {},

  setImageRenderer: (value: JSX.Element | null | ((prevState: JSX.Element | null) => JSX.Element | null)) => {},
})

export interface DialogWrapperProps {
  children: JSX.Element | null
  onClickClose: (step: number) => void
  onClickBack: (step: number) => void
}

export default function DialogWrapper({
  children,
  onClickClose = (step) => {},
  onClickBack = (step) => {},
}: DialogWrapperProps): JSX.Element {
  const isMobile = useMediaQuery('(max-width: 768px)')
  const { isRtl } = useRtl()
  const { t } = useTranslation()
  const classes = useStyles({ isMobile, isRtl })

  const [step, setStep] = useState(1)
  const [preventBack, setPreventBack] = useState(false)

  const [headerActive, setHeaderActive] = useState(false)
  const [darkHeading, setDarkHeading] = useState(false)
  const [stepTitle, setStepTitle] = useState('')

  const [stepperActive, setStepperActive] = useState(false)
  const [maxSteps, setMaxSteps] = useState(5)
  const [stepperStep, setStepperStep] = useState(0)

  const [imageRenderer, setImageRenderer] = useState<JSX.Element | null>(null)

  const increment = (): void => {
    setStep((prev) => {
      return prev + 1
    })
  }

  const decrement = (): void => {
    setStep((prev) => {
      if (prev === 1) {
        return prev
      }

      return prev - 1
    })
  }

  const [_, triggerRender] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      triggerRender((prev) => !prev)
    }, 100)
  }, [isMobile, headerActive, stepperActive, stepTitle])

  return (
    <StepContext.Provider
      value={{
        step,
        setStep,
        increment,
        decrement,
        setPreventBack,
        setHeaderActive,
        setDarkHeading,
        setStepTitle,
        setStepperActive,
        setMaxSteps,
        setStepperStep,
        setImageRenderer,
      }}
    >
      <Dialog open fullScreen={isMobile} sx={{ p: 0 }}>
        <div
          className={classes.root}
          style={step === 1 ? { backgroundColor: 'white' } : { backgroundColor: 'var(--lighter-grey)' }}
        >
          {headerActive ? (
            <header
              className={classes.header}
              style={
                step === 1
                  ? { backgroundColor: 'white', color: 'black' }
                  : darkHeading
                  ? { backgroundColor: 'var(--darker-grey)', color: 'var(--lighter-grey)' }
                  : { backgroundColor: 'var(--lighter-grey)', color: 'black' }
              }
            >
              {!preventBack ? (
                <IconButton
                  style={{ fontSize: '1rem', color: darkHeading ? 'var(--lighter-grey)' : 'var(--grey)' }}
                  onClick={() => {
                    onClickBack(step)
                    decrement()
                  }}
                >
                  {t('Back')}
                </IconButton>
              ) : (
                <EmptyDiv />
              )}

              {step === 1 ? (
                <SelectLanguage color={'var(--grey)'} />
              ) : stepTitle ? (
                <Typography variant='h4' textAlign='center' fontSize='2rem' fontWeight='300' margin='0'>
                  {stepTitle}
                </Typography>
              ) : null}

              <IconButton
                onClick={() => {
                  onClickClose(step)
                }}
              >
                <Close color={darkHeading ? 'var(--lighter-grey)' : 'var(--grey)'} />
              </IconButton>
            </header>
          ) : null}

          {children}

          {stepperActive ? (
            <footer className={classes.footer}>
              {new Array(maxSteps - 1).fill(1).map((one, idx) => (
                <Fragment key={`stepper-${idx}`}>
                  <div
                    style={{
                      width: 22,
                      height: 22,
                      margin: 0,
                      padding: 0,
                      fontSize: 14,
                      fontWeight: 600,
                      color: idx + one <= stepperStep ? 'var(--lighter-grey)' : '#EEEEEE',
                      backgroundColor: idx + one <= stepperStep ? 'var(--darker-grey)' : '#FCFCFC',
                      borderRadius: '100%',
                      border: `1px solid ${
                        idx + one <= stepperStep ? 'var(--darker-grey)' : 'var(--lighter-grey)'
                      }`,
                    }}
                  >
                    {idx + one}
                  </div>
                  <div
                    style={{
                      width: '30px',
                      height: '3px',
                      backgroundColor: idx + one <= stepperStep ? 'var(--darker-grey)' : 'var(--lighter-grey)',
                    }}
                  />
                </Fragment>
              ))}
              <StepperCar active={stepperStep === maxSteps} />
            </footer>
          ) : null}

          {imageRenderer ? <div className={classes.imageRenderer}>{imageRenderer}</div> : null}
        </div>
      </Dialog>
    </StepContext.Provider>
  )
}
