import React from 'react'

const ManWithWallet = ({ style = {} }: { style?: React.CSSProperties }) => {
  return (
    <svg
      width='166'
      height='311'
      viewBox='0 0 166 311'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
    >
      <g clipPath='url(#clip0_279_17771)'>
        <path
          d='M34.92 195.89C31.85 190.83 17.13 190.91 15.8 193.16C15.8 193.16 23.1 216.74 31.23 214.3C39.36 211.85 34.92 195.89 34.92 195.89Z'
          fill='#EBD0D0'
        />
        <path
          opacity='0.3'
          d='M34.92 195.89C31.85 190.83 17.13 190.91 15.8 193.16C15.8 193.16 23.1 216.74 31.23 214.3C39.36 211.85 34.92 195.89 34.92 195.89Z'
          fill='#414042'
        />
        <path
          d='M34.92 195.89C31.85 190.83 17.13 190.91 15.8 193.16C15.8 193.16 23.1 216.74 31.23 214.3C39.36 211.85 34.92 195.89 34.92 195.89Z'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.36 207.89C17.36 207.89 15.8 209.67 15.8 212.34C15.8 214.54 20.0001 258.47 32.9501 273.76C35.66 276.97 38.76 278.92 42.2901 278.96C53.25 279.08 58.5601 270.38 65.5401 250.21C66.41 247.7 67.31 245 68.24 242.12C75.02 221.3 83.36 177.87 83.36 177.87C83.36 177.87 68.57 181.3 59.8 190C55.97 193.8 51.97 204.59 49.42 212.51C48.16 216.4 47.2501 219.6 46.8801 220.94C46.76 221.39 46.69 221.63 46.69 221.63C46.35 220.32 45.8 218.99 45.14 217.7C45.13 217.68 45.13 217.67 45.12 217.67C45.09 217.64 45.08 217.6 45.07 217.57C42.42 212.49 38.12 208.01 38.12 208.01L34.93 195.9C34.93 195.9 33.8 198.11 28.69 198.89C23.58 199.67 15.8 193.17 15.8 193.17L17.36 207.89Z'
          fill='#EBD0D0'
        />
        <path
          opacity='0.3'
          d='M32.95 273.76C35.66 276.97 38.76 278.92 42.29 278.96C53.25 279.08 58.56 270.38 65.54 250.21L65.3899 203.33C65.3899 203.33 57.1499 204.93 49.4199 212.51C48.1599 216.4 47.25 219.6 46.88 220.94C49.96 227.36 55.3399 242.12 49.1699 257.85C45.1599 268.05 38.62 272.2 32.95 273.76Z'
          fill='#414042'
        />
        <path
          d='M17.36 207.89C17.36 207.89 15.8 209.67 15.8 212.34C15.8 214.54 20.0001 258.47 32.9501 273.76C35.6601 276.97 38.7601 278.92 42.2901 278.96C53.2501 279.08 58.5601 270.38 65.5401 250.21C66.4101 247.7 67.31 245 68.24 242.12C75.02 221.3 83.36 177.87 83.36 177.87C83.36 177.87 68.57 181.3 59.8 190C55.97 193.8 51.97 204.59 49.42 212.51C48.16 216.4 47.2501 219.6 46.8801 220.94C46.7601 221.39 46.69 221.63 46.69 221.63C46.35 220.32 45.8 218.99 45.14 217.7C45.13 217.68 45.13 217.67 45.12 217.67C45.09 217.64 45.08 217.6 45.07 217.57C42.42 212.49 38.12 208.01 38.12 208.01L34.93 195.9C34.93 195.9 33.8 198.11 28.69 198.89C23.58 199.67 15.8 193.17 15.8 193.17L17.36 207.89Z'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M52.2201 235.94C53.0101 240.06 53.0401 244.52 51.1301 248.35'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M46.6899 221.63C48.8499 225.28 50.5399 229.31 51.6399 233.41'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M53.67 226.81C53.67 226.81 52.13 222.57 47.96 221.62'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M32.4001 211.31C33.4001 210.77 34.3401 210.1 35.1901 209.35'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.3601 207.89C20.6201 211.38 25.7101 213.66 30.4601 212.14'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M45.0601 217.57C45.0601 217.57 45.0801 217.6 45.1201 217.66C45.1001 217.63 45.0801 217.59 45.0601 217.57C45.0601 217.56 45.0601 217.57 45.0601 217.57Z'
          fill='#EBD0D0'
        />
        <path
          d='M45.0601 217.57C45.0601 217.57 45.0801 217.6 45.1201 217.66C45.1001 217.63 45.0801 217.59 45.0601 217.57ZM45.0601 217.57C45.0601 217.56 45.0601 217.57 45.0601 217.57Z'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M141.64 307.13C141.64 307.13 143.5 292.41 144.64 287.61C149.76 265.97 167.79 215.84 164.88 201.01C161.89 185.76 143.61 172.43 114.12 170.96C86.7601 169.59 64.2101 179.29 58.8101 190.57C53.6601 201.33 53.8701 275.59 55.1801 309.1C55.1801 309.1 111.55 309.1 130.33 309.1C136.27 309.09 141.64 307.13 141.64 307.13Z'
          fill='#EBD0D0'
        />
        <path
          opacity='0.3'
          d='M135.77 217.67C121.6 237.1 106.15 266.17 106.15 266.17L63.95 213.57C63.95 213.57 73.6199 280.38 78.8899 287.61C84.16 294.84 83.93 298.16 83.93 298.16C83.93 298.16 97.3099 312.57 107.34 310.61C115.85 308.95 146.46 274.8 156 235.2L135.77 217.67Z'
          fill='#414042'
        />
        <path
          d='M141.64 307.13C141.64 307.13 143.5 292.41 144.64 287.61C149.76 265.97 167.79 215.84 164.88 201.01C161.89 185.76 143.61 172.43 114.12 170.96C86.7601 169.59 64.2101 179.29 58.8101 190.57C53.6601 201.33 53.8701 275.59 55.1801 309.1C55.1801 309.1 111.55 309.1 130.33 309.1C136.27 309.09 141.64 307.13 141.64 307.13Z'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M126.03 173.92C126.03 173.92 111.32 180.23 95.3999 175C95.3999 175 97.4499 170.02 97.4599 165.91C97.4699 163.85 96.9499 162 95.3999 161.12C95.3999 161.12 96.1199 160.33 97.3399 159.08C97.3499 159.08 97.3499 159.08 97.3499 159.08C103.38 152.94 121.69 135.68 127.65 145.97C127.65 145.97 121.41 146.56 126.03 173.92Z'
          fill='white'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M109.4 154.86C109.4 154.86 106.98 163.73 97.4599 165.91C97.4699 163.85 96.9499 162 95.3999 161.12C95.3999 161.12 96.1199 160.33 97.3399 159.08C97.3499 159.08 97.3499 159.08 97.3499 159.08C103.18 156.79 109.4 154.86 109.4 154.86Z'
          fill='#414042'
        />
        <path
          d='M131.8 131.49C138.12 106.31 124.51 97.7 115.94 94.4C102.2 89.11 84.87 95.13 80.81 109.11C76.75 123.1 80.41 126.28 80.22 132C80.09 136.01 77.43 137.99 80.36 148.06C82.3 154.7 85.89 162.33 90.08 162.57C101.43 163.23 112.17 156.77 111.57 153.73'
          fill='white'
        />
        <path
          d='M131.8 131.49C138.12 106.31 124.51 97.7 115.94 94.4C102.2 89.11 84.87 95.13 80.81 109.11C76.75 123.1 80.41 126.28 80.22 132C80.09 136.01 77.43 137.99 80.36 148.06C82.3 154.7 85.89 162.33 90.08 162.57C101.43 163.23 112.17 156.77 111.57 153.73'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M96.1401 119.15C96.1401 119.15 101.22 117.39 103.93 122.93'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78.8801 118.33C78.8801 118.33 82.6601 115.12 86.4401 119.66'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M98.4099 131.93C99.1058 131.93 99.6699 130.981 99.6699 129.81C99.6699 128.639 99.1058 127.69 98.4099 127.69C97.714 127.69 97.1499 128.639 97.1499 129.81C97.1499 130.981 97.714 131.93 98.4099 131.93Z'
          fill='#414042'
        />
        <path
          d='M82.6599 128.65C82.6599 129.82 83.2199 130.77 83.9199 130.77C84.6199 130.77 85.1799 129.82 85.1799 128.65C85.1799 127.48 84.6199 126.53 83.9199 126.53C83.2199 126.53 82.6599 127.48 82.6599 128.65Z'
          fill='#414042'
        />
        <path
          d='M86.4399 123.8C86.4399 123.8 87.2199 129.63 86.8699 130.77C86.5199 131.91 82.5799 135.47 82.4199 137.05C82.2599 138.63 85.7199 140.23 85.7199 140.23'
          fill='white'
        />
        <path
          d='M86.4399 123.8C86.4399 123.8 87.2199 129.63 86.8699 130.77C86.5199 131.91 82.5799 135.47 82.4199 137.05C82.2599 138.63 85.7199 140.23 85.7199 140.23'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.9199 143.58C83.9199 143.58 91.7999 145.56 98.4199 143.58C98.4199 143.58 95.2999 153.35 89.1299 152.35C85.0499 151.69 86.0899 145.29 86.0899 145.29L83.9199 143.58Z'
          fill='#414042'
        />
        <path
          d='M105.63 105.04C105.63 105.04 102.37 102.8 97.84 103.85C93.31 104.91 92.6 114.43 92.6 114.43C92.6 114.43 90.18 112.29 89.75 109.65L88.33 113.74L84.55 110.18L83.29 114.43L81.79 111.36L78.88 117.02C78.88 117.02 74.51 111.39 75.19 107.04C77.04 95.1702 86.45 85.4202 106.15 86.4702C116.07 87.0002 119.77 93.7902 119.77 93.7902C119.77 93.7902 129.47 92.4802 133.77 97.5102C148.42 114.64 139.23 133.24 125.32 150.87L120.55 134.07L115.93 133.54C115.93 133.54 116.1 131 115.96 128.36C115.82 125.72 114.25 121.86 114.25 121.86L112.54 122.63L113.25 119.41L109.35 121.41C109.35 121.41 111 108.18 109.35 105.94C107.7 103.7 105.63 105.04 105.63 105.04Z'
          fill='#414042'
        />
        <path
          d='M117.65 135.89C117.65 135.89 122.27 127.29 127.09 129.39C131.92 131.49 128.56 141.77 124.36 145.13C120.16 148.49 117.65 145.13 117.65 145.13'
          fill='white'
        />
        <path
          d='M117.65 135.89C117.65 135.89 122.27 127.29 127.09 129.39C131.92 131.49 128.56 141.77 124.36 145.13C120.16 148.49 117.65 145.13 117.65 145.13'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <g opacity='0.2'>
          <path
            d='M109.82 101.5C109.82 101.5 108.1 96.6805 102.45 96.3105C96.7999 95.9405 92.7399 102.06 92.6099 106.33'
            stroke='white'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M113.64 101.31C113.64 101.31 117.51 98.3402 119.76 101.31C123.56 106.33 120.41 118.44 120.41 118.44'
            stroke='white'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M118.91 97.7702C118.91 97.7702 122.94 95.3002 126.39 100.07C129.84 104.84 129.47 116.44 129.47 116.44'
            stroke='white'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M130.32 97.1304C130.32 97.1304 137.29 100.07 137.69 114.12'
            stroke='white'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M114.11 97.7706C114.11 97.7706 110.19 91.1106 98.57 93.3406C86.96 95.5706 85.21 107.441 85.21 107.441'
            stroke='white'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M117 95.7503C117 95.7503 112.32 89.2603 98.1099 90.5603C83.8999 91.8603 78.1799 104.29 79.6199 110.22'
            stroke='white'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <path
          d='M0.5 179C0.5 179 17.41 165.99 24.78 164.67C32.15 163.35 57.51 168.89 57.51 168.89V198.92C57.51 198.92 41.64 200.66 34.52 205.87C27.4 211.08 2.87 211.92 2.87 211.92L0.5 179Z'
          fill='#414042'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M16.3701 169.69C16.3701 169.69 16.7601 165.87 17.3601 163.76C17.9601 161.65 22.0501 161.88 22.9701 162.62C23.8901 163.36 24.7801 164.66 24.7801 164.66C24.7801 164.66 23.3601 165.07 22.9701 165.21C22.5701 165.34 22.1701 172.99 16.3701 169.69Z'
          fill='white'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M37.9634 123.836L21.1138 172.68L45.304 181.025L62.1536 132.181L37.9634 123.836Z' fill='#EBD0D0' />
        <path
          opacity='0.5'
          d='M21.1101 172.68L45.3001 181.02L62.1501 132.18L37.9601 123.84L21.1101 172.68ZM59.6101 133.42L44.0701 178.48L23.6601 171.44L39.2001 126.38L59.6101 133.42Z'
          fill='#414042'
        />
        <path
          opacity='0.5'
          d='M41.5602 130.2L39.4802 136.23C39.2502 136.91 38.5102 137.26 37.8402 137.03C37.1602 136.8 36.8102 136.06 37.0402 135.39L39.1202 129.36C39.3502 128.68 40.0902 128.33 40.7602 128.56C41.4302 128.78 41.7902 129.52 41.5602 130.2Z'
          fill='#414042'
        />
        <path
          opacity='0.5'
          d='M46.2401 169.47L44.1601 175.5C43.9301 176.18 43.1901 176.53 42.5201 176.3C41.8401 176.07 41.4901 175.33 41.7201 174.66L43.8001 168.63C44.0301 167.95 44.7701 167.6 45.4401 167.83C46.1101 168.06 46.4701 168.8 46.2401 169.47Z'
          fill='#414042'
        />
        <path
          opacity='0.5'
          d='M34.0602 149.82C32.6202 154 34.8402 158.57 39.0202 160.01C43.2002 161.45 47.7702 159.23 49.2102 155.05C50.6502 150.87 48.4302 146.3 44.2502 144.86C40.0702 143.42 35.5002 145.63 34.0602 149.82ZM45.8402 153.29L46.4402 153.5L46.0802 154.56L45.4802 154.35C44.9702 155.21 44.1002 155.43 42.9802 155.05L42.4202 154.86L42.8402 153.64L43.4802 153.86C44.0702 154.06 44.3702 153.88 44.5102 153.47C44.6502 153.06 44.5302 152.73 43.9402 152.53C43.3502 152.33 42.8502 152.42 41.8702 153.05C40.6702 153.82 39.8902 153.95 38.9502 153.63C37.8502 153.25 37.3002 152.55 37.4102 151.58L36.8002 151.37L37.1602 150.31L37.7702 150.52C38.2702 149.67 39.1402 149.44 40.2502 149.83L40.5102 149.92L40.0902 151.14L39.7502 151.02C39.1602 150.82 38.8602 150.98 38.7202 151.39C38.5802 151.8 38.7202 152.11 39.3102 152.31C39.9002 152.51 40.3902 152.42 41.3802 151.79C42.5902 151.03 43.3602 150.89 44.3002 151.21C45.4202 151.58 45.9702 152.31 45.8402 153.29Z'
          fill='#414042'
        />
        <path
          d='M37.9634 123.836L21.1138 172.68L45.304 181.025L62.1536 132.181L37.9634 123.836Z'
          stroke='#414042'
          strokeWidth='0.999967'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M39.5792 95.9959L26.3052 46.062L1.575 52.6361L14.849 102.57L39.5792 95.9959Z' fill='#EBD0D0' />
        <path
          opacity='0.5'
          d='M26.3001 46.0601L1.58008 52.6301L14.8501 102.56L39.5801 95.9901L26.3001 46.0601ZM16.2701 100.12L4.02008 54.0501L24.8801 48.5101L37.1201 94.5801L16.2701 100.12Z'
          fill='#414042'
        />
        <path
          opacity='0.5'
          d='M33.04 92.7201L31.4 86.5501C31.22 85.8601 31.63 85.1501 32.32 84.9701C33.01 84.7901 33.72 85.2001 33.9 85.8901L35.54 92.0601C35.72 92.7501 35.31 93.4601 34.62 93.6401C33.94 93.8201 33.23 93.4101 33.04 92.7201Z'
          fill='#414042'
        />
        <path
          opacity='0.5'
          d='M7.23998 62.7401L5.59998 56.5701C5.41998 55.8801 5.82998 55.1701 6.51998 54.9901C7.20998 54.8101 7.91998 55.2201 8.09998 55.9101L9.73998 62.0801C9.91998 62.7701 9.50998 63.4801 8.81998 63.6601C8.13998 63.8401 7.42998 63.4301 7.23998 62.7401Z'
          fill='#414042'
        />
        <path
          opacity='0.5'
          d='M28.3201 72.25C27.1801 67.97 22.7901 65.43 18.5201 66.56C14.2501 67.69 11.7001 72.09 12.8301 76.37C13.9601 80.65 18.3601 83.19 22.6301 82.06C26.9101 80.92 29.4601 76.53 28.3201 72.25ZM16.6001 75.95L15.9901 76.11L15.7001 75.03L16.3101 74.87C16.2501 73.87 16.8501 73.2 17.9901 72.9L18.5701 72.75L18.9001 74L18.2401 74.18C17.6401 74.34 17.4901 74.66 17.6101 75.08C17.7201 75.5 18.0101 75.71 18.6101 75.55C19.2101 75.39 19.5801 75.04 20.0401 73.96C20.6001 72.66 21.1801 72.11 22.1401 71.85C23.2701 71.55 24.1101 71.83 24.5601 72.7L25.1801 72.53L25.4701 73.61L24.8501 73.78C24.9101 74.77 24.3201 75.44 23.1801 75.74L22.9201 75.81L22.5901 74.56L22.9401 74.47C23.5401 74.31 23.7001 74.01 23.5901 73.59C23.4801 73.17 23.1901 72.99 22.5901 73.15C21.9901 73.31 21.6401 73.66 21.1601 74.74C20.5801 76.05 20.0201 76.59 19.0601 76.85C17.9001 77.13 17.0401 76.83 16.6001 75.95Z'
          fill='#414042'
        />
        <path
          d='M39.5792 95.9959L26.3052 46.062L1.575 52.6361L14.849 102.57L39.5792 95.9959Z'
          stroke='#414042'
          strokeWidth='0.999963'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0.5 179C0.5 179 25.03 170.73 31.62 169.55C38.21 168.36 57.51 168.89 57.51 168.89V198.92C57.51 198.92 37.37 205.03 34.26 207.48C31.15 209.93 2.88 211.92 2.88 211.92L0.5 179Z'
          fill='#949191'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M33.97 182.57C32.68 189.52 33.61 198.84 29 204.48'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M36.1099 172.05C35.6099 174.32 35.1399 176.59 34.6899 178.87'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M60.52 72.41C60.52 75.44 58.06 77.9 55.03 77.9C52 77.9 49.54 75.44 49.54 72.41C49.54 69.38 52 66.92 55.03 66.92C58.06 66.91 60.52 69.37 60.52 72.41Z'
          fill='#949191'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          opacity='0.5'
          d='M58.0902 75.8301C59.9802 74.1401 60.1402 71.23 58.4402 69.34C56.7502 67.45 53.8402 67.29 51.9502 68.99C50.0602 70.68 49.9002 73.5901 51.6002 75.4801C53.3002 77.3701 56.2002 77.5201 58.0902 75.8301ZM53.1602 70.8001L52.9202 70.53L53.4002 70.1L53.6402 70.3701C54.1302 70.0801 54.6402 70.2101 55.0902 70.7201L55.3202 70.9701L54.7702 71.4601L54.5102 71.17C54.2702 70.91 54.0702 70.9101 53.8902 71.0801C53.7002 71.2501 53.6702 71.4501 53.9102 71.7101C54.1502 71.97 54.4202 72.07 55.0902 72.03C55.9002 71.98 56.3302 72.1301 56.7102 72.5501C57.1602 73.0501 57.2302 73.5501 56.9102 74.0101L57.1602 74.2901L56.6802 74.7201L56.4302 74.4401C55.9402 74.7301 55.4402 74.6 54.9902 74.09L54.8902 73.9701L55.4402 73.4801L55.5802 73.63C55.8202 73.89 56.0102 73.9001 56.2002 73.7301C56.3902 73.5601 56.4002 73.37 56.1602 73.1C55.9202 72.84 55.6502 72.74 54.9802 72.78C54.1602 72.83 53.7402 72.6801 53.3602 72.2601C52.8902 71.7701 52.8202 71.2601 53.1602 70.8001Z'
          fill='#414042'
        />
        <path
          d='M34.5 28.99C34.5 32.02 32.04 34.48 29.01 34.48C25.98 34.48 23.52 32.02 23.52 28.99C23.52 25.96 25.97 23.5 29 23.5C32.04 23.5 34.5 25.96 34.5 28.99Z'
          fill='#949191'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          opacity='0.5'
          d='M32.0701 32.4199C33.9601 30.7299 34.1201 27.8199 32.4201 25.9299C30.7301 24.0399 27.8201 23.8799 25.9301 25.5799C24.0401 27.2799 23.8801 30.1799 25.5801 32.0699C27.2801 33.9499 30.1801 34.1099 32.0701 32.4199ZM27.1301 27.3899L26.8901 27.1199L27.3701 26.6899L27.6101 26.9599C28.1001 26.6699 28.6101 26.7999 29.0601 27.3099L29.2901 27.5599L28.7401 28.0499L28.4801 27.7599C28.2401 27.4999 28.0401 27.4999 27.8601 27.6699C27.6701 27.8399 27.6401 28.0399 27.8801 28.2999C28.1201 28.5599 28.3901 28.6599 29.0601 28.6199C29.8701 28.5699 30.3001 28.7199 30.6801 29.1399C31.1301 29.6399 31.2001 30.1399 30.8801 30.5999L31.1301 30.8799L30.6501 31.3099L30.4001 31.0299C29.9101 31.3199 29.4101 31.1899 28.9601 30.6799L28.8601 30.5599L29.4101 30.0699L29.5501 30.2199C29.7901 30.4799 29.9801 30.4899 30.1701 30.3199C30.3601 30.1499 30.3701 29.9599 30.1301 29.6899C29.8901 29.4299 29.6201 29.3299 28.9501 29.3699C28.1301 29.4199 27.7101 29.2699 27.3301 28.8499C26.8701 28.3599 26.8001 27.8399 27.1301 27.3899Z'
          fill='#414042'
        />
        <path
          d='M8.99 135.15C6.04 134.43 4.24 131.45 4.97 128.5C5.69 125.55 8.67 123.75 11.62 124.48C14.57 125.2 16.37 128.18 15.64 131.13C14.92 134.07 11.94 135.87 8.99 135.15Z'
          fill='#949191'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          opacity='0.5'
          d='M6.24999 131.98C7.43999 134.22 10.23 135.07 12.47 133.87C14.71 132.68 15.56 129.89 14.36 127.65C13.17 125.41 10.38 124.56 8.13999 125.76C5.89999 126.95 5.04999 129.74 6.24999 131.98ZM12.31 128.38L12.63 128.21L12.93 128.77L12.61 128.94C12.77 129.49 12.53 129.94 11.93 130.26L11.63 130.42L11.28 129.77L11.63 129.59C11.94 129.42 11.98 129.22 11.87 129C11.75 128.78 11.57 128.7 11.25 128.87C10.94 129.04 10.78 129.28 10.66 129.94C10.52 130.74 10.27 131.12 9.76999 131.39C9.17999 131.7 8.66999 131.66 8.30999 131.23L7.97999 131.4L7.67999 130.84L7.99999 130.68C7.83999 130.14 8.07999 129.69 8.67999 129.37L8.81999 129.3L9.16999 129.95L8.98999 130.05C8.67999 130.22 8.61999 130.41 8.73999 130.63C8.85999 130.85 9.03999 130.91 9.35999 130.74C9.66999 130.57 9.82999 130.33 9.94999 129.67C10.1 128.86 10.34 128.49 10.84 128.22C11.43 127.89 11.95 127.94 12.31 128.38Z'
          fill='#414042'
        />
        <path
          d='M74.87 183.98C73.47 181.44 69.47 182.67 64.8 190C60.13 197.33 58.23 205.89 59.36 209C60.49 212.11 75.58 215.89 75.58 215.89L78.69 208L74.87 183.98Z'
          fill='#EBD0D0'
        />
        <path
          opacity='0.5'
          d='M74.87 183.98C73.47 181.44 69.47 182.67 64.8 190C60.13 197.33 58.23 205.89 59.36 209C60.49 212.11 75.58 215.89 75.58 215.89L78.69 208L74.87 183.98Z'
          fill='#414042'
        />
        <path
          d='M74.87 183.98C73.47 181.44 69.47 182.67 64.8 190C60.13 197.33 58.23 205.89 59.36 209C60.49 212.11 75.58 215.89 75.58 215.89L78.69 208L74.87 183.98Z'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.2501 199.33L72.5901 180.38C72.5901 180.38 66.9501 172.31 65.8401 169.23C64.7301 166.15 52.3201 163.76 49.5201 163.76C46.7201 163.76 43.7501 168.77 43.7501 168.77H49.5801C49.5801 168.77 51.7501 171.06 52.0301 172.42C52.3001 173.79 44.3201 180.59 43.1801 181.24C42.0401 181.89 39.0301 182.89 35.8601 185.24C32.6901 187.59 25.5401 189.42 24.2101 191.53C22.8801 193.64 23.8201 194.03 24.7701 194.52C25.7301 195.01 27.3201 195.12 27.3201 195.12C27.3201 195.12 26.7501 197.51 27.3201 198.77C27.8901 200.02 31.2101 199.91 31.2101 199.91C31.2101 199.91 31.3701 201.65 32.9901 201.92C34.6101 202.19 35.8501 202.68 35.8501 202.68C35.8501 202.68 42.7101 203.84 45.7901 203.19C48.8701 202.54 58.2101 197.56 60.4901 197.77C62.7701 197.98 69.0901 212.63 69.0901 212.63C69.0901 212.63 79.8801 210.79 81.2501 199.33Z'
          fill='white'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M27.3301 195.11C27.3301 195.11 36.2901 193.17 36.8901 192.49C37.4801 191.81 43.7501 189.86 43.7501 189.86'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M31.2202 199.9C31.2202 199.9 36.2502 198.87 38.0002 198.65C39.7502 198.42 46.9302 195.12 46.9302 195.12'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M35.8601 202.67C35.8601 202.67 42.9501 202.07 44.4301 201.38C45.9101 200.7 50.2401 199.33 50.2401 199.33'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.63 190.98C130.11 197.91 123.75 214.66 123.75 214.66L107.33 245.21C107.33 245.21 93.5201 211.21 89.6101 210.77L74.8701 183.97C74.8701 183.97 76.6101 199.67 70.4701 206.34C64.3301 213.01 59.3501 209 59.3501 209L63.9401 221.67L65.0801 227.69C65.0801 227.69 84.4501 285.81 90.7901 289.41C97.1201 293.01 111.53 294.62 123.75 285.67C135.97 276.72 156.87 242.89 158.85 231.82'
          fill='#EBD0D0'
        />
        <path
          d='M140.63 190.98C130.11 197.91 123.75 214.66 123.75 214.66L107.33 245.21C107.33 245.21 93.5201 211.21 89.6101 210.77L74.8701 183.97C74.8701 183.97 76.6101 199.67 70.4701 206.34C64.3301 213.01 59.3501 209 59.3501 209L63.9401 221.67L65.0801 227.69C65.0801 227.69 84.4501 285.81 90.7901 289.41C97.1201 293.01 111.53 294.62 123.75 285.67C135.97 276.72 156.87 242.89 158.85 231.82'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.6699 214.8C84.1999 214 84.6899 213.18 85.1399 212.34'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M71.26 222.74C75.48 222.29 79.4 220.09 82.13 216.86'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M63.9399 221.66C65.5799 222.35 67.3299 222.74 69.0999 222.81'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M111.63 258.68C111.7 260.08 111.61 261.48 111.29 262.85'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M107.33 245.21C109.26 248.57 110.71 252.34 111.36 256.16'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M116.41 250.68C116.41 250.68 113.42 246.94 110.02 245.21'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.37 206.35C142.37 206.35 133.13 205.11 125.8 213.57'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.14 232.19C150.37 232.44 148.56 232.17 146.96 231.35'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M163.24 219.8C161.79 226.81 157.87 230.37 154.46 231.61'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M119.68 179.94C119.68 179.94 104.34 183.51 95.79 177.79'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M64.6599 273.8C64.6599 273.8 73.0199 297.4 92.3499 301.4'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M120.54 306.51C119.54 307.26 118.5 307.95 117.44 308.6'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13.8699 20.7002V41.4502'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13.8699 8.25V16.55'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13.8699 0.5V4.94'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.94995 39.0298L13.87 43.8798L17.79 39.0298'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M24.24 124.59V145.34'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M24.24 112.14V120.44'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M24.24 104.39V108.83'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M20.3201 142.91L24.2401 147.76L28.1601 142.91'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M44.74 62.1504V82.9004'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M44.74 49.71V58'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M44.74 41.9502V46.3902'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M40.8201 80.48L44.7401 85.33L48.6501 80.48'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_279_17771'>
          <rect width='165.7' height='310.79' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ManWithWallet
