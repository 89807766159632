const AmpaLogoClean = () => {
  const scale = 0.7

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={216 * scale}
      height={143 * scale}
      fill='none'
      viewBox='0 0 216 143'
    >
      <path
        fill='#928F90'
        d='M192.692 138.475c-.53-.604-52.548-57.095-84.681-57.095-19.204.227-41.206 11.389-56.252 20.59-16.407 10.107-28.353 20.214-28.429 20.289l-4.007 3.394 2.873-4.375c.53-.754 52.548-80.55 66.989-105.59C95.233 5.28 101.509 0 108.01 0c6.502 0 13.004 5.28 19.28 15.688 8.846 14.632 66.005 120.901 66.61 121.957l2.873 5.355-4.082-4.525z'
      ></path>
      <path
        fill='#D01417'
        d='M100.137 116.527c59.125 9.126 110.765-5.43 115.15-32.431 1.134-7.09-1.058-14.406-6.427-21.797-14.667-20.138-51.186-37.259-92.997-43.745-12.022-1.81-23.665-2.715-34.704-2.715-43.474 0-76.968 13.651-80.446 35.147-1.135 7.09 1.058 14.405 6.426 21.796 14.668 20.138 51.187 37.334 92.998 43.745zM9.786 42.387c.756-4.751 3.704-9.05 8.77-12.746 16.029-11.615 50.582-15.386 88.083-9.428 24.27 3.847 46.423 11.163 62.527 20.666 16.256 9.654 24.422 20.364 22.834 30.32-.756 4.751-3.705 9.05-8.771 12.746-11.038 7.995-30.696 12.294-54.059 12.294-10.736 0-22.229-.905-34.1-2.791C70.877 89.602 48.725 82.21 32.62 72.707 16.363 63.053 8.198 52.343 9.786 42.387z'
      ></path>
      <mask
        id='mask0_1866_17800'
        style={{ maskType: 'alpha' }}
        width='178'
        height='143'
        x='19'
        y='0'
        maskUnits='userSpaceOnUse'
      >
        <path
          fill='#928F90'
          d='M192.692 138.475c-.53-.604-52.548-57.095-84.681-57.095-19.204.227-41.206 11.389-56.252 20.59-16.407 10.107-28.353 20.214-28.429 20.289l-4.007 3.394 2.873-4.375c.53-.754 52.548-80.55 66.989-105.59C95.233 5.28 101.509 0 108.01 0c6.502 0 13.004 5.28 19.28 15.688 8.846 14.632 66.005 120.901 66.61 121.957l2.873 5.355-4.082-4.525z'
        ></path>
      </mask>
      <g mask='url(#mask0_1866_17800)'>
        <path fill='#928F90' d='M-50.743 -36.734H138.174V124.63299999999998H-50.743z'></path>
      </g>
      <mask
        id='mask1_1866_17800'
        style={{ maskType: 'alpha' }}
        width='178'
        height='143'
        x='19'
        y='0'
        maskUnits='userSpaceOnUse'
      >
        <path
          fill='#928F90'
          d='M192.692 138.475c-.53-.604-52.548-57.095-84.681-57.095-19.204.227-41.206 11.389-56.252 20.59-16.407 10.107-28.353 20.214-28.429 20.289l-4.007 3.394 2.873-4.375c.53-.754 52.548-80.55 66.989-105.59C95.233 5.28 101.509 0 108.01 0c6.502 0 13.004 5.28 19.28 15.688 8.846 14.632 66.005 120.901 66.61 121.957l2.873 5.355-4.082-4.525z'
        ></path>
      </mask>
      <g mask='url(#mask1_1866_17800)'>
        <path fill='#928F90' d='M31.908 -52.477H170.97199999999998V57.725H31.908z'></path>
      </g>
    </svg>
  )
}

export default AmpaLogoClean
