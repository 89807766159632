import React, { Fragment, useEffect, useState } from 'react'
import i18next from 'i18next'
import { initReactI18next, useTranslation } from 'react-i18next'
import { Button, Popover, List, ListSubheader, ListItemText } from '@mui/material'
import { ArrowDropDownRounded } from '@mui/icons-material'
import translationEN from '../translations/en/translation.json'
import translationHE from '../translations/he/translation.json'
import { useRtl } from '../contexts/RtlContext'

i18next.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationEN,
    },
    he: {
      translation: translationHE,
    },
  },
  fallbackLng: ['en'],
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  detection: {
    checkWhitelist: true,
  },
})

function SelectLanguage({ color }: { color?: string }): JSX.Element {
  const { t } = useTranslation()
  const { setIsRtl } = useRtl()
  const [menuTarget, setMenuTarget] = useState<HTMLButtonElement | null>(null)

  const changeLanguage = async (item: string): Promise<void> => {
    localStorage.setItem('selected_language', item)

    if (item === 'he') {
      setIsRtl(true)
      document.body.className = 'forceRtl'
    } else {
      setIsRtl(false)
      document.body.className = ''
    }

    await i18next.changeLanguage(item)
    setMenuTarget(null)
  }

  useEffect(() => {
    changeLanguage(localStorage.getItem('selected_language') ?? 'he')
  }, [])

  return (
    <Fragment>
      <Button onClick={({ currentTarget }) => setMenuTarget(currentTarget)} style={{ color: color ?? 'default' }}>
        {i18next.language}
        <ArrowDropDownRounded fontSize='medium' />
      </Button>

      <Popover
        open={!!menuTarget}
        anchorEl={menuTarget}
        onClose={() => setMenuTarget(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: -30,
        }}
      >
        <List style={{ minWidth: '100px' }}>
          <ListSubheader style={{ textAlign: 'center' }}>{t('Select Language')}</ListSubheader>
          <ListItemText onClick={() => changeLanguage('en')} style={{ textAlign: 'center', cursor: 'pointer' }}>
            English
          </ListItemText>
          <ListItemText onClick={() => changeLanguage('he')} style={{ textAlign: 'center', cursor: 'pointer' }}>
            עברית
          </ListItemText>
        </List>
      </Popover>
    </Fragment>
  )
}

export default SelectLanguage
