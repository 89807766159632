import React from 'react'

const Car = ({ style = {} }: { style?: React.CSSProperties }) => {
  return (
    <svg
      width='456'
      height='270'
      viewBox='0 0 456 270'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
    >
      <g clipPath='url(#clip0_61_5455)'>
        <path
          d='M247.184 53.9772H223.099C221.371 53.9772 219.979 52.5802 219.979 50.8632V44.9162C219.979 43.1914 221.379 41.8022 223.099 41.8022H247.184C248.913 41.8022 250.305 43.1992 250.305 44.9162V50.8632C250.305 52.5802 248.905 53.9772 247.184 53.9772Z'
          fill='#414042'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M235.509 35.8867H232.553V45.1194H235.509V35.8867Z'
          fill='#414042'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M339.412 102.068L328.8 93.8581L314.013 39.3362L289.412 36.3081L300.054 75.7674H175.633L186.276 36.3081L161.674 39.3362L146.676 94.6542L135.47 102.068H339.412ZM292.446 39.3362L310.987 40.6786L322.842 89.2379L305.427 75.7596H304.488L292.446 39.3362ZM164.708 40.6786L183.249 39.3362L170.268 78.5926L170.714 78.7409L152.47 90.8144L164.708 40.6786Z'
          fill='#414042'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M212.973 104.558L211.143 78.5927C210.713 72.4272 205.567 67.6431 199.374 67.6431H175.461C169.267 67.6431 164.122 72.4272 163.692 78.5927L161.862 104.558H212.973Z'
          fill='#949191'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M307.249 104.558L305.419 78.5927C304.989 72.4272 299.843 67.6431 293.65 67.6431H269.737C263.544 67.6431 258.398 72.4272 257.968 78.5927L256.138 104.558H307.249Z'
          fill='#949191'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M181.373 48.4594C181.373 48.4594 176.79 45.1893 173.951 46.719C171.113 48.2408 165.217 64.8019 164.341 68.509C163.465 72.2161 168.923 75.2676 172.638 76.3525C176.352 77.4451 183.773 75.0413 185.517 73.3009C187.269 71.5605 181.373 48.4594 181.373 48.4594Z'
          fill='#414042'
          stroke='#414042'
          strokeMiterlimit='10'
        />
        <path
          d='M212.347 105.252C212.065 104.371 211.901 103.824 211.901 103.824C211.901 103.824 208.719 86.8184 207.929 85.4604C207.131 84.1024 197.255 79.6773 197.255 79.6773L180.106 78.772C180.106 78.772 167.273 85.0077 167.156 85.8038C167.093 86.2252 164.638 96.09 162.347 105.252H212.347Z'
          fill='#F2F2F2'
          stroke='#414042'
          strokeMiterlimit='10'
        />
        <path
          d='M172.106 92.5234C173.115 95.4345 173.795 97.4793 174.21 100.109'
          stroke='#414042'
          strokeMiterlimit='10'
        />
        <path
          d='M205.747 93.7017C205.2 95.5747 203.816 98.0565 204.23 100.054'
          stroke='#414042'
          strokeMiterlimit='10'
        />
        <path
          d='M189.646 42.6216C189.646 42.6216 179.441 45.4546 176.602 50.9255C173.764 56.3964 173.576 57.6217 175.187 60.2674C176.79 62.9053 180.763 67.627 184.454 69.5157C188.137 71.4043 199.859 68.665 199.859 68.665C199.859 68.665 203.448 67.0651 204.582 64.2321C205.716 61.399 208.265 57.4422 206.568 53.1966C204.863 48.951 197.302 43.1054 194.94 42.442C192.571 41.7787 189.646 42.6216 189.646 42.6216Z'
          fill='#414042'
          stroke='#414042'
          strokeMiterlimit='10'
        />
        <path
          d='M197.075 49.7861C197.075 49.7861 191.984 52.9157 189.372 54.7342C186.76 56.5604 183.625 57.5984 183.625 57.5984C183.625 57.5984 181.279 58.6442 181.795 61.3757C182.319 64.1151 183.304 73.5663 183.304 73.5663L181.529 77.4061L189.099 85.7412L195.104 77.9212L195.753 73.8785C195.753 73.8785 201.235 70.4913 201.368 69.7109C201.501 68.9304 203.065 57.4657 202.932 56.295C202.815 55.1244 197.075 49.7861 197.075 49.7861Z'
          fill='white'
          stroke='#414042'
          strokeMiterlimit='10'
        />
        <path
          d='M197.958 57.8169C197.958 57.8169 197.677 60.3611 197.677 60.7436C197.677 61.1182 199.945 63.1005 199.092 63.8575C198.24 64.6146 196.543 65.0828 196.543 65.0828'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M196.825 67.4319C196.825 67.4319 195.878 68.4699 193.798 66.3003'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M195.769 73.8863C195.769 73.8863 193.611 73.66 192.766 73.0981C191.914 72.5283 189.458 70.6475 189.458 70.6475L194.275 78.9514L195.112 77.929L195.769 73.8863Z'
          fill='#414042'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M184.633 60.08C184.633 60.08 182.835 54.7027 180.755 57.723C178.675 60.7433 181.982 64.989 183.593 64.8953'
          fill='white'
        />
        <path
          d='M184.633 60.08C184.633 60.08 182.835 54.7027 180.755 57.723C178.675 60.7433 181.982 64.989 183.593 64.8953'
          stroke='#414042'
          strokeMiterlimit='10'
        />
        <path
          d='M193.282 60.923C193.727 60.923 194.088 60.4163 194.088 59.7913C194.088 59.1663 193.727 58.6597 193.282 58.6597C192.837 58.6597 192.477 59.1663 192.477 59.7913C192.477 60.4163 192.837 60.923 193.282 60.923Z'
          fill='#414042'
        />
        <path
          d='M200.375 60.923C200.82 60.923 201.18 60.4163 201.18 59.7913C201.18 59.1663 200.82 58.6597 200.375 58.6597C199.93 58.6597 199.569 59.1663 199.569 59.7913C199.569 60.4163 199.93 60.923 200.375 60.923Z'
          fill='#414042'
        />
        <path
          d='M191.155 57.3407C191.155 57.3407 192.86 55.6393 195.128 56.966'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.811 56.7789C198.811 56.7789 201.36 55.7409 201.931 57.7233'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M181.513 74.5107L188.129 82.9083L183.593 87.8095L178.487 78.6627L181.513 74.5107Z'
          fill='white'
          stroke='#414042'
          strokeMiterlimit='10'
        />
        <path d='M160.243 42.1455L191.038 104.23H182.757L157.944 44.042' fill='#414042' />
        <path
          d='M160.243 42.1455L191.038 104.23H182.757L157.944 44.042'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M188.129 82.9082L189.114 85.7412L186.807 84.6018L188.129 82.9082Z'
          fill='white'
          stroke='#414042'
          strokeMiterlimit='10'
        />
        <path
          d='M190.498 81.4957L193.704 87.8095L198.623 79.5134L195.315 76.8677L190.498 81.4957Z'
          fill='white'
          stroke='#414042'
          strokeMiterlimit='10'
        />
        <path
          d='M190.498 81.4956L189.114 85.7412L191.437 83.4701L190.498 81.4956Z'
          fill='white'
          stroke='#414042'
          strokeMiterlimit='10'
        />
        <path
          d='M323.545 110.177C324.085 108.983 326.814 102.755 325.837 101.108C320.269 91.7665 318.634 97.9554 316.648 93.2805C313.301 85.4293 310.478 79.9584 309.274 79.1389C306.952 77.5546 291.672 74.6279 291.672 74.6279L272.966 74.144C272.966 74.144 256.826 80.731 255.732 82.1982C255.262 82.8304 251.79 86.9589 249.374 92.7811C249.132 93.3742 243.548 97.8227 243.306 98.4159C240.67 104.792 243.118 109.209 243.736 110.177H323.545Z'
          fill='white'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M261.432 88.8242C261.62 95.2629 261.62 101.413 261.886 107.766'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M261.338 84.9531L261.385 86.8808'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M304.363 88.1997C303.988 93.1477 303.62 98.1035 303.245 103.052C303.128 104.628 303.01 106.212 302.885 107.789'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M304.637 84.5942L304.535 85.9054'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M278.049 95.2471C278.237 98.681 277.862 105.627 277.572 110.177H290.069C289.466 104.761 288.505 95.9963 288.309 94.0764C288.02 91.2512 284.211 83.158 284.211 83.158L286.651 79.9425L282.256 75.3613L279.426 79.7474L281.67 83.4545C281.662 83.4545 277.76 89.8854 278.049 95.2471Z'
          fill='#EAC9C9'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M275.414 66.9873L274.632 72.8328L282.937 77.414L290.358 72.9343L290.061 68.6418L275.414 66.9873Z'
          fill='white'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M274.632 72.8328L277.424 74.3703L282.139 72.4036L276.884 67.1512L275.414 66.9873L274.632 72.8328Z'
          fill='#414042'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M274.835 43.8626C274.835 43.8626 272.075 45.8293 271.88 48.6857C271.684 51.5421 273.162 64.7238 274.742 67.6739C276.321 70.624 278.19 72.9888 282.038 73.6755C285.885 74.3623 290.42 70.5303 290.913 68.7587C291.406 66.9871 293.282 57.8403 293.282 54.3908C293.282 50.949 293.673 47.0077 290.522 44.1591C287.371 41.3027 282.241 40.7174 279.778 41.209C277.299 41.6929 274.835 43.8626 274.835 43.8626Z'
          fill='white'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M290.506 44.1513C287.355 41.2949 282.225 40.7096 279.762 41.2012C277.299 41.6929 274.835 43.8547 274.835 43.8547C274.835 43.8547 272.075 45.8215 271.88 48.6779C271.786 50.0671 272.083 53.9069 272.599 57.8247C272.802 57.8559 273.06 57.7623 273.06 57.0443C273.06 55.8658 272.372 55.1712 273.256 54.1878C274.139 53.2045 275.524 51.8231 276.313 50.551C277.103 49.271 276.908 47.3043 276.908 47.3043C276.908 47.3043 278.683 48.7794 282.233 49.1774C285.783 49.5676 289.631 49.5676 290.217 49.6691C290.811 49.7705 290.022 50.3558 290.905 51.5421C291.789 52.7206 292.188 53.3137 292.282 53.9069C292.368 54.4142 292.821 57.2628 292.938 58.0432C293.134 56.5994 293.267 55.3039 293.267 54.4064C293.267 50.9412 293.666 47.0077 290.506 44.1513Z'
          fill='#414042'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M280.849 62.3593C280.849 62.3593 282.522 63.4441 283.609 63.2412C284.696 63.0461 285.775 62.2578 285.775 62.2578'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M285.877 54.9763C285.877 54.9763 288.145 53.3061 290.413 55.1714'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M281.146 55.1711C281.146 55.1711 278.487 53.3995 276.611 55.5614'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M279.66 57.0441C279.66 57.5358 279.418 57.926 279.121 57.926C278.824 57.926 278.581 57.528 278.581 57.0441C278.581 56.5524 278.824 56.1622 279.121 56.1622C279.418 56.1544 279.66 56.5524 279.66 57.0441Z'
          fill='#414042'
        />
        <path
          d='M287.895 57.0441C287.895 57.5358 287.652 57.926 287.355 57.926C287.058 57.926 286.815 57.528 286.815 57.0441C286.815 56.5524 287.058 56.1622 287.355 56.1622C287.652 56.1544 287.895 56.5524 287.895 57.0441Z'
          fill='#414042'
        />
        <path
          d='M279.074 65.6059C279.074 65.6059 282.921 67.276 286.761 65.5044'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M272.466 56.0607C272.466 56.0607 270.886 54.7808 270.3 57.0441C269.706 59.3074 273.357 62.2575 273.357 62.2575L272.466 56.0607Z'
          fill='white'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M293.173 56.4511C293.173 56.4511 294.057 54.976 294.846 56.8413C295.636 58.7144 292.086 62.8429 292.086 62.8429L293.173 56.4511Z'
          fill='white'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M283.367 64.0995C283.367 64.0995 278.675 63.4361 277.69 65.0126C276.837 66.3706 277.314 70.1635 278.096 71.9898C279.144 72.7936 280.411 73.3868 282.03 73.6755C283.938 74.0189 286.026 73.2385 287.691 72.1537C288.082 71.5996 288.637 70.2026 288.434 66.7842C288.27 64.0214 287.074 63.7483 283.367 64.0995ZM286.894 70.4913C286.894 70.4913 285.361 71.662 283.156 71.5996C282.358 71.5761 279.809 71.5293 278.925 70.6318C278.042 69.7343 277.33 66.355 278.214 65.2545C279.097 64.1541 283.312 64.6146 283.312 64.6146C286.643 64.3726 287.715 64.5599 287.863 66.4954C288.145 70.0543 286.894 70.4913 286.894 70.4913Z'
          fill='#414042'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M284.469 69.9841C284.469 70.3821 283.899 71.5918 283.195 71.5918C282.491 71.5918 281.92 70.3821 281.92 69.9841C281.92 69.5861 282.491 69.2661 283.195 69.2661C283.899 69.2661 284.469 69.5861 284.469 69.9841Z'
          fill='#414042'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M282.937 77.4139L275.805 84.0399L271.997 74.0034L274.437 72.0522L282.937 77.4139Z'
          fill='white'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M282.937 77.4138L288.692 84.9139L292.501 74.9788L290.358 72.9341L282.937 77.4138Z'
          fill='white'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M313.966 42.1455L268.415 104.23H276.704L316.265 44.042' fill='#414042' />
        <path
          d='M313.966 42.1455L268.415 104.23H276.704L316.265 44.042'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M240.796 110.177H252.299C253.401 108.897 254.465 107.437 254.754 107.086C255.247 106.477 256.592 104.277 255.732 101.233C254.879 98.1813 252.064 96.4722 252.064 96.4722L249.006 97.0809C249.006 97.0809 246.442 96.2302 244.487 97.0809C242.532 97.9316 239.967 100.866 238.981 104.277C238.199 107.008 239.451 109.037 240.796 110.177Z'
          fill='white'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M248.107 97.6587C245.965 100.585 244.463 104.417 246.723 107.703'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M265.561 93.452C265.561 93.452 267.586 92.9447 268.259 93.3661C268.931 93.7875 269.69 95.2158 270.026 95.9728C270.362 96.7298 269.854 97.401 268.759 97.5727C267.665 97.7444 266.234 96.3084 266.234 96.3084L265.561 93.452Z'
          fill='white'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M307.382 98.0174L305.747 95.918L288.833 106.516H276.704L259.9 96.9716L258.5 98.4856L266.765 110.177H297.904L307.382 98.0174Z'
          fill='#414042'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M257.671 110.177C258.414 96.9328 269.401 86.4202 282.859 86.4202C296.317 86.4202 307.296 96.9328 308.046 110.177H312.074C311.323 94.7163 298.537 82.4087 282.866 82.4087C267.188 82.4087 254.402 94.7163 253.659 110.177H257.671Z'
          fill='#414042'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M254.512 91.4305C254.512 91.4305 249.366 98.8369 249.202 101.444C249.03 104.05 250.211 106.743 252.744 107.164C255.27 107.586 255.692 105.986 255.692 105.986C255.692 105.986 261.174 107.164 261.424 107.164C261.675 107.164 262.269 106.914 262.433 106.321C262.605 105.736 263.02 104.979 263.02 104.979C263.02 104.979 263.778 104.472 264.286 103.551C264.795 102.622 264.959 102.208 264.959 102.208C264.959 102.208 265.89 100.858 266.054 100.273C266.226 99.6876 266.226 98.5871 266.226 98.5871C266.226 98.5871 267.07 97.4945 266.984 96.3161C266.898 95.1376 265.717 93.0382 263.278 91.688C260.838 90.3379 257.374 89.7525 256.193 89.9242C255.02 90.0881 254.512 91.4305 254.512 91.4305Z'
          fill='white'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M259.305 94.6147L266.234 98.5872'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M257.718 93.7095L258.609 94.2168'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M255.692 97.4087L264.967 102.201'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M256.959 102.294L263.027 104.979'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M254.848 101.366L256.052 101.896'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M313.927 110.177H325.43C326.775 109.037 328.026 107.008 327.244 104.277C326.267 100.858 323.702 97.9316 321.739 97.0809C319.784 96.2302 317.219 97.0809 317.219 97.0809L314.162 96.4722C314.162 96.4722 311.346 98.1813 310.494 101.233C309.642 104.284 310.987 106.477 311.471 107.086C311.761 107.437 312.824 108.897 313.927 110.177Z'
          fill='white'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M320.23 106.314C320.05 106.782 319.815 107.25 319.502 107.703'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M318.118 97.6587C319.69 99.8049 320.918 102.435 320.566 104.979'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M300.664 93.452C300.664 93.452 298.639 92.9447 297.967 93.3661C297.294 93.7875 296.536 95.2158 296.199 95.9728C295.863 96.7298 296.371 97.401 297.466 97.5727C298.561 97.7444 299.992 96.3084 299.992 96.3084L300.664 93.452Z'
          fill='white'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M311.714 91.4307C311.714 91.4307 316.859 98.8371 317.024 101.444C317.196 104.05 316.015 106.743 313.481 107.164C310.955 107.586 310.533 105.986 310.533 105.986C310.533 105.986 305.051 107.164 304.801 107.164C304.551 107.164 303.957 106.915 303.792 106.322C303.62 105.736 303.206 104.979 303.206 104.979C303.206 104.979 302.447 104.472 301.939 103.551C301.431 102.622 301.267 102.209 301.267 102.209C301.267 102.209 300.336 100.858 300.172 100.273C300 99.6878 300 98.5874 300 98.5874C300 98.5874 299.155 97.4948 299.241 96.3163C299.327 95.1378 300.508 93.0384 302.948 91.6882C305.395 90.3459 308.852 89.7527 310.033 89.9244C311.206 90.0883 311.714 91.4307 311.714 91.4307Z'
          fill='white'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M306.92 94.6147L299.992 98.5872'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M308.508 93.7095L307.616 94.2168'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M310.533 97.4087L301.259 102.201'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M309.266 102.294L303.198 104.979'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M311.378 101.366L310.166 101.896'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M335.291 161.452V210.745H142.211V161.452H105.911V233.495C105.911 239.083 110.446 243.617 116.053 243.617H132.076C137.675 243.617 142.218 239.091 142.218 233.495V230.17H159.805L168.04 224.941H308.735L319.213 230.92H335.306V233.495C335.306 239.083 339.842 243.617 345.449 243.617H361.472C367.071 243.617 371.614 239.091 371.614 233.495V161.452H335.291Z'
          fill='#414042'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M364.107 125.973C362.762 123.289 352.956 113.065 350.977 111.02C350.751 110.778 350.555 110.513 350.399 110.224C347.998 105.65 327.635 53.2748 324.061 46.6333C317.508 34.4349 312.253 36.2768 305.255 35.3871C305.051 35.3636 304.848 35.4183 304.637 35.4105L251.501 32.7257C242.751 32.2809 233.984 32.2809 225.234 32.7179C210.853 33.4359 196.473 34.1383 182.092 34.872C170.096 35.4807 158.531 34.7081 151.939 46.6255C148.287 53.228 128.002 105.627 125.601 110.216C125.445 110.513 125.249 110.778 125.023 111.02C123.044 113.073 113.246 123.289 111.901 125.973C110.407 128.962 104.417 140.162 104.417 142.409C104.417 144.345 105.809 176.289 111.956 201.333C113.785 208.794 119.455 214.78 126.86 216.864C127.384 217.012 127.783 217.098 127.986 217.098H174.6C175.859 217.098 177.025 216.427 177.65 215.342L188.676 196.33C188.895 195.956 189.177 195.628 189.513 195.355C191.249 193.958 197.114 189.837 205.817 189.837C213.473 189.837 228.307 189.837 235.752 189.837C238.497 189.837 240.24 189.837 240.24 189.837C247.685 189.837 262.519 189.837 270.175 189.837C278.878 189.837 284.743 193.958 286.479 195.355C286.815 195.628 287.097 195.956 287.316 196.33L298.342 215.342C298.975 216.427 300.133 217.098 301.392 217.098H348.006C348.217 217.098 348.608 217.012 349.132 216.864C356.545 214.772 362.207 208.794 364.037 201.333C370.183 176.289 371.575 144.345 371.575 142.409C371.591 140.162 365.6 128.962 364.107 125.973ZM135.47 102.068C144.822 83.7668 162.409 42.5125 162.409 42.5125C248.107 33.9276 313.966 42.1457 313.966 42.1457L339.412 102.068C281.404 96.4646 135.47 102.068 135.47 102.068Z'
          fill='#EAC9C9'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.634 217.098L174.014 223.076H301.243L299.374 217.098L287.027 195.807C287.027 195.807 282.163 190.204 270.19 189.829C258.218 189.454 205.825 189.829 205.825 189.829C205.825 189.829 196.848 189.08 188.989 195.807L176.634 217.098Z'
          fill='#949191'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M187.488 146.14C187.488 146.14 197.591 164.067 200.586 167.056C203.581 170.045 206.943 170.045 211.064 170.42C215.178 170.794 271.684 170.42 271.684 170.42C271.684 170.42 276.173 169.671 278.417 166.315C280.661 162.951 288.145 146.148 288.145 146.148H187.488V146.14Z'
          fill='#414042'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.513 148.575C197.513 148.575 205.606 162.927 207.999 165.323C210.4 167.719 213.09 167.719 216.39 168.016C219.69 168.312 264.935 168.016 264.935 168.016C264.935 168.016 268.533 167.415 270.331 164.722C272.13 162.03 278.12 148.575 278.12 148.575H197.513Z'
          fill='#949191'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M280.028 210.745L277.791 204.572C276.376 200.67 272.63 198.102 268.47 198.18L207.694 198.789C203.401 198.875 199.679 201.754 198.529 205.883L197.012 210.737H280.028V210.745Z'
          fill='#414042'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M196.082 213.734L194.221 219.713H283.281L281.115 213.734H196.082Z'
          fill='#414042'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M350.633 110.661C350.633 110.661 352.502 96.0977 355.873 96.0977C359.243 96.0977 374.21 100.203 374.586 103.192C374.961 106.181 371.591 109.537 368.596 110.286C365.601 111.035 359.243 111.777 359.243 111.777L357.749 117.38L350.633 110.661Z'
          fill='#414042'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M125.367 110.661C125.367 110.661 123.498 96.0977 120.127 96.0977C116.757 96.0977 101.79 100.203 101.414 103.192C101.039 106.181 104.409 109.537 107.404 110.286C110.399 111.035 116.757 111.777 116.757 111.777L118.251 117.38L125.367 110.661Z'
          fill='#414042'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M168.775 144.267C168.775 144.267 175.508 151.361 177.752 164.433C177.752 164.433 145.949 162.943 133.593 158.088C121.246 153.234 119.744 152.859 119.377 150.62C119.001 148.38 120.127 132.318 124.991 129.704C129.855 127.089 135.095 127.838 139.959 131.569C144.822 135.307 150.812 140.903 154.175 142.027C157.545 143.151 168.775 144.267 168.775 144.267Z'
          fill='white'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M146.981 145.352C146.981 150.183 143.055 154.108 138.207 154.108C133.359 154.108 129.433 150.191 129.433 145.352C129.433 140.521 133.359 136.595 138.207 136.595C143.055 136.595 146.981 140.521 146.981 145.352Z'
          fill='white'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M162.644 156.294C162.644 158.541 160.822 160.36 158.57 160.36C156.318 160.36 154.496 158.541 154.496 156.294C154.496 154.046 156.318 152.228 158.57 152.228C160.814 152.235 162.644 154.054 162.644 156.294Z'
          fill='white'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M308.281 144.267C308.281 144.267 301.548 151.361 299.304 164.433C299.304 164.433 331.107 162.943 343.463 158.088C355.81 153.234 357.311 152.859 357.679 150.62C358.054 148.38 356.928 132.318 352.064 129.704C347.2 127.089 341.961 127.838 337.097 131.569C332.233 135.307 326.243 140.903 322.881 142.027C319.51 143.151 308.281 144.267 308.281 144.267Z'
          fill='white'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M332.092 145.352C332.092 150.183 336.018 154.108 340.866 154.108C345.715 154.108 349.64 150.191 349.64 145.352C349.64 140.521 345.715 136.595 340.866 136.595C336.018 136.595 332.092 140.521 332.092 145.352Z'
          fill='white'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M316.437 156.294C316.437 158.541 318.259 160.36 320.511 160.36C322.763 160.36 324.585 158.541 324.585 156.294C324.585 154.046 322.763 152.228 320.511 152.228C318.259 152.235 316.437 154.054 316.437 156.294Z'
          fill='white'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M304.77 150.019L295.48 146.928H288.145'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.896 149.574L179.097 146.046L187.488 146.14'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M331.115 108.249C332.061 107.711 333.015 107.204 333.985 106.735'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M289.92 161.265C295.777 148.419 309.157 122.875 327.87 110.271'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M286.628 169.007C286.628 169.007 287.089 167.782 287.981 165.667'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.076 110.263C146.519 109.326 144.916 108.437 143.251 107.617'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M185.556 161.554C181.724 150.128 171.738 126.473 151.376 112.401'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M187.503 168.125C187.503 168.125 187.23 166.939 186.612 164.878'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M132.631 128.377C132.631 128.377 128.651 118.661 128.651 112.917L132.631 128.377Z' fill='white' />
        <path
          d='M132.631 128.377C132.631 128.377 128.651 118.661 128.651 112.917'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M345.488 127.932C345.488 127.932 346.817 123.515 347.701 115.562L345.488 127.932Z' fill='white' />
        <path
          d='M345.488 127.932C345.488 127.932 346.817 123.515 347.701 115.562'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M201.008 154.459L237.12 157.339C238.653 157.464 240.193 157.464 241.734 157.347L275.015 154.764'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M203.385 158.237L237.112 160.929C238.645 161.054 240.186 161.054 241.726 160.937L273.842 158.448'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M205.802 162.006L237.12 164.504C238.653 164.629 240.193 164.629 241.734 164.512L272.224 162.147'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.47 102.068C135.47 102.068 281.404 96.4645 339.404 102.068L313.966 42.1455C313.966 42.1455 248.107 33.9274 162.417 42.5201C162.417 42.5201 144.822 83.7666 135.47 102.068Z'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_61_5455'>
          <rect width='274' height='212' fill='white' transform='translate(101 32)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Car
