const formatPhone = (str: string, addDashes?: boolean) => {
  // returns:
  // if (addDashes) +972-50-123-4567
  // else +972501234567

  let formatted = ''

  // 501234567
  if (str.length === 9 && str.charAt(0) === '5') {
    formatted = `+972${addDashes ? '-' : ''}${
      addDashes ? `${str.substring(0, 2)}-${str.substring(2, 5)}-${str.substring(5)}` : str
    }`
  }

  // 0501234567
  if (str.length === 10 && str.charAt(0) === '0') {
    formatted = `+972${addDashes ? '-' : ''}${
      addDashes ? `${str.substring(1, 3)}-${str.substring(3, 6)}-${str.substring(6)}` : str.substring(1)
    }`
  }

  // +972501234567
  if (str.length === 13 && str.charAt(0) === '+') {
    formatted = addDashes
      ? `${str.substring(0, 4)}-${str.substring(4, 6)}-${str.substring(6, 9)}-${str.substring(9)}`
      : str
  }

  // +9720501234567
  if (str.length === 14 && str.charAt(0) === '+' && str.charAt(4) === '0') {
    formatted = addDashes
      ? `${str.substring(0, 4)}-${str.substring(5, 7)}-${str.substring(7, 10)}-${str.substring(10)}`
      : `${str.substring(0, 4)}${str.substring(5)}`
  }

  return formatted
}

export default formatPhone
