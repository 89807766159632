import React from 'react'

const QustionMarks = ({ style = {} }: { style?: React.CSSProperties }) => {
  return (
    <svg
      width='169'
      height='197'
      viewBox='0 0 169 197'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
    >
      <path
        opacity='0.16'
        d='M107.406 0.00665529C88.0102 0.341874 73.0468 16.3579 56.4389 26.489C31.3612 41.792 -0.84783 44.8994 -22.0253 65.3425C-36.5302 79.3418 -43.6983 100.439 -41.6585 120.59C-39.6188 140.74 -28.6716 159.629 -12.8175 172.043C-2.70837 179.961 9.15588 185.298 21.2626 189.469C33.5327 193.699 46.3087 196.828 59.2693 196.993C77.237 197.222 94.8884 191.736 111.143 183.999C122.449 178.62 133.343 172.043 142.235 163.173C150.636 154.793 157.077 144.507 161.488 133.45C172.393 106.117 170.659 74.7978 161.204 46.9214C153.387 23.8604 134.271 -0.461587 107.406 0.00665529Z'
        fill='#949191'
      />
      <g clipPath='url(#clip0_61_5455)'>
        <path
          d='M31.14 0.5H84.08C90.51 0.5 95.72 5.71 95.72 12.14V52.31C95.72 58.74 90.51 63.95 84.08 63.95H40.33L20.31 81.43L29.94 63.89C24.08 63.29 19.5 58.33 19.5 52.31V12.14C19.5 5.71 24.71 0.5 31.14 0.5Z'
          fill='white'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M50.9999 42.8699V35.0499C52.8699 33.5399 54.5599 31.9199 56.0699 30.1599C57.5799 28.4099 58.7699 26.5499 59.6399 24.5699C60.5099 22.5999 60.9499 20.5099 60.9499 18.3099C60.9499 16.7199 60.6299 15.5299 59.9999 14.7399C59.3699 13.9499 58.4199 13.5499 57.1599 13.5499C56.0599 13.5499 55.1899 13.8799 54.5299 14.5299C53.8799 15.1799 53.5499 16.0599 53.5499 17.1599C53.5499 17.8099 53.6199 18.3799 53.7599 18.8699C53.8999 19.3599 54.0599 19.8899 54.2199 20.4599L45.8499 22.3499C45.5599 21.4599 45.3399 20.5699 45.1799 19.6899C45.0199 18.8099 44.9399 17.9299 44.9399 17.0299C44.9399 13.4899 46.0099 10.7099 48.1499 8.68992C50.2899 6.66992 53.3499 5.66992 57.3399 5.66992C61.4099 5.66992 64.5499 6.74992 66.7399 8.90992C68.9399 11.0699 70.0399 14.1399 70.0399 18.1299C70.0399 20.6499 69.6099 23.0199 68.7599 25.2099C67.9099 27.4099 66.6699 29.4799 65.0699 31.4099C63.4599 33.3399 61.5599 35.1699 59.3599 36.8799V42.8599H50.9999V42.8699ZM50.6899 55.7599V46.8999H59.7299V55.7499H50.6899V55.7599Z'
          fill='#EBD0D0'
        />
      </g>
      <g clipPath='url(#clip1_61_5455)'>
        <path
          d='M127.039 31.3662H87.9611C83.2148 31.3662 79.369 35.1817 79.369 39.8906V69.3084C79.369 74.0173 83.2148 77.8327 87.9611 77.8327H120.255L135.033 90.6339L127.925 77.7888C132.25 77.3494 135.631 73.717 135.631 69.3084V39.8906C135.631 35.1817 131.785 31.3662 127.039 31.3662Z'
          fill='#EBD0D0'
          stroke='#414042'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.621 62.3951V56.6683C104.001 55.5624 105.249 54.3761 106.363 53.0872C107.478 51.8056 108.356 50.4434 108.999 48.9934C109.641 47.5507 109.965 46.0202 109.965 44.409C109.965 43.2446 109.729 42.3731 109.264 41.7946C108.799 41.2161 108.098 40.9231 107.168 40.9231C106.356 40.9231 105.714 41.1648 105.227 41.6408C104.747 42.1168 104.503 42.7613 104.503 43.5668C104.503 44.0429 104.555 44.4603 104.658 44.8191C104.762 45.178 104.88 45.5661 104.998 45.9835L98.8194 47.3676C98.6054 46.7159 98.443 46.0641 98.3249 45.4196C98.2068 44.7752 98.1477 44.1307 98.1477 43.4716C98.1477 40.8792 98.9375 38.8433 100.517 37.364C102.097 35.8847 104.356 35.1523 107.301 35.1523C110.305 35.1523 112.623 35.9433 114.239 37.5251C115.863 39.1069 116.675 41.3552 116.675 44.2772C116.675 46.1227 116.358 47.8583 115.73 49.4621C115.103 51.0733 114.188 52.5892 113.007 54.0026C111.818 55.416 110.416 56.7562 108.792 58.0084V62.3878H102.621V62.3951ZM102.392 71.8349V65.3464H109.065V71.8276H102.392V71.8349Z'
          fill='#414042'
        />
      </g>
      <defs>
        <clipPath id='clip0_61_5455'>
          <rect width='77.22' height='81.93' fill='white' transform='translate(19)' />
        </clipPath>
        <clipPath id='clip1_61_5455'>
          <rect width='57' height='60' fill='white' transform='translate(79 31)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default QustionMarks
