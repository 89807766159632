import { useContext, useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useForm } from '../../contexts/FormContext'
import { StepContext } from '../../components/DialogWrapper'
import LoadingScreen from './LoadingScreen'
import { LOAN_JOURNEY_SERVICE } from '../../consts'
import { INTEREST_SUBSIDY_PERCENT } from './ProductInformation'

const BdiAndFicoAndDataHarbor = ({
  nextStep,
  manualCallback,
  failedCallback,
  digitalRequestId,
}: {
  nextStep: () => void
  manualCallback: () => void
  failedCallback: () => void
  digitalRequestId: string
}): JSX.Element => {
  const { t } = useTranslation()
  const { token, formData, onChangeFormData } = useForm()
  const { setPreventBack, setHeaderActive, setDarkHeading, setStepTitle, setStepperActive, setImageRenderer } =
    useContext(StepContext)

  useEffect(() => {
    setPreventBack(true)
    setHeaderActive(false)
    setDarkHeading(false)
    setStepTitle('')
    setStepperActive(false)
    setImageRenderer(null)
  }, [])

  const run = async () => {
    try {
      if (!formData['Consent_Before_Credit_Pool__c'] || formData['tranzillaStatus'] !== 'GO') {
        toast.error(t('Your consent for credit rates is required in able to continue'))

        return
      }

      const { data: bdiData = {} } = await axios.post(
        `${LOAN_JOURNEY_SERVICE}/digital-request/${digitalRequestId}/bdi/credit-report`,
        {
          nationalId: formData['nationalId'],
          firstName: formData['firstName'],
          lastName: formData['lastName'],
        },
        {
          headers: { authorization: `Bearer ${token}` },
        }
      )

      if (bdiData.error) {
        return manualCallback()
      }

      if (!bdiData.passed) {
        failedCallback()
      }

      const projectId =
        formData['selectedProduct'] === 'Car' && formData['isVehicleSubjected']
          ? formData['employmentStatus'] === 'Company Owner' ||
            formData['employmentStatus'] === 'Independent Business'
            ? 7
            : 5
          : formData['employmentStatus'] === 'Company Owner' ||
            formData['employmentStatus'] === 'Independent Business'
          ? 3
          : 6

      const QA = {
        ID: 999999999,
        Age: new Date(formData['dateOfBirth']).toLocaleDateString('he-IL').replaceAll('.', '/'),
        social_index: formData['geographicScore'],

        RECIDENCE: formData['isHomeOwner'] === 'Yes' ? 1 : 0,
        Avg_monthly_income_Partner_Another: formData['additionalIncomeAmount'] || 0,
        PASSIVE: formData['portfolioAmount'] || 0,
        ind_credit_company: formData['isFinancialService'] === 'Yes' ? 1 : 0,

        ind_money_loandry_terror: formData['hasBankRejectionsPastYear'] === 'YES' ? 1 : 0,
        foregn_citizen: formData['isForeignCitizen'] === 'YES' ? 1 : 0,
        ind_pep: formData['isPublicFigure'] === 'YES' ? 1 : 0,

        REQUESTED_LOAN_AMOUNT: formData['loanAmount'],
        CREDIT_PERIOD: formData['loanTerm'],
        Montly_Payment_Ratio: formData['monthlyReturn'],

        Loan_Purpose: (() => {
          switch (formData['selectedProduct']) {
            case 'Studies':
              return 1
            case 'Trip':
              return 2
            case 'Car':
              return 3
            case 'Renovations':
              return 4
            case 'Liability Coverage':
              return 5
            case 'Other':
            default:
              return 6
          }
        })(),

        EMPLOYMENT_STATUS: (() => {
          switch (formData['employmentStatus']) {
            case 'Employee':
              return 1
            case 'Unemployed':
              return 2
            case 'Pensioner':
              return 3
            case 'Company Owner':
              return 4
            case 'Independent Business':
              return 5
            default:
              return 1
          }
        })(),

        industry_index: (() => {
          switch (formData['fieldOfWork']) {
            case 'Pharma':
            case 'Health services':
            case 'Vehicles':
            case 'Agriculture':
            case 'Medical equipment':
            case 'Banks Insurance and financial institutions':
            case 'Start-up and R&D':
            case 'Gold, metal and jewelry':
            case 'Cosmetics':
            case 'High tech':
            case 'Optics':
            case 'Food and detergents':
            case 'Chemicals':
              return 'A'

            case 'Advertising':
            case 'Communication':
            case 'Electronics':
            case 'Computers':
            case 'Packaging':
            case 'Electricity':
            case 'Textile':
            case 'Communications – equipment & infostructure':
            case 'Office supplies':
            case 'Services':
            case 'Vehicles – leasing & rental':
            case 'Toiletries':
            case 'Electrical products':
            case 'Retail chains':
            case 'Construction - Infrastructure':
            case 'Toys & Gifts':
              return 'B'

            case 'Tourism & aviation':
            case 'Printing & publishing':
            case 'Glass & its products':
            case 'Plastics & nylon Products':
            case 'Housewares':
            case 'Transportation Services':
            case 'Wood and its products':
            case 'Fashion':
            case 'Construction - Trading houses and wholesalers':
            case 'Fuels':
              return 'C'

            case 'Construction - Contracting':
            case 'Construction - inputs and raw materials':
            case 'Food – bars, cafes, restaurants':
            case 'Government & authorities':
              return 'D'

            default:
              return ''
          }
        })(),
      } as Record<string, any>

      switch (projectId) {
        case 3: {
          if (formData['businessMonthlyIncome'] !== '') {
            QA.Avg_monthly_income = formData['businessMonthlyIncome']
          }
          QA.biz_seniority = formData['businessEstablishmentYear']
          if (formData['businessCustomerCount'] !== '') {
            QA.Number_of_customers = formData['businessCustomerCount']
          }

          break
        }

        case 7: {
          if (formData['businessMonthlyIncome'] !== '') {
            QA.Avg_monthly_income = formData['businessMonthlyIncome']
          }
          QA.biz_seniority = formData['businessEstablishmentYear']
          if (formData['businessCustomerCount'] !== '') {
            QA.Number_of_customers = formData['businessCustomerCount']
          }

          // QA.CAR_ID = Number(formData['vehicleLicenseNumber'])
          // QA.NEW_OR_USED = formData['isVehicleNew'] === 'yes'
          QA.CAR_TYPE = formData['vehicleType'] === 'Private' ? 1 : 2
          QA.CAR_COST = Math.min(formData['vehicleValue'], formData['vehicleValueFromApi'])
          QA.CAR_AGE = formData['vehicleAge']
          QA.BALOON = formData['isVehicleSubjected'] ? 1 : 2
          QA.LOAN_BALOON = formData['balloonAmount']

          break
        }

        case 6: {
          if (formData['monthlyIncome'] !== '') {
            QA.Avg_monthly_income = formData['monthlyIncome']
          }
          QA.PROFESSION = formData['occupation']
          if (formData['yearsEmployedCurrent'] !== '') {
            QA.empl_seniority = formData['yearsEmployedCurrent']
          }
          if (formData['yearsEmployedPrevious'] !== '') {
            QA.EMPLOYMENT_SENIORITY_BEFORE = formData['yearsEmployedPrevious']
          }

          break
        }

        case 5: {
          if (formData['monthlyIncome'] !== '') {
            QA.Avg_monthly_income = formData['monthlyIncome']
          }
          QA.PROFESSION = formData['occupation']
          if (formData['yearsEmployedCurrent'] !== '') {
            QA.empl_seniority = formData['yearsEmployedCurrent']
          }
          if (formData['yearsEmployedPrevious'] !== '') {
            QA.EMPLOYMENT_SENIORITY_BEFORE = formData['yearsEmployedPrevious']
          }

          // QA.CAR_ID = Number(formData['vehicleLicenseNumber'])
          // QA.NEW_OR_USED = formData['isVehicleNew'] === 'yes'
          QA.CAR_TYPE = formData['vehicleType'] === 'Private' ? 1 : 2
          QA.CAR_COST = Math.min(formData['vehicleValue'], formData['vehicleValueFromApi'])
          QA.CAR_AGE = formData['vehicleAge']
          QA.BALOON = formData['isVehicleSubjected'] ? 1 : 2
          QA.LOAN_BALOON = formData['balloonAmount']

          break
        }
      }

      const { data: dhData } = await axios.post(
        `${LOAN_JOURNEY_SERVICE}/digital-request/${digitalRequestId}/data-harbor`,
        {
          projectId,
          dhBody: {
            QA,
            CustId: formData['nationalId'],
          },
        },
        {
          headers: { authorization: `Bearer ${token}` },
        }
      )

      const modelInterest = dhData.modelInterest
      const interestAfterSubsidy =
        (modelInterest / 100) * (formData['subsidyType'] === 'סבסוד חלקי' ? 1 - INTEREST_SUBSIDY_PERCENT : 1) * 100

      onChangeFormData('loanInterest', modelInterest)
      onChangeFormData('interestAfterSubsidy', interestAfterSubsidy)

      switch (dhData.underwritingResult) {
        case 'מאושר':
          nextStep()
          break

        case 'לא מאושר':
          failedCallback()
          break

        case 'חיתום ידני':
        case 'מועבר לחתם':
        default:
          manualCallback()
          break
      }
    } catch (error: any) {
      console.error(error)
      toast.error(
        'אנחנו מתנצלים אך זמנית לא ניתן להמשיך בתהליך, אנא וודא שהחיבור לאינטרנט תקין ונסה שוב בעוד מספר דקות'
      )
    }
  }

  useEffect(() => {
    run()
  }, [])

  return <LoadingScreen />
}

export default BdiAndFicoAndDataHarbor
