import React from 'react'

const RoundIconTextUnder = ({
  icon: Icon,
  label,
  onClick,
}: {
  icon: React.FunctionComponent
  label: string
  onClick: () => void
}) => {
  return (
    <div style={{ margin: '0.3rem', width: 'fit-content' }}>
      <button
        onClick={() => onClick()}
        style={{
          width: 'fit-content',
          height: 'fit-content',
          margin: '0',
          padding: '0',
          background: 'none',
          border: 'none',
          borderRadius: '100%',
          cursor: 'pointer',
        }}
      >
        <Icon />
      </button>
      <h6 style={{ fontSize: '1rem', fontWeight: '300' }}>{label}</h6>
    </div>
  )
}

export default RoundIconTextUnder
