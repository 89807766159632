import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useRtl } from '../../contexts/RtlContext'
import { useForm } from '../../contexts/FormContext'
import { StepContext } from '../../components/DialogWrapper'
import GreyButton from '../../components/buttons/GreyButton'
import ManWithWallet from '../../vectors/ManWithWallet'
import { formatToCurrency } from '../../functions/format-currency'
import { INTEREST_SUBSIDY_PERCENT, SOLO_INIT_FEE_PERCENT, VEHICLE_INIT_FEE_PERCENT } from './ProductInformation'
import { CONTACT_EMAIL } from '../../consts'
import Disclaimer from '../../components/Disclaimer'

const SignedResults = ({ nextStep }: { nextStep: () => void }): JSX.Element => {
  const { t } = useTranslation()
  const { isRtl } = useRtl()
  const { formData } = useForm()
  const { setPreventBack, setHeaderActive, setDarkHeading, setStepTitle, setStepperActive, setImageRenderer } =
    useContext(StepContext)

  useEffect(() => {
    setPreventBack(true)
    setHeaderActive(true)
    setDarkHeading(false)
    setStepTitle('')
    setStepperActive(false)
    setImageRenderer(null)
  }, [isRtl])

  const onClickContinue = (): void => {
    nextStep()
  }

  return (
    <main>
      <div className='flex-col'>
        <div>
          <p style={{ fontSize: '2rem', color: 'var(--grey)' }}>
            {formData['firstName']} {formData['lastName']},
          </p>
          <p style={{ fontSize: '1.4rem' }}>{t('result_title')}</p>
          <p
            style={{
              fontSize: '2rem',
              fontWeight: 'bold',
              color: 'var(--ampa-red)',
              textShadow: '-1px 1px 3px var(--grey)',
            }}
          >
            {formatToCurrency(formData['loanAmount'])}
          </p>

          <p style={{ fontSize: '1.3rem' }}>
            {t('result_pre_term')} - <span style={{ color: 'var(--ampa-red)' }}>{formData['loanTerm']}</span>{' '}
            {t('result_post_term')} | {t('result_pre_interest')} +{' '}
            <span style={{ color: 'var(--ampa-red)' }}>{formData['interestAfterSubsidy']}%</span>
          </p>
        </div>

        <div>
          <ManWithWallet />
          <GreyButton label={t('Continue')} onClick={onClickContinue} />
        </div>

        <Disclaimer>
          * עלות ההלוואה כוללת גם עמלת הקמה בסך{' '}
          {(formData['selectedProduct'] === 'Car' && formData['isVehicleSubjected']
            ? VEHICLE_INIT_FEE_PERCENT
            : SOLO_INIT_FEE_PERCENT) * 100}
          %, במסגרת התשלום החודשי של ההלוואה נפרוס עבורך גם את עמלת הקמה, בגין פריסת העמלה לא תשלם ריבית. אם בכל
          זאת ברצונך לשלם את העמלה מראש עליך לשלוח מייל היום, טרם הקמת ההלוואה לכתובת <u>{CONTACT_EMAIL}</u>. ההצעה
          תקפה עד {new Date(Date.now() + 12096e5).toLocaleDateString('he-IL')} בשעה 23:59.
          <br />
          <br /> אישור ההלוואה הינו עקרוני בלבד ואינו מחייב את החברה, העמדת ההלוואה מותנת בהשלמת תהליך חתימה על
          מסמכים באופן דיגיטלי ו/או באמצעות שליח,
          {formData['selectedProduct'] === 'Car' && formData['isVehicleSubjected']
            ? `ובכפוף לשעבוד הרכב: ${formData['vehicleBrand']} - ${formData['vehicleModel']} שאת פרטיו הזנת,`
            : null}{' '}
          ובאישור סופי של החברה.
          <br />
          <br />
          {t('result_disclaimer_middle')}
          <br />
          <br />
          {t('result_disclaimer_pre_mail')} <u>{CONTACT_EMAIL}</u> {t('result_disclaimer_post_mail')}
        </Disclaimer>
      </div>
    </main>
  )
}

export default SignedResults
