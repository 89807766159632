const Close = ({ color = 'var(--grey)' }: { color?: string }) => {
  return (
    <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M14 14L8 8M14 8L8 14' stroke={color} strokeWidth='2' strokeLinecap='round' />
      <path
        d='M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z'
        stroke={color}
        strokeWidth='2'
      />
    </svg>
  )
}

export default Close
