import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { StepContext } from '../../components/DialogWrapper'
import GreyButton from '../../components/buttons/GreyButton'
import PaperPlane from '../../vectors/PaperPlane'
import ManGoodbye from '../../vectors/ManGoodbye'
import { CONTACT_EMAIL } from '../../consts'
import Disclaimer from '../../components/Disclaimer'

const EndScreen = ({
  isManual,
  isSuccess,
  isFail,
  failMessage,
  allowClearFail,
  clearFail,
  clickConfirm,
}: {
  isManual: boolean
  isSuccess: boolean
  isFail: boolean
  failMessage: string
  allowClearFail: boolean
  clearFail: () => void
  clickConfirm: () => void
}): JSX.Element => {
  const { t } = useTranslation()
  const { setPreventBack, setHeaderActive, setDarkHeading, setStepTitle, setStepperActive, setImageRenderer } =
    useContext(StepContext)

  useEffect(() => {
    setPreventBack(true)
    setHeaderActive(false)
    setDarkHeading(false)
    setStepTitle('')
    setStepperActive(false)
    setImageRenderer(null)
  }, [])

  const rootStyles = {
    width: '100%',
    height: `100%`,
    padding: '1rem',
    backgroundColor: 'var(--lighter-grey)',
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'space-between',
    position: 'absolute' as const,
    top: 0,
    right: 0,
  }

  if (isManual) {
    return (
      <main>
        <div style={rootStyles}>
          <br />

          <div>
            <p style={{ fontSize: '2rem', color: 'var(--ampa-red)' }}>תודה על הפרטים שמסרת</p>
            <p>
              בשלב זה אנחנו בודקים אם ניתן לאשר את בקשתך להלוואה. ייתכן ונפנה בהמשך להשלמת פרטים. אפשר לבדוק את
              סטטטוס בקשתך באמצעות המייל: {CONTACT_EMAIL}
            </p>
          </div>

          <div>
            <ManGoodbye />
            <GreyButton label={t('Confirm')} onClick={clickConfirm} />
          </div>

          <Disclaimer>
            לידיעתך, בהמשך להסכמתך, התקבל דוח נתוני אשראי מלשכת קופאס בי.די.אי בע"מ. אם לא תבוצע עסקת האשראי
            באפשרותך לקבל את דוח האשראי עד 60 יום מהיום. אם תבוצע עסקת האשראי תוכל לקבל את דוח האשראי עד ליום סיום
            העסקה. תוכל לקבל את הדוח באמצעות פניה לכאן {CONTACT_EMAIL} או בקישור "צור קשר" באתר החברה
            ampacapital.co.il
            {/* <a href='https://ampacapital.co.il' target='_blank'>
              ampacapital.co.il
            </a> */}
          </Disclaimer>

          <br />
        </div>
      </main>
    )
  }

  if (isSuccess) {
    return (
      <main>
        <div style={rootStyles}>
          <br />

          <div>
            <p style={{ fontSize: '2rem', color: 'var(--ampa-red)' }}>{t('Excellent, so what now?')}</p>
            <p>
              {t(
                "To transfer the money to you, very soon we will send a SMS with a link to progress in the process. If you think it's taking too long, you can check the status of your application by email:"
              )}{' '}
              {CONTACT_EMAIL}
            </p>
          </div>

          <div>
            <PaperPlane />
            <GreyButton label={t('Confirm')} onClick={clickConfirm} />
          </div>

          <br />
        </div>
      </main>
    )
  }

  if (isFail) {
    return (
      <main>
        <div style={rootStyles}>
          <br />

          <div>
            <p style={{ fontSize: '2rem', color: 'var(--ampa-red)' }}>{t('Thank you for trying')}</p>
            <p>{t(failMessage)}</p>
          </div>

          <div>
            <ManGoodbye />
            <GreyButton label={t('Confirm')} onClick={clickConfirm} />
            {allowClearFail ? <GreyButton label={t('Try again')} onClick={clearFail} /> : null}
          </div>

          <br />
        </div>
      </main>
    )
  }

  return <main />
}

export default EndScreen
